import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Empty } from 'antd';
import { getTopRunningItemsAPI } from '../../../../../api';
import { ITopRunningItems } from '../../../../../modal/items';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

export const TopItemsOverview: FC = () => {
  const [topRunningItems, setTopRunningItems] = useState<ITopRunningItems[]>(
    []
  );
  const { data: topRunningItemsData, isLoading: topRunningItemsLoading } =
    useQuery([`top-running-items`], getTopRunningItemsAPI);

  /* component did mount */
  useEffect(() => {
    if (topRunningItemsData?.data?.result) {
      const { result } = topRunningItemsData.data;
      setTopRunningItems(result);
    }
  }, [topRunningItemsData]);

  return (
    <WrapperTopItems>
      <table>
        {topRunningItems.length > 0 ? (
          <>
            <thead>
              <tr>
                {[
                  '#',
                  'Item Name',
                  'Purchase Price',
                  'Sell price',
                  'Category',
                  'Status',
                ].map((head, index) => (
                  <th key={index}>{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {topRunningItems.map((item, index) => (
                <tr key={index} className="mr-10 pv-10">
                  <td>{index + 1}</td>
                  <td>
                    <Link to="">{item.item_name}</Link>
                  </td>
                  <td>{item.purchase_price}</td>
                  <td>{item.sale_price}</td>
                  <td>{item.category_name}</td>
                  <td>
                    {item.status && item.status === 1 ? 'Active' : 'Deactive'}
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <tr>
            <td colSpan={24}>
              <EmptyState
                text=""
                content="No Item are available"
                src="/assets/emptyItems.png"
                width={158}
                height={128}
                alt="chart"
              />
            </td>
          </tr>
        )}
      </table>
    </WrapperTopItems>
  );
};

const WrapperTopItems = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    thead > tr > th {
      color: #143c69;
      padding-top: 19px;
      padding-right: 50px;
      padding-left: 12px;
    }
    tbody > tr > td {
      padding-top: 18px;
      padding-right: 50px;
      padding-left: 13px;
    }
  }
`;
