import { Button, Space } from 'antd';
import {
  PrinterOutlined,
  MailOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import printIcon from '@iconify-icons/bytesize/print';
import styled, { css } from 'styled-components';
import { useState, useEffect, FC } from 'react';
import { IThemeProps } from '@invyce/shared/invyce-theme';
import { ButtonTag } from '@components';
import documentImport from '@iconify/icons-carbon/document-import';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';

interface FixedHeaderContainerProps {
  hasScrolled: boolean;
}

interface Iprops {
  title: string;
  onPrint?: () => void;
  onEmail?: () => void;
  children: any;
  src: string;
  result?: any[];
}

const FixedHeaderContainer = styled.div<FixedHeaderContainerProps>`
  position: sticky !important;
  top: 0 !important;
  ${(props) =>
    props.hasScrolled &&
    css`
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    `};
  z-index: 10;
`;

const HeaderContent = styled.div`
  background: ${(props: IThemeProps) => props.theme.colors.sidebarBg};
  padding: 16px 5px;
  position: relative;
  top: -21px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  .btn-primary {
    padding: 20px 16px;
  }
`;

const Logo = styled.img`
  height: 50px;
`;

const Title = styled.h1`
  font-size: 28px;
  color: ${(props: IThemeProps) => props.theme.colors.updateTableHeader};
  font-weight: 500;
  text-transform: capitalize;
  margin-right: auto;
  margin-left: 6px;
  margin-bottom: 0px;
`;

const FixedHeader: FC<Iprops> = ({
  title,
  onPrint,
  onEmail,
  src,
  children,
  result,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const isDataEmpty = result?.length === 0;

  return (
    <FixedHeaderContainer hasScrolled={hasScrolled}>
      <div>
        <HeaderContent>
          <Logo src={src} alt={src} />
          <Title>{title}</Title>
          <Space>
            <ButtonTag
              onClick={onPrint}
              icon={printIcon}
              disabled={isDataEmpty}
              type="primary"
              title="Print"
              size="middle"
              className="btn-primary"
            />
            <ButtonTag
              onClick={onEmail}
              size="middle"
              disabled={isDataEmpty}
              title="Email"
              icon={baselineAlternateEmail}
              type="ghost"
            />
            {children}
          </Space>
        </HeaderContent>
      </div>
    </FixedHeaderContainer>
  );
};

export default FixedHeader;
