import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { Seprator } from '../../../../../components/Seprator';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getSalesSummaryDataAPI } from '../../../../../api';
import { useWindowSize } from '../../../../../utils/useWindowSize';
/* Echarts */
import * as echarts from 'echarts';
import { IThemeProps } from '@invyce/shared/invyce-theme';
import EChart from '../../../../../components/BarChart/Chart';

interface IProps {
  id?: number;
}

interface RouteParams {
  id: string;
}

export const ItemSalesGraph: FC<IProps> = (props) => {
  const [graphData, setGraphData] = useState({
    labels: [],
    series: [],
  });

  const [width, height] = useWindowSize();
  const { id: itemId } = useParams<RouteParams>();

  // const { data: salesSummaryData, isLoading: salesSummaryFetching } = useQuery(
  //   [
  //     `item-sales-summay-${id}`,
  //     {
  //       id,
  //     },
  //   ],
  //   getSalesSummaryDataAPI,
  //   {
  //     enabled: !!id,
  //     cacheTime: Infinity,
  //   }
  // );

  // useEffect(() => {
  //   if (
  //     salesSummaryData &&
  //     salesSummaryData.data &&
  //     salesSummaryData.data.result
  //   ) {
  //     const { result } = salesSummaryData.data;

  //     setGraphData({
  //       labels: result.map((item) => {
  //         return dayjs(item.saledate).format('DD MMM');
  //       }),
  //       series: result.map((item) => {
  //         return { value: item.saleamount, itemStyle: { color: '#1890FF' } };
  //       }),
  //     });
  //   }
  // }, [salesSummaryData]);

  const renderChart = () => {
    const chartDom: any = document.getElementById('sales_chart')!;

    if (chartDom) {
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          top: '5%',
          left: '2%',
          right: '2%',
          bottom: '0%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: graphData.labels,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],

        series: [
          {
            name: 'Sales',
            type: 'bar',
            barWidth: '10%',
            data: graphData.series,
          },
        ],
      };

      myChart.resize();
      option && myChart.setOption(option);
    }
  };

  useEffect(() => {
    renderChart();
  }, [graphData, width, height]);

  const chartData = {
    xAxisData: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    seriesData: [120, 200, 150, 80, 70, 110, 130],
  };

  const chartHeight = itemId ? '200px' : '300px';

  return (
    <WrapperItemSalesGraph hasNoData={!graphData.series.length}>
      <Card className="_salesitemcard">
        <h4 className="innerHeader">Item Sales Reports</h4>
        <Seprator />
        <div className="flex justifySpaceBetween alignCenter innerHeader">
          {chartData ? (
            <EChart data={chartData} height={chartHeight} />
          ) : (
            <div
              style={{ minHeight: '200px', width: '100%' }}
              id={`sales_chart`}
            >
              <div>No data found</div>
            </div>
          )}
        </div>
      </Card>
    </WrapperItemSalesGraph>
  );
};
type DivProps = JSX.IntrinsicElements['div'];

interface WrapperItemsSalesGraph extends DivProps {
  hasNoData?: boolean;
}

const WrapperItemSalesGraph = styled.div<WrapperItemsSalesGraph>`
  #sales_chart {
    position: relative;
  }
  #sales_chart::after {
    position: absolute;
    content: 'No Record Found';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #6e6e6d;
    display: flex;
    transition: 0.4s all ease-in-out;
    visibility: ${(props: any) => (props.hasNoData ? 'visible' : 'hidden')};
  }
`;
