import convertToRem from 'apps/main-ui/src/utils/convertToRem';
import styled from 'styled-components';
import { IThemeProps } from '../../../../hooks/useTheme/themeColors';

interface WrapperInvoiceFormProps {
  isToggle: boolean;
}

export const Wrapper = styled.div<WrapperInvoiceFormProps>`
  min-height: 100vh;
  overflow-y: scroll;
  .table-wrapper {
    .add_item > button {
      margin: ${convertToRem(18)};
      color: #7988ff !important;
      border: 1px solid #7988ff;
    }
  }

  .narrations {
    margin: ${convertToRem(18)};
  }

  .ant-card-body {
    padding: 0px !important;
    min-height: 70vh;
  }
  .file_drop_zone {
    margin: ${convertToRem(10)} ${convertToRem(18)} ${convertToRem(26)}
      ${convertToRem(26)};
  }
  .actions_control {
    margin: 0 !important;
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${convertToRem(10)};
    padding: ${convertToRem(10)} ${convertToRem(10)} ${convertToRem(10)}
      ${convertToRem(10)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
  }

  .actions.fixed-actions {
    position: fixed;
    width: ${(props) =>
      props.isToggle ? `calc(100% - 258px)` : `calc(100% - 107px)`};
    bottom: 2px;
    z-index: 1000;
  }

  .table-footer {
    tbody {
      tr td {
        padding: 10px;
      }
    }
  }
`;
