import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Form,
  Row,
  Col,
  Input,
  Card,
  Select,
  InputNumber,
  Radio,
  Switch,
} from 'antd';
import {
  FormLabel,
  Heading,
  Para,
  EnterpriseWrapper,
  ButtonTag,
} from '@components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  create_update_contact,
  viewSingleContact,
  getAllAccounts,
  getBankAccounts,
} from '../../../api';
import {
  IContactTypes,
  ISupportedRoutes,
  NOTIFICATIONTYPE,
  IAccountsResult,
  IOrganizationType,
  ReactQueryKeys,
} from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { AddressForm } from './addressForm';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useLocation } from 'react-router-dom';
import en from 'world_countries_lists/data/en/world.json';
import phoneCodes from '../../../utils/phoneCodes';
import { IThemeProps } from '@invyce/shared/invyce-theme';
import convertToRem from 'apps/main-ui/src/utils/convertToRem';

const { Option } = Select;

interface IProps {
  id?: any;
}

export const ContactsForm: FC<IProps> = ({ id }) => {
  /* HOOKS */

  /* user context API hook*/
  const { notificationCallback } = useGlobalContext();
  const [hasOpeningBalance, setHasOpeningBalance] = useState(false);
  const [hasSameAddress, setSameAddress] = useState<boolean>(false);
  const [showOpeningBlance, setShowOpeningBalance] = useState(false);
  const [address, setAddress] = useState([
    {
      description: '',
      addressType: 1,
      city: '',
      town: '',
      country: '',
      postalCode: '',
    },
    {
      description: '',
      addressType: 2,
      city: '',
      town: '',
      country: '',
      postalCode: '',
    },
  ]);

  const location = useLocation();

  /* Use form hook antd */
  const [form] = Form.useForm();
  const queryCache = useQueryClient();

  const { data: AllAccounts } = useQuery(
    [`all-accounts`, 'ALL'],
    getAllAccounts
  );

  const debitedAccounts: IAccountsResult[] =
    (AllAccounts &&
      AllAccounts.data &&
      AllAccounts.data.result &&
      AllAccounts.data.result.filter(
        (acc) => acc?.secondaryAccount?.primaryAccount?.name === 'asset'
      )) ||
    [];
  const creditedAccounts: IAccountsResult[] =
    (AllAccounts &&
      AllAccounts.data &&
      AllAccounts.data.result &&
      AllAccounts.data.result.filter(
        (acc) =>
          acc?.secondaryAccount?.primaryAccount?.name === 'liability' ||
          acc?.secondaryAccount?.primaryAccount?.name === 'equity' ||
          acc?.secondaryAccount?.primaryAccount?.name === 'revenue'
      )) ||
    [];

  useEffect(() => {
    if (location?.state && location?.state !== undefined) {
      if (location?.state === 'customers') {
        form.setFieldsValue({ contactType: IContactTypes.CUSTOMER });
      } else if (location?.state === 'suppliers') {
        form.setFieldsValue({ contactType: IContactTypes.SUPPLIER });
      }
    }
  }, [location?.state]);

  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;

  /* Mutation to create and update contact */
  const {
    mutate: mutateAddContact,
    isSuccess,
    isLoading,
  } = useMutation(create_update_contact);

  /*Query hook for  Fetching single contact against ID */
  const { data } = useQuery([`contact-${id}`, id], viewSingleContact, {
    enabled: !!id,
    onSuccess: () => {
      /* when successfully created OR updated toast will be apear */
      /* three type of parameters are passed
        first: notification type
        second: message title
        third: message description
        */
      notificationCallback(NOTIFICATIONTYPE.SUCCESS, 'Contact Fetched');
    },
  });

  /* Component did update hook to update form values when contact
   against ID is successfull fetches */
  useEffect(() => {
    if (id && data && data?.data?.result) {
      const { result } = data?.data;
      const { addresses } = result;

      form.setFieldsValue({
        ...result,
      });
      if (addresses?.length === 1) {
        setAddress((prev) => {
          prev.splice(0, 1, { ...addresses[0] });
          return prev;
        });
      } else if (addresses.length) {
        setAddress(addresses);
        const areDescriptionsSame =
          addresses.length > 1
            ? addresses.every(
                (addr) => addr.description === addresses[0].description
              )
            : true;
        const areCitiesSame =
          addresses.length > 1
            ? addresses.every((addr) => addr.city === addresses[0].city)
            : true;
        if (areDescriptionsSame && areCitiesSame) {
          setSameAddress(true);
        } else {
          setSameAddress(false);
        }
      }
    }
  }, [data, id, form]);

  const getFlag = (short: string) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const data = require(`world_countries_lists/flags/24x24/${short.toLowerCase()}.png`);
    // for dumi
    if (typeof data === 'string') {
      return data;
    }
    // for CRA
    return data.default;
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ width: 100 }}
        showSearch
        defaultValue={`92`}
        filterOption={(input, option) => {
          return (
            option?.id?.toLowerCase().includes(input?.toLocaleLowerCase()) ||
            option?.title?.toLowerCase().includes(input?.toLocaleLowerCase())
          );
        }}
      >
        {phoneCodes?.map((country) => {
          return (
            <Option
              value={`${country?.phoneCode}`}
              title={`${country?.phoneCode}`}
              id={`${country?.short}`}
            >
              <img
                className="mr-10"
                alt="flag"
                style={{ width: 18, height: 18, verticalAlign: 'sub' }}
                src={getFlag(country.short)}
              />
              <span>+{country?.phoneCode}</span>
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  /* HOOKS ENDS HERE */

  /* Async function to create and update a contact */
  const handleSubmit = async (values) => {
    let payload = {
      ...values,
      isNewRecord: true,
      addresses: address,
    };

    if (id) {
      payload = {
        ...payload,
        isNewRecord: false,
        id: id,
      };
    }

    await mutateAddContact(payload, {
      onSuccess: () => {
        [
          `contacts-list`,
          `all-contacts`,
          ReactQueryKeys?.TRANSACTION_KEYS,
        ].forEach((key) => {
          (queryCache.invalidateQueries as any)((q) => q.startsWith(key));
        });
        if (id) {
          [
            `contacts-list`,
            `all-contacts`,
            `contact-${id}`,
            ReactQueryKeys?.TRANSACTION_KEYS,
          ].forEach((key) => {
            (queryCache.invalidateQueries as any)((q) => q.startsWith(key));
          });
        }
        /* when successfully created OR updated toast will be apear */
        /* three type of parameters are passed
        first: notification type
        second: message title
        third: message description
        */
        form.resetFields();
        notificationCallback(
          NOTIFICATIONTYPE.SUCCESS,
          id ? 'Updated Successfully' : 'Created Successfully'
        );
        history.push({
          pathname: `/app${ISupportedRoutes?.CONTACTS}`,
          state: {
            from: history.location.pathname,
          },
        });
      },
    });
  };

  const { data: allBanks } = useQuery([`bank-accounts`], getBankAccounts);

  const banksList = allBanks?.data?.result || [];

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleSameSecondaryAddress = (checked: boolean) => {
    setSameAddress(checked);
    const addresses = [...address];
    const indexed = addresses.findIndex((adress) => adress.addressType === 1);
    if (indexed > -1) {
      const { description, city, country, postalCode } = address[indexed];
      const secondaryIndex = addresses.findIndex(
        (adress) => adress.addressType === 2
      );
      if (secondaryIndex > -1) {
        addresses[secondaryIndex] = {
          ...addresses[secondaryIndex],
          description,
          city,
          country,
          postalCode,
        };
        setAddress(addresses);
      } else {
        addresses.push({ ...addresses[indexed], addressType: 2 });
        setAddress(addresses);
      }
    } else {
      notificationCallback(
        NOTIFICATIONTYPE.ERROR,
        "Postal Address isn't provided"
      );
    }
  };

  return (
    <WrapperContactsForm>
      <Form
        form={form}
        onValuesChange={(changedValue, values) => {
          if (
            changedValue?.openingBalance &&
            changedValue?.openingBalance !== '0'
          ) {
            setHasOpeningBalance(true);
          } else if (changedValue?.openingBalance) {
            if (hasOpeningBalance) {
              setHasOpeningBalance(false);
            }
          }
        }}
        // onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card>
          <Row gutter={[60, 5]}>
            <Col span={24} offset={0}>
              <div className="pv-20">
                <Heading type="container" className="mb-12">
                  Personal Information
                </Heading>
                <Para type="heading-description">
                  Please input details of your Potential Customers
                </Para>
              </div>
            </Col>

            <Col span={24}>
              <FormLabel isRequired={true}>Contact Type</FormLabel>
              <Form.Item
                name="contactType"
                rules={[{ required: true, message: 'Select Contact Type' }]}
              >
                <Radio.Group size="large">
                  <Radio value={IContactTypes.CUSTOMER}>Customer</Radio>
                  <Radio value={IContactTypes.SUPPLIER}>Supplier</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={true}>
                Primary Contact Person's Name (Contact Type)
              </FormLabel>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please Primay Person',
                  },
                  {
                    pattern: /^\S/,
                    message: 'Please Primay Person',
                  },
                ]}
              >
                <Input
                  placeholder={'Contact Name'}
                  autoComplete="off"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={true}>Email</FormLabel>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: false,
                    message: 'Please provide Email',
                    type: 'email',
                  },
                ]}
              >
                <Input
                  placeholder={'john@example.com'}
                  autoComplete="off"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={true}>Mobile Number</FormLabel>
              <Form.Item
                name="cellNumber"
                rules={[
                  {
                    required: false,
                    message: 'Please add your last name',
                  },
                  { max: 12, min: 4 },
                ]}
              >
                <Input
                  autoComplete="off"
                  addonBefore={prefixSelector}
                  type="text"
                  placeholder="3188889898"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={false}>Skype Name / Number</FormLabel>
              <Form.Item
                name="skypeName"
                rules={[
                  { required: false, message: 'Please provide Skype ID' },
                ]}
              >
                <Input
                  autoComplete="off"
                  placeholder={'live:@example'}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={24} offset={0}>
              <div className="pv-20">
                <Heading type="container">Business Details</Heading>
              </div>
            </Col>

            <Col span={24} className="mb-8">
              <Para type="heading-description">
                All defaults can be overridden on individual transactions
              </Para>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={true}>Business Name</FormLabel>
              <Form.Item
                name="businessName"
                rules={[
                  { required: true, message: 'Please provide Company Name' },
                  {
                    pattern: /^\S/,
                    message:
                      'Please remove Whitespaces and provide Company Name',
                  },
                ]}
              >
                <Input
                  placeholder={'Company Name'}
                  autoComplete="off"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={false}>Website</FormLabel>
              <Form.Item
                name="webLink"
                rules={[
                  {
                    required: false,
                    message: 'Please provide website',
                    type: 'url',
                  },
                ]}
              >
                <Input
                  placeholder={'http://www.phunar.example.com'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={false}>Phone Number</FormLabel>
              <Form.Item
                shouldUpdate
                name="phoneNumber"
                rules={[
                  {
                    message: 'Please provide Phone Number',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'(Area code + Alot number). eg : 05811-45XXXX'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel isRequired={false}>Fax Number</FormLabel>
              <Form.Item
                name="faxNumber"
                rules={[
                  {
                    required: false,
                    message: 'Please provide Fax Number',
                  },
                  {
                    max: 10,
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'0215XXXX'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>

            {/* <Col span={24}>
            <div className="textRight">
              <Button
                onClick={() => {
                 
                }}
                type="link"
                size="middle"
              >
                Same as postal address
              </Button>
            </div>
          </Col> */}
            <Col span={24}>
              <AddressForm
                reset={isSuccess}
                item={address[0]}
                onChange={(values) => {
                  const payload = {
                    ...values,
                    addressType: address[0]['addressType'],
                  };
                  const addressArr = [...address];
                  const indexed = addressArr.findIndex(
                    (ind) =>
                      ind && ind.addressType === address[0]['addressType']
                  );
                  if (indexed > -1) {
                    addressArr[indexed] = {
                      ...addressArr[indexed],
                      ...payload,
                    };
                    setAddress(addressArr);
                  } else {
                    addressArr.push(payload);
                    setAddress(addressArr);
                  }
                }}
                state={address}
              />
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <div className="flex alignCenter mv-10">
                <Switch
                  size="small"
                  checked={hasSameAddress}
                  onChange={handleSameSecondaryAddress}
                />
                <Heading type="sub-heading" className="ml-7">
                  Same as Billing Address
                </Heading>
              </div>
            </Col>
            {!hasSameAddress && (
              <Col span={24}>
                <AddressForm
                  reset={isSuccess}
                  item={address[1]}
                  onChange={(values) => {
                    const payload = {
                      ...values,
                      addressType: address[1]['addressType'],
                    };
                    const addressArr = [...address];
                    const indexed = addressArr.findIndex(
                      (ind) =>
                        ind && ind.addressType === address[1]['addressType']
                    );
                    if (indexed > -1) {
                      addressArr[indexed] = {
                        ...addressArr[indexed],
                        ...payload,
                      };
                      setAddress(addressArr);
                    } else {
                      addressArr.push(payload);
                      setAddress(addressArr);
                    }
                  }}
                  state={address}
                />
              </Col>
            )}
          </Row>

          <Row gutter={24}>
            <Col span={24} offset={0}>
              <div className="pv-20">
                <Heading type="container">Financial Details</Heading>
              </div>
            </Col>

            <Col span={12} className="mb-12">
              <Para type="heading-description">
                All defaults can be overridden on individual transactions
              </Para>
            </Col>

            {!data?.data?.result?.hasOpeningBalance && (
              <Col span={12} className="mb-12">
                <div className="flex alignCenter">
                  <Switch
                    size="small"
                    checked={showOpeningBlance}
                    onChange={(cheked) => setShowOpeningBalance(cheked)}
                  />
                  <span className="ml-8"> Enable Opening Balance</span>
                </div>
              </Col>
            )}

            <Col span={12}>
              <FormLabel isRequired={true}>Account Number</FormLabel>
              <Form.Item
                name="accountNumber"
                rules={[
                  {
                    required: false,
                    message: 'Please provide Account Number',
                  },
                  {
                    pattern: /^\S/,
                    message: 'Please provide Account Number',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={''}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <FormLabel>Select Bank</FormLabel>
              <Form.Item name="bankId">
                <Select
                  loading={isLoading}
                  size="large"
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select Bank"
                  optionFilterProp="children"
                >
                  {banksList.map((bank, index) => {
                    return (
                      <Option key={index} value={bank.accountId}>
                        {bank.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {showOpeningBlance && !data?.data?.result?.hasOpeningBalance && (
              <>
                <Col span={12}>
                  <FormLabel isRequired={false}>Opening Balance</FormLabel>
                  <Form.Item
                    name="openingBalance"
                    rules={[{ required: false, message: 'Opening Balance' }]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder={''}
                      size="large"
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <EnterpriseWrapper enable={[IOrganizationType.SAAS]}>
                  <Col span={12}>
                    <FormLabel isRequired={false}>Debit Account</FormLabel>
                    <Form.Item
                      name="debitAccount"
                      rules={[{ required: false, message: 'Debit Account' }]}
                    >
                      <Select
                        disabled={!hasOpeningBalance}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Item"
                        optionFilterProp="children"
                      >
                        {debitedAccounts.length &&
                          debitedAccounts.map((acc: IAccountsResult, index) => {
                            return (
                              <Option key={index} value={acc.id}>
                                {acc.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </EnterpriseWrapper>
                <EnterpriseWrapper enable={[IOrganizationType.SAAS]}>
                  <Col span={12}>
                    <FormLabel isRequired={false}>Credit Account</FormLabel>
                    <Form.Item
                      name="creditAccount"
                      rules={[{ required: false, message: 'Credit Account' }]}
                    >
                      <Select
                        disabled={!hasOpeningBalance}
                        size="large"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Item"
                        optionFilterProp="children"
                      >
                        {creditedAccounts.length &&
                          creditedAccounts.map(
                            (acc: IAccountsResult, index) => {
                              return (
                                <Option key={index} value={acc.id}>
                                  {acc.name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>
                  </Col>
                </EnterpriseWrapper>
              </>
            )}

            <Col span={12}>
              <FormLabel isRequired={true}>Credit Limit Amount</FormLabel>
              <Form.Item
                name="creditLimit"
                rules={[{ required: true, message: 'Please add Credit Limit' }]}
              >
                <InputNumber
                  type="number"
                  style={{ width: '100%' }}
                  placeholder={''}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <FormLabel isRequired={true}>Credit Limit Block </FormLabel>
              <Form.Item
                name="creditLimitBlock"
                rules={[
                  {
                    required: true,
                    message: 'Please credit block limit',
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  style={{ width: '100%' }}
                  placeholder={'add credit limit block'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <FormLabel isRequired={true}>Payment Days Limit</FormLabel>
              <Form.Item
                name="paymentDaysLimit"
                rules={[
                  {
                    required: true,
                    message: 'Please provide payment days limit',
                  },
                  {
                    pattern: /^\S/,
                    message: 'Please provide payment days limit',
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={'Please add payment days limit'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <FormLabel isRequired={true}>Credit Discount % </FormLabel>
              <Form.Item
                name="salesDiscount"
                rules={[
                  {
                    required: true,
                    message: 'If no any discount please type 0',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  type="number"
                  placeholder={'Sales discount in percentage or amount'}
                  size="large"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={24}>
                {/* <Col span={24}>
                <FormLabel isRequired={true}>Select Bank</FormLabel>
                <Form.Item name="bankId">
                  <Select
                    loading={isLoading}
                    size="large"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select Bank"
                    optionFilterProp="children"
                  >
                    {banksList.map((bank, index) => {
                      return (
                        <Option key={index} value={bank.accountId}>
                          {bank.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
              </Row>
            </Col>
          </Row>
        </Card>
        <Row gutter={24} className="actions">
          <Col span={24} offset={0}>
            <Form.Item>
              <div className="actions-wrapper">
                <ButtonTag
                  onClick={() => {
                    form.resetFields();
                    history.push(`/app${ISupportedRoutes.CONTACTS}`);
                  }}
                  size="middle"
                  type="default"
                  title="Cancel"
                />
                <ButtonTag
                  loading={isLoading}
                  type="primary"
                  size="middle"
                  onClick={() => {
                    const value = form?.getFieldsValue();
                    handleSubmit(value);
                  }}
                  title={id ? 'Update' : 'Create'}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </WrapperContactsForm>
  );
};

const WrapperContactsForm: React.ReactElement<any> | any = styled.div`
  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0 ${convertToRem(5)};
      margin-top: ${convertToRem(35)};
    }
  }
`;
