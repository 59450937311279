import { IThemeProps } from '@invyce/shared/invyce-theme';
import styled from 'styled-components';
import convertToRem from '../../../../../utils/convertToRem';

export const WrapperItemsView = styled.div`
  ._itemviewcard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: ${convertToRem(16)};
    .item_amount {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.emptyStateDescription} !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: normal;
      letter-spacing: 0.64px;
      text-transform: capitalize;
      margin-top: 6px !important;
    }
    .small_item_amount {
      font-size: ${convertToRem(14)};
      line-height: ${convertToRem(12)};
      margin-top: ${convertToRem(14)};
      font-weight: 400;
      display: flex;
      justify-content: center;
    }
  }
  .viewSmallCard {
    min-width: ${convertToRem(130)};
    max-width: ${convertToRem(150)};
    min-height: ${convertToRem(240)};
    max-height: ${convertToRem(260)};
    margin: 0 ${convertToRem(2)};
  }
  .small_item_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ._itemdetailcard {
    padding: ${convertToRem(16)};
    color: ${(props: IThemeProps) => props.theme.colors.$BLACK};

    .detail_area {
      padding: 8px 12px;
      border-radius: 12px;
      border: 1px solid #eaeaea;

      p {
        color: ${(props: IThemeProps) =>
          props.theme.colors.emptyStateDescription};
        font-size: ${convertToRem(15)};
      }
    }
  }
  ._itemdetailcard {
    min-height: ${convertToRem(272)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    margin-bottom: ${convertToRem(16)};
    h3 {
      margin-left: ${convertToRem(20)};
      margin-bottom: ${convertToRem(23)};
    }
    p {
      font-size: ${convertToRem(16)};
      margin-bottom: ${convertToRem(23)};
    }
    h4 {
      margin-bottom: ${convertToRem(23)};
    }
    ._imageContainer {
      max-width: ${convertToRem(260)};
      max-height: ${convertToRem(260)};
      padding: ${convertToRem(10)};
      background-color: #f8f8f8;
      img {
        width: 100%;
        height: 100%;
      }
    }
    ._columnCard {
      display: flex;
      align-items: center;
    }
  }
  ._smallitemdetailcard {
    min-height: ${convertToRem(285)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    margin-bottom: ${convertToRem(16)};
    padding: ${convertToRem(5)};
  }
  ._salesitemcard {
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(272)};
    margin-bottom: ${convertToRem(16)};
  }
  ._topitemcard {
    margin-bottom: ${convertToRem(16)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(310)};
  }
  ._otherlinkcard {
    margin-bottom: ${convertToRem(16)};
    min-height: ${convertToRem(310)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    .datalinkcard {
      margin-bottom: ${convertToRem(26)};
      margin-top: ${convertToRem(26)};
      a {
        margin-left: ${convertToRem(16)};
      }
    }
  }

  ._color {
    background: #00b9ff30;
  }
  ._color1 {
    background: #fbcf323b;
  }
  ._color2 {
    background: rgba(0, 245, 98, 0.233);
  }
  ._color3 {
    background: rgba(38, 0, 255, 0.212);
  }
  ._color4 {
    background: #e2b242;
  }
  ._color5 {
    background: #7988ff;
  }
  ._color6 {
    background: #e78035;
  }
  ._color7 {
    background: #1e75f1;
  }
  ._color8 {
    background: rgba(226, 178, 66, 0.5);
  }
  ._color9 {
    background: rgba(121, 136, 255, 0.5);
  }
  ._color10 {
    background: rgba(231, 128, 53, 0.5);
  }
  ._color11 {
    background: rgba(30, 117, 241, 0.5);
  }
  ._cardBg1 {
    background: rgba(226, 178, 66, 0.1);
  }
  ._cardBg2 {
    background: rgba(121, 136, 255, 0.1);
  }
  ._cardBg3 {
    background: rgba(231, 128, 53, 0.1);
  }
  ._cardBg4 {
    background: rgba(30, 117, 241, 0.1);
  }
  ._cardBg5 {
    background: #f8f8f8;
  }
`;
