import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #000;
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212b3b;
`;

const FooterText = styled.p`
  margin: 0 0 4px;
  color: #666565;
`;

const TermsSection = styled.div`
  padding: 20px;
  text-align: left;
`;

const TotalSection = styled.div`
  text-align: right;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Label = styled.span`
  font-weight: bold;
  color: #212b3b;
  margin-right: 20px;
`;

const Value = styled.span`
  color: #7988ff;
`;
const Underline =styled.div`
margin-bottom: 8px;
margin-top: 8px;
border: 1px solid #7988ff !important;
`
const PrintFooter = () => {
  return (
    <>
      <FooterContainer>
        <FooterSection>
          <FooterTitle>Payment Information :</FooterTitle>
          <FooterText>Account Name : Studio Shodwe</FooterText>
          <FooterText>Account Number : 123-456-7890</FooterText>
          <FooterText>Email Address : hello@reallygreatsite.com</FooterText>
          <FooterText>Website : www.reallygreatsite.com</FooterText>
        </FooterSection>
        <TotalSection>
          <TotalRow>
            <Label>Sub Total :</Label>
            <Value>$3,450</Value>
          </TotalRow>
          <TotalRow>
            <Label>Discount :</Label>
            <Value>-</Value>
          </TotalRow>
          <TotalRow>
            <Label>Tax (10%) :</Label>
            <Value>-</Value>
          </TotalRow>
          <Underline className=''/>
          <TotalRow>
            <Label>Grand Total</Label>
            <Value>$3645</Value>
          </TotalRow>
        </TotalSection>
      </FooterContainer>
      <TermsSection>
        <FooterTitle>Term & Condition :</FooterTitle>
        <FooterText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
          tristique lobortis purus, et cursus enim maximus ac. Quisque arcu
          eros, feugiat in tellus sit amet.
        </FooterText>
      </TermsSection>
    </>
  );
};

export default PrintFooter;
