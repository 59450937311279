import React, { FC } from 'react';
import { WrapperUser } from './styles';
import { ButtonTag, Heading } from '@components';
import { UsersList } from './UsersList/index';
import { useGlobalContext } from '../../hooks/globalContext/globalContext';
import { Rbac } from '../../components/Rbac';
import { PERMISSIONS } from '../../components/Rbac/permissions';

export const UsersContainer: FC = () => {
  const { setUserInviteModal } = useGlobalContext();
  return (
    <WrapperUser>
      <div className="flex justifySpaceBetween flexWarp pv-10">
        <Heading type="table">Users</Heading>
        <Rbac permission={PERMISSIONS.USERS_CREATE}>
          <ButtonTag
            onClick={() => setUserInviteModal(true)}
            className="ml-10"
            size="middle"
            type="primary"
            title=" Add User"
          />
        </Rbac>
      </div>
      <UsersList />
    </WrapperUser>
  );
};
