import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { GeneralLedgerSummaryList } from './List';
import { GeneralLedgerSummaryContainer } from './styled';

export const GeneralLedgerSummary: FC = () => {
  return (
    <GeneralLedgerSummaryContainer>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <GeneralLedgerSummaryList />
        </Col>
      </Row>
    </GeneralLedgerSummaryContainer>
  );
};
