import { Button, Select } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { IThemeProps } from '../../hooks/useTheme/themeColors';
import moneyFormat from '../../utils/moneyFormat';
import { Card } from '../Card';
import { P } from '../Para/P';
import { Seprator } from '../Seprator';
import { H4 } from '../Typography';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Reminder = () => {
  return (
    <WrapperPayablesOverview>
      <Card className="_card_wrapper">
        <div className="flex alignCenter justifySpaceBetween">
          <H4 className="reminder_heading">Reminder</H4>
        </div>
        <Seprator />
        <div className="mt-20">
          {[0, 1, 2, 3, 4, 5, 6, 7].map((item, index) => (
            <div key={index} className="flex alignCenter listItem">
              <div>
                <Avatar
                  size={41}
                  className="user_avatar flex alignCenter justifyCenter"
                  icon={<UserOutlined size={28} />}
                />
              </div>
              <div className="margin-left">
                <P className="sales_desc">Net income of last 15 days</P>
                <P className="sales_price">5 mint ago</P>
              </div>
            </div>
          ))}
        </div>
        <div className="footer flex alignEnd justifyEnd mt-20">
          <Button type="link" size="middle">
            See More
          </Button>
        </div>
      </Card>
    </WrapperPayablesOverview>
  );
};
const WrapperPayablesOverview = styled.div`
  .listItem {
    background: ${(props: IThemeProps) => props?.theme?.colors?.sidebarBg};
    border-radius: 4px;
    margin: 12px 0;
    padding: 11px 8px;
  }
  .margin-left {
    margin-left: 10px;
  }
  .sales_price{
    margin-top:3px;
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark'
        ? '#ffffff99'
        : 'rgba(62, 62, 60, 0.75)'} !important;
      font-weight:500;
      font-size:10px;
      letter-spacing:0px;
      text-transform:none;
  }
  .sales_desc{
    font-weight:500;
  }
  .reminder_heading{
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : '#334d6e'};
  }
  }
`;

export default Reminder;
