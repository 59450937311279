import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts/theme/macarons';
import { ChartType } from '../../modal/items';

type EChartsOption = echarts.EChartsOption;

interface EChartProps {
  data: {
    xAxisData: string[];
    seriesData: number[];
  };
  type?: ChartType;
  height?: string;
}

const EChart: React.FC<EChartProps> = ({
  data,
  type = ChartType.Bar,
  height = '400px',
}) => {
  const { xAxisData, seriesData } = data;

  useEffect(() => {
    const chartDom = document.getElementById('main')!;
    const myChart = echarts.init(chartDom);

    const option: EChartsOption = {
      grid: {
        left: '5%', 
        right: '5%', 
        top: '5%', 
        bottom: '10%', 
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: seriesData,
          type: type,
          barWidth: 40,
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [xAxisData, seriesData, type]);

  return <div id="main" style={{ width: '100%', height: height }} />;
};

export default EChart;
