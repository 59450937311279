import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { FC, useEffect } from 'react';
import en from 'world_countries_lists/data/en/world.json';
import { FormLabel } from '@components';

interface IProps {
  item: any;
  state?: any;
  onChange: (payload: any) => void;
  index?: number;
  reset: boolean;
}

export const AddressForm: FC<IProps> = ({ onChange, item, index, reset }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item, form]);

  useEffect(() => {
    if (reset === true) {
      form.resetFields();
    }
  }, [reset, form]);

  const getFlag = (short: string) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const data = require(`world_countries_lists/flags/24x24/${short.toLowerCase()}.png`);
    // for dumi
    if (typeof data === 'string') {
      return data;
    }
    // for CRA
    return data.default;
  };

  return (
    <Form
      form={form}
      onValuesChange={(changedValues, values) => {
        onChange(values);
      }}
    >
      <Row gutter={[60, 5]}>
        <Col span={12}>
          <FormLabel isRequired={item.addressType === 1 ? true : false}>
            Postal Address
          </FormLabel>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: 'Please provide postal address',
              },
              {
                pattern: /^\S/,
                message: 'Please provide postal address',
              },
            ]}
          >
            <Input placeholder={'Address'} size="large" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormLabel isRequired={true}>City/State</FormLabel>
          <Form.Item
            name="city"
            rules={[{ required: false, message: 'Please provide City Name' }]}
          >
            <Input placeholder={'City/Town'} size="large" autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormLabel isRequired={true}>Country</FormLabel>
          <Form.Item name="country" rules={[{ required: true }]}>
            <Select
              size="large"
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a Country"
              filterOption={(input, option) => {
                return option?.title
                  ?.toLowerCase()
                  .includes(input?.toLocaleLowerCase());
              }}
            >
              {en?.map((country) => {
                return (
                  <Option title={country?.name} value={country?.name}>
                    <img
                      className="mr-10"
                      alt="flag"
                      style={{
                        width: 18,
                        height: 18,
                        verticalAlign: 'sub',
                      }}
                      src={getFlag(country.alpha2)}
                    />
                    <span>{country?.name}</span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <FormLabel isRequired={true}>Postal / Zip Code</FormLabel>
          <Form.Item
            name="postalCode"
            rules={[
              {
                required: false,
                message: 'Please provide Skype ID',
              },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: '100%' }}
              placeholder={'Postal/ Zip Code'}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
