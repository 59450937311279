import React, { FC, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import {
  Card,
  Heading,
  SmartFilter,
  TableTabs,
  TableTabsContent,
} from '@components';
import FilterSchema from './filter';
import styled from 'styled-components';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { ISupportedRoutes } from '@invyce/shared/types';
import convertToRem from '../../../utils/convertToRem';
import { ReportItems } from './ReportsItems';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import ReportIcon from '../../../assets/reportSvg.svg';

export const ReportSummaryList: FC = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const [activeTab, setActiveTab] = useState(1);
  const { query } = config;
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(1);
    }
  }, [activeTab]);

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });
      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const RenderButton = () => {
    return (
      <div className="_disable_print flex alignCenter">
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.AGED_PAYABLES}?query=${query}`
            );
          }}
        />
      </div>
    );
  };

  return (
    <WrapperReportSummaryList>
      <div className="flex alignCenter pv-10">
        <i className="mr-10">
          <img src={ReportIcon} alt="report icon" />
        </i>
        <Heading type="form">Reporting Summary</Heading>
      </div>
      <Heading type="sub-heading">
        Decoding Excellence: Transformative Insights Unveiled in Every Page of
        Our Reports.
      </Heading>
      <div className="tabs-active">
        <TableTabs
          defaultkey={`${activeTab}`}
          tabBarExtraContent={RenderButton()}
        >
          <>
            <TableTabsContent tab="Summary" key="1">
              <ReportItems />
            </TableTabsContent>
          </>
        </TableTabs>
      </div>
    </WrapperReportSummaryList>
  );
};

export const WrapperReportSummaryList = styled.div``;
