import { Seprator } from '@components';
import { Card, Avatar, Image, Comment, Tooltip, Button } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import convertToRem from '../../../../../utils/convertToRem';
import { IThemeProps } from '../../../../../hooks/useTheme/themeColors';

export const ItemActivity: FC = () => {
  return (
    <CardWrapper>
      <div className="flex">
        <h4>Activity</h4>
      </div>
      <Seprator />
      <div className="content_area">
        <Comment
          content=""
          author={
            <>
              <h4 className="heading mr-6">Ilhan Karim:</h4>
              <p className="para">Create New Invoice</p>
            </>
          }
          avatar={
            <Avatar
              className="userAvatar"
              src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
              alt="Han Solo"
            />
          }
          datetime={
            <Tooltip title="2016-11-22 11:22:33">
              <span>8 hours ago</span>
            </Tooltip>
          }
        />
        <Comment
          content=""
          author={
            <>
              <h4 className="heading mr-6">Ilhan Karim:</h4>
              <p className="para">Create New Invoice</p>
            </>
          }
          avatar={
            <Avatar
              className="userAvatar"
              src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
              alt="Han Solo"
            />
          }
          datetime={
            <Tooltip title="2016-11-22 11:22:33">
              <span>8 hours ago</span>
            </Tooltip>
          }
        />
        <Comment
          content=""
          author={
            <>
              <h4 className="heading mr-6">Ilhan Karim:</h4>
              <p className="para">Create New Invoice</p>
            </>
          }
          avatar={
            <Avatar
              className="userAvatar"
              src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
              alt="Han Solo"
            />
          }
          datetime={
            <Tooltip title="2016-11-22 11:22:33">
              <span>8 hours ago</span>
            </Tooltip>
          }
        />
        <div className="footer">
          <Button type="link" size="small">
            View More
          </Button>
        </div>
      </div>
    </CardWrapper>
  );
};

const CardWrapper = styled(Card)`
  min-height: ${convertToRem(272)};
  border-radius: ${convertToRem(8)}
  background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
  /* margin-bottom: ${convertToRem(16)}; */
  .ant-card-body {
    padding: ${convertToRem(14)} !important;
  }
  .content_area {
    .heading {
      font-size: ${convertToRem(14)};
      font-weight: 500;
      color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
    }
    .ant-comment-inner {
      padding: 12px !important;
    }
    .userAvatar {
      margin-top: -6px;
    }
    .ant-comment-content-author-name {
      display: flex;
      justify-content: space-between;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;
