import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { SupplierOutstandingContainerBalanceList } from './List';
import { SupplierOutstandingContainer } from './styled';

export const SupplierOutstandingBalance: FC = () => {
  return (
    <SupplierOutstandingContainer>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <SupplierOutstandingContainerBalanceList />
        </Col>
      </Row>
    </SupplierOutstandingContainer>
  );
};
