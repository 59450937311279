import React, { SFC } from 'react';
import styled from 'styled-components';
import { Card } from '../Card';
import { useGlobalContext } from '../../hooks/globalContext/globalContext';

import convertToRem from '../../utils/convertToRem';
import { H2, P } from '../Typography';
import { IThemeProps } from '../../hooks/useTheme/themeColors';
import { Button } from 'antd';

export const DashboardMessages: SFC = () => {
  const { userDetails } = useGlobalContext();
  return (
    <WrapperWellcome>
      <Card className="card">
        <div className="flex">
          <div className="inner">
            <H2 className="_heading">Welcome to Invyce</H2>
            <P className="m-reset">
              We are glad that you’ve chosen invyce as your invoice software.
              Invyce is a next generation invoicing software.
            </P>
            <Button className="dash_button">Create Invoice</Button>
          </div>
          <div>
            <img
              alt="flag"
              style={{
                width: 208,
                height: 206,
                objectFit: 'contain',
                marginTop: '-12px',
              }}
              src="/assets/phone.png"
            />
          </div>
        </div>
      </Card>
    </WrapperWellcome>
  );
};

const WrapperWellcome = styled.div`
  margin: ${convertToRem(5)} 0;
  .card {
    background: #7988ff;
    border-radius:8px;
    height: 206px !important;
  }
  .inner {
    box-shadow: 0px 4px 40px 0px rgba(61, 63, 101, 0.02);
    padding: 22px 5px 24px 20px;
    h2 {
      color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
    }
    p {
      color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
      padding: 13px 0px 24px 0px;
    }
    button.dash_button {
      color: ${(props: IThemeProps) =>
        props?.theme?.theme === 'dark' ? '#7988FF' : '#7988FF'};
      background: white;
      border-radius: 4px;
      border: 0px;
      font-weight: 600;
    }
  }
`;
