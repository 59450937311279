import {
  ReactQueryKeys,
} from '@invyce/shared/types';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Icon from '@iconify/react';
import { getItemDetail,getItemByIDAPI } from '../../../../api';
import { Card } from '../../../../components/Card';
import { CustomDateRange } from '../../../../components/DateRange';
import InvoiceIcon from '@iconify-icons/mdi/file-document-box-plus';
import { Heading } from '../../../../components/Heading';
import { Seprator } from '../../../../components/Seprator';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import { ISupportedRoutes } from '../../../../modal';
import { IItemViewResponse } from '../../../../modal/items';
import moneyFormat from '../../../../utils/moneyFormat';
import { ItemSalesGraph } from './ItemSalesGraph';
import { SummaryItem } from './SummaryItem';
import { WrapperItemsView } from './SummaryItem/styles';
import { TopItemsOverview } from './TopItemOverview';
import {
  TotalInvoices,
  AwaitedApproval,
  DraftInvoices,
  SummedBilledInvoices,
  SalePriceIcon,
  PurchasePriceIcon,
  StockAble,
} from '@components';
import { ItemActivity } from './itemActivity';
import moment from 'moment';

export const ItemsViewContainer: FC = () => {
  /* HOOKS HERE */
  const { routeHistory, setItemsModalConfig } = useGlobalContext();
  const { history } = routeHistory;
  const { location } = routeHistory.history;
  const [apiConfig, setApiConfig] = useState({
    id: null,
    start: dayjs(),
    end: dayjs(),
  });
  const { id, start, end } = apiConfig;
  const [{ result, message }, setItemDetails] = useState<IItemViewResponse>({
    result: null,
    message: '',
  });

  useEffect(() => {
    const routeId = location.pathname.split(
      `${ISupportedRoutes.DASHBOARD_LAYOUT}${ISupportedRoutes.ITEMS}/`
    )[1];
    setApiConfig({ ...apiConfig, id: routeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { data: itemDetailData, isLoading: itemDetailFetching } = useQuery(
    [ReactQueryKeys?.ITEMS_VIEW, apiConfig?.id],
    getItemByIDAPI,
    {
      enabled: !!apiConfig?.id,
    }
  );

  useEffect(() => {
    if (itemDetailData && itemDetailData.data && itemDetailData.data.result) {
      setItemDetails(itemDetailData.data);
    }
  }, [itemDetailData]);

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  }

  return (
    <WrapperItemsView>
      <div className="flex justifySpaceBetween">
        <Heading type="table" className="mb-18">
          {result && result.name}
        </Heading>
        <div className="_filter textRight mb-10">
          <CustomDateRange
            size="small"
            onChange={(dates) => {
              if (dates && dates.length) {
                setApiConfig({ ...apiConfig, start: dates[0], end: dates[1] });
              } else {
                setApiConfig({ ...apiConfig, start: dayjs(), end: dayjs() });
              }
            }}
          />
        </div>
      </div>
      <Row gutter={24}>
        <Col
          className="gutter-row"
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <SummaryItem
            footerDesc="Total Stocks"
            card={{
              light:
                'linear-gradient(127deg, rgba(203,202,222,1) 0%, rgba(230,230,237,1) 33%, rgba(242,242,242,1) 100%)',
              dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
            }}
            icon_bg="_color4"
            icon={<TotalInvoices />}
            amount={(result?.stock && result?.stock) || 0}
          />
        </Col>

        <Col
          className="gutter-row"
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <SummaryItem
            icon_bg="_color5"
            card={{
              light:
                'linear-gradient(127deg, rgba(203,202,222,1) 0%, rgba(230,230,237,1) 33%, rgba(242,242,242,1) 100%)',
              dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
            }}
            footerDesc="Awaiting to approval"
            icon={<AwaitedApproval />}
            amount={moneyFormat(
              result?.totalBillsAmount ? result?.totalBillsAmount : 0
            )}
          />
        </Col>
        <Col
          className="gutter-row"
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <SummaryItem
            icon_bg="_color6"
            card={{
              light:
                'linear-gradient(22deg, rgba(204,227,255,1) 0%, rgba(230,230,238,1) 33%, rgba(233,240,248,1) 100%)',
              dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
            }}
            footerDesc="Draft invoices to process"
            icon={<DraftInvoices />}
            amount={(result && result?.salecount) || 0}
          />
        </Col>
        <Col
          className="gutter-row"
          xxl={{ span: 6 }}
          xl={{ span: 6 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <SummaryItem
            icon_bg="_color7"
            card={{
              light:
                'linear-gradient(339deg, rgba(255,255,255,1) 0%, rgba(238,233,233,1) 33%, rgba(220,220,220,1) 100%)',
              dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
            }}
            footerDesc="Total Sales"
            icon={<Icon color="#FFFFFF" icon={InvoiceIcon} />}
            amount={moneyFormat(
              result?.totalInvoicesAmount ? result?.totalInvoicesAmount : 0
            )}
          />
        </Col>
      </Row>

      <Row gutter={[30, 12]}>
        <Col
          xxl={{ span: 10 }}
          xl={{ span: 10 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card className="_itemdetailcard">
            <Row gutter={[40, 30]}>
              <Col span={12}>
                <div className="detail_area">
                  <p>Price</p>
                  <Heading type="normal" fontWeight="600">
                    {moneyFormat(result?.price?.salePrice) || '-'}
                  </Heading>
                </div>
              </Col>
              <Col span={12}>
                <div className="detail_area">
                  <p>Item Sale Price</p>
                  <Heading type="normal" fontWeight="600">
                    {moneyFormat(result?.price?.salePrice) || '-'}
                  </Heading>
                </div>
              </Col>
              <Col span={12}>
                <div className="detail_area">
                  <p>Item purchase Price</p>
                  <Heading type="normal" fontWeight="600">
                    {moneyFormat(result?.price?.purchasePrice) || '-'}
                  </Heading>
                </div>
              </Col>
              <Col span={12}>
                <div className="detail_area">
                  <p>Total Stock</p>
                  <Heading type="normal" fontWeight="600">
                    {result?.stock || 0}
                  </Heading>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xxl={{ span: 14 }}
          xl={{ span: 14 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ItemSalesGraph id={id} />
        </Col>
      </Row>

      <Row gutter={[30, 12]}>
        <Col
          xxl={{ span: 10 }}
          xl={{ span: 10 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card className="_itemdetailcard">
            <div className="flex  justifySpaceBetween">
              <h4>Item Details</h4>
              <Button
                onClick={() => setItemsModalConfig(true, id)}
                type="link"
                size="small"
              >
                Edit Item
              </Button>
            </div>
            <Seprator />
            <Row gutter={16}>
              <Col
                xxl={{ span: 18 }}
                xl={{ span: 18 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="flex">
                  <Col span={7}>
                    <h4>Title: </h4>
                  </Col>
                  <Col span={15}>
                    <span>{result && result?.name}</span>
                  </Col>
                </div>
                <div className="flex">
                  <Col span={7}>
                    <h4>Added Date: </h4>
                  </Col>
                  <Col span={15}>
                    <span>
                      {dayjs(result?.createdAt).format('DD/MM/YYYY h:mm A') ||
                        ''}
                    </span>
                  </Col>
                </div>
                <div className="flex">
                  <Col span={7}>
                    <h4>code: </h4>
                  </Col>
                  <Col span={15}>
                    <span>{result?.code}</span>
                  </Col>
                </div>
                <div className="flex">
                  <Col span={7}>
                    <h4>Description: </h4>
                  </Col>
                  <Col span={15}>
                    <span>
                      {result?.description && truncate(result?.description, 28)}
                    </span>
                  </Col>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xxl={{ span: 14 }}
          xl={{ span: 14 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <ItemActivity />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xxl={{ span: 24 }}
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Card className="_topitemcard">
            <div className="flex  justifySpaceBetween">
              <h4>Top 5 Items</h4>
              <Button
                onClick={() => history.push(`/app${ISupportedRoutes.ITEMS}`)}
                type="link"
                size="small"
              >
                View all
              </Button>
            </div>
            <Seprator />
            <TopItemsOverview />
          </Card>
        </Col>
      </Row>
    </WrapperItemsView>
  );
};
