import { Breadcrumb, Col, Row } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumbArea, Heading, TableCard } from '@components';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { ISupportedRoutes } from '@invyce/shared/types';
import { ContactsForm } from './ContactsForm';
import { WrapperContactsEditor } from './styles';
import Icon from '@iconify/react';
import fileInvoice from '@iconify/icons-bx/bx-file';
import ContactSvg from '../../../assets/contactSvg.svg';

export const ContactsEditorWidget: FC = () => {
  const { routeHistory } = useGlobalContext();

  const id =
    routeHistory &&
    routeHistory.location &&
    routeHistory.location.pathname.split('/')[3];

  return (
    <WrapperContactsEditor className="mh-36">
      <div className="flex alignCenter justifySpaceBetween">
        <div>
          <div className="headingWrapper flex alignCenter">
            <i className="mr-10">
              <img src={ContactSvg} alt="contact svg" />
            </i>
            <Heading type="form" className="pv-16">
              {id ? 'Update Contact' : 'Create New Contact'}
            </Heading>
          </div>
          <Heading type="sub-heading" className="pb-10">
            Elevate your business with a fresh invoice. Streamline processes for
            efficiency and success.
          </Heading>
        </div>
        <BreadCrumbArea>
          <Breadcrumb separator=">">
            <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
            <Breadcrumb.Item>
              <Link to={`/app${ISupportedRoutes.CONTACTS}`}>Contacts</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>New Contact</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbArea>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <ContactsForm id={id} />
        </Col>
      </Row>
    </WrapperContactsEditor>
  );
};
