import styled from 'styled-components';
import convertToRem from '../../../../../utils/convertToRem';
import { Drawer } from 'antd';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';

export const WrapperItemsView = styled.div`
  padding: 0;
  overflow-y: hidden;
  ._itemviewcard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: ${convertToRem(16)};
    .item_amount {
      font-size: ${convertToRem(16)};
      line-height: ${convertToRem(24)};
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${convertToRem(7)} 0;
      color: ${(props: IThemeProps) => props?.theme?.colors?.$TEXT};
    }
  }

  ._itemdetailcard {
    min-height: ${convertToRem(272)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    margin-bottom: ${convertToRem(16)};
    padding: 10px;
    .innerHeader {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      padding: 8px;
      margin: 0;

      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
      }
    }
    .btn {
      background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
      border: 1px solid #2395e7;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calc_Details {
      padding: ${convertToRem(16)};
      color: ${(props: IThemeProps) => props.theme.colors.$BLACK};

      .detail_area {
        padding: 8px 12px;
        border-radius: 12px;
        border: 1px solid #eaeaea;

        p {
          color: ${(props: IThemeProps) =>
            props.theme.colors.emptyStateDescription};
          font-size: ${convertToRem(15)};
        }
      }
    }
    h4 {
      margin-bottom: ${convertToRem(23)};
    }
    p {
      font-size: ${convertToRem(16)};
      margin-bottom: ${convertToRem(23)};
    }
  }
  ._salesitemcard {
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(272)};
    margin-bottom: ${convertToRem(13)};

    h4 {
      margin-bottom: ${convertToRem(10)};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
    }
    .innerHeader {
      background-color: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      padding: 15px 8px;
      margin: 0;
    }
    .itemOverview {
      margin: 0 10px;
    }
  }
  ._topitemcard {
    margin-bottom: ${convertToRem(16)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(310)};
  }
  ._otherlinkcard {
    margin-bottom: ${convertToRem(16)};
    min-height: ${convertToRem(310)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    .datalinkcard {
      margin-bottom: ${convertToRem(26)};
      margin-top: ${convertToRem(26)};
      a {
        margin-left: ${convertToRem(16)};
      }
    }
  }

  ._color {
    background: #e2b242;
  }
  ._color1 {
    background: #fbcf323b;
  }
  ._color2 {
    background: #e78035;
  }
  ._color3 {
    background: #7988ff;
  }
`;

export const ItemDrawer = styled(Drawer)`
  .ant-drawer-body {
    background-color: ${(props: IThemeProps) =>
      props?.theme?.colors?.layoutBg} !important;
    padding: 0;
  }
`;
