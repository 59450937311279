import { Card } from 'antd';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { BoldText } from '../../../../../components/Para/BoldText';
import convertToRem from '../../../../../utils/convertToRem';
import moneyFormat from '../../../../../utils/moneyFormat';
import { IThemeProps } from '@invyce/shared/invyce-theme';

interface ICardProps {
  light: string;
  dark: string;
}
interface IProps {
  amount: number | string;
  footerDesc: string;
  icon: ReactElement<any>;
  icon_bg: any;
  card?: any | ICardProps;
  size?: 'small';
}

export const SummaryItem: FC<IProps> = ({
  amount,
  footerDesc,
  icon,
  icon_bg,
  card,
  size,
}) => {
  return (
    <WrapperSummaryItem>
      <GlassCard card={card} className={`_itemviewcard`}>
        <div className="content_area">
          <div className="item_result flex justifyCenter">
            <div className={`${icon_bg}  icon flex alignCenter justifyCenter`}>
              <i className="flex alignCenter justifyCenter">{icon}</i>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="_footer_desc textCenter">
            <p className="m-reset">{footerDesc}</p>
            <BoldText className="item_amount">{amount}</BoldText>
          </div>
        </div>
      </GlassCard>
    </WrapperSummaryItem>
  );
};

const GlassCard = styled(Card)<{
  theme: IThemeProps;
  card: { light: string; dark: string };
}>`
  backdrop-filter: blur(75px);
  background: ${(props) =>
    props.theme?.theme === 'dark'
      ? props.card?.dark
      : props.card?.light} !important;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.09);
  .ant-card-body {
    padding: 20px !important;
  }
`;

const WrapperSummaryItem = styled.div`
  .ant-card-body {
    padding: 20px;
  }
  .item_result {
    margin: ${convertToRem(21)} 0;
    .icon {
      width: ${convertToRem(49)};
      height: ${convertToRem(49)};
      border-radius: 50%;
      i {
        font-size: ${convertToRem(27)};
        color: #1f9dff;
      }
    }
  }
`;
