import { Breadcrumb } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BreadCrumbArea, CreateInvoiceIcon, Heading } from '@components';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { InvoiceEditor } from './Editor';
import { Link } from 'react-router-dom';
import { ISupportedRoutes } from '@invyce/shared/types';
import fileInvoice from '@iconify/icons-bx/bx-file';
import Icon from '@iconify/react';

export const InvoiceEditorWidget: FC = () => {
  const { routeHistory } = useGlobalContext();
  const { location } = routeHistory;
  const id = location && location.pathname.split('/app/invoice-create/')[1];

  return (
    <WrapperInvoiceWidget>
       <div className="flex alignCenter justifySpaceBetween">
        <div>
      <div className="flex alignCenter">
        <i className="mr-12">
          <CreateInvoiceIcon />
        </i>
        <Heading type="form">New Invoice</Heading>
      </div>
      <Heading className="mr-17 pb-10" type="sub-heading">
        Elevate your business with a fresh invoice. Streamline processes for
        efficiency and success.
      </Heading>
      </div>
      <BreadCrumbArea>
        <Breadcrumb separator=">">
          <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
          <Breadcrumb.Item>
            <Link to={`/app${ISupportedRoutes.INVOICES}`}>Invoices</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="active_breadcrum">New Invoice</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </BreadCrumbArea>
       </div>
      <InvoiceEditor type="SI" id={id} />
    </WrapperInvoiceWidget>
  );
};

const WrapperInvoiceWidget = styled.div`
  .active_breadcrum {
    color: #7988ff;
  }
`;
