import React, { FC } from 'react';
import styled from 'styled-components';
import { CreateInvoiceIcon, Heading } from '@components';
import { InvoiceList } from './InvoiceList';

export const InvoiceContainer: FC = () => {
  return (
    <WrapperInvoice>
      <div className="invoice_header">
        <div className="flex alignCenter mb-5">
          <CreateInvoiceIcon />
          <Heading type="table">Invoice</Heading>
        </div>
      </div>
      <Heading type="sub-heading">
        Elevate your business with a fresh invoice. Streamline processes for
        efficiency and success.
      </Heading>
      <InvoiceList />
    </WrapperInvoice>
  );
};

export const WrapperInvoice = styled.div`
  .invoice_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
