import { ButtonTag, SmartFilter } from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import convertToRem from '../../../utils/convertToRem';
import styled, { css } from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { useQuery } from 'react-query';
import { getCashSummaryReport } from '../../../api';
import printDiv from '../../../utils/Print';
import { Card, Col, Row, Space } from 'antd';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

const columns = [
  {
    title: 'Monthly',
    dataIndex: 'monthly',
    key: 'monthly',
  },
  {
    title: 'November-12',
    dataIndex: 'november-12',
    key: 'november-12',
  },
  {
    title: 'October-12',
    dataIndex: 'october-12',
    key: 'october-12',
  },
  {
    title: 'September-12',
    dataIndex: 'september-12',
    key: 'september-12',
  },
  {
    title: 'August-12',
    dataIndex: 'august-12',
    key: 'august-12',
  },
  {
    title: 'July-12',
    dataIndex: 'july-12',
    key: 'july-12',
  },
  {
    title: 'June-12',
    dataIndex: 'june-12',
    key: 'june-12',
  },
];

const data = [
  {
    monthly: 'Total Income',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    title: 'Expenses',
    monthly: 'Bank Fees',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Total Income',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Total Income',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Cleaning',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Light,Heating',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Rent',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Office Expenses',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Internet',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    title: 'Profit & Expenses',
    monthly: 'Total Expenses',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
  {
    monthly: 'Total Profit',
    'november-12': '70034PKR',
    'october-12': '70034PKR',
    'september-12': '70034PKR',
    'august-12': '70034PKR',
    'july-12': '70034PKR',
    'june-12': '70034PKR',
  },
];

export const CashSummaryList = () => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const [result, setResult] = useState([]);
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });
      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const { data: cashSummary } = useQuery(
    [`report-agedreceivablereport-query=${query}`, query],
    getCashSummaryReport
  );

  useEffect(() => {
    if (cashSummary) {
      const { data: newResult } = cashSummary;
      setResult(newResult);
    }
  }, [cashSummary]);

  const onEmail = async () => {};

  return (
    <CashSummaryListWrapper>
      <FixedHeader
        src="/assets/cashSummary.png"
        title="Cash Summary"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(`/app${ISupportedRoutes.CASH_SUMMARY}?query=${query}`);
          }}
        />
      </FixedHeader>
      <table className="table mt-30">
        {data?.length > 0 ? (
          <>
            <thead>
              <tr className="columns">
                {columns.map((item) => {
                  return <th>{item?.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((element, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {element.title ? (
                    <tr className="title">
                      <td colSpan={12} className="title">
                        {element?.title}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr className="rows">
                    {columns.map((item, columnIndex) => (
                      <td key={columnIndex}>
                        {element[item?.key.toLocaleLowerCase()]}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </>
        ) : (
          <Card>
            <EmptyState
              text="Report File In Empty"
              content="Unraveling the Enigma of Empty Report Files."
              src="/assets/emptyReport.gif"
              width={200}
              height={200}
              alt="invoice"
            />
          </Card>
        )}
      </table>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </CashSummaryListWrapper>
  );
};

const CashSummaryListWrapper = styled.div`
  .buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
  }
  .table {
    width: 100% !important;
    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      .columns {
        th {
          padding: ${convertToRem(12)};
          font-size: ${convertToRem(14)};
          font-weight: 500 !important;
          letter-spacing: 0.13px;
          text-align: left;

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
    tbody {
      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyTextColor};
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          font-size: ${convertToRem(13)};
          font-weight: 400 !important;
          letter-spacing: 0.13px;
          padding: ${convertToRem(12)};
        }
      }
      .title {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        text-align: left;
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.listTableHeaderText};
        border: none !important;
        td {
          font-size: ${convertToRem(13)};
          font-weight: 500 !important;
          letter-spacing: 0.13px;
          padding: ${convertToRem(12)};
        }
      }
    }
  }
`;
