import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { BudgetManagerContainer } from './styled';
import { BudgetManagerList } from './List';

export const BudgetManager: FC = () => {
  return (
    <BudgetManagerContainer>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <BudgetManagerList />
        </Col>
      </Row>
    </BudgetManagerContainer>
  );
};
