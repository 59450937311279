export enum ReactQueryKeys {
  CONTACTS_KEYS = 'APP/CONTACT_KEYS',
  CONTACT_VIEW = 'APP/CONTACT_VIEW',
  ITEMS_KEYS = 'APP/ITEM_KEYS',
  ITEMS_VIEW = 'APP/ITEM_VIEW',
  PAYMENTS_KEYS = 'APP/PAYMENT_KEYS',
  ACCOUNTS_KEYS = 'APP/ACCOUNT_KEYS',
  ACCOUNT_VIEW = 'APP/ACCOUNT_VIEW',
  TRANSACTION_KEYS = 'APP/TRANSACTION_KEYS',
  BANK_KEYS = 'APP/BANK_KEY',
  INVOICES_KEYS = 'APP/INVOICE_KEY',
  INVOICE_VIEW = 'APP/INVOICE_VIEW',
  TRIBALANCE_KEYS = 'APP/TRIBALANCE_KEYS',
  CREDITNOTE_KEYS = 'APP/CREDITNOTE_KEYS',
  DEBITNOTE_KEYS = 'APP/DEBITNOTE_KEYS',
  QUOTE_KEY = 'APP/QUOTE_KEY',
  PURCHASEORDERS_KEY = 'APP/PURCHASEORDER_KEY',
  BILL_KEYS = 'APP/BILL_KEY',
}

export const QueryInvalidate = {
  invoices: [
    ReactQueryKeys?.INVOICES_KEYS,
    ReactQueryKeys?.TRANSACTION_KEYS,
    ReactQueryKeys?.ITEMS_KEYS,
    ReactQueryKeys?.INVOICE_VIEW,
    ReactQueryKeys.CONTACT_VIEW,
    'all-items',
  ],
};
