import React from 'react';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { DailyStalesDashboardAPI } from '../../api';
import { IDashboardSalesOverview } from '@invyce/shared/types';
import { DashboardMessages } from '../../components/SystemMessages/DashboardMessage';
import { IncomeView } from '../../components/IncomeOverview/index';
import BalanceDetails from '../../components/BalanceDetails';
import Reminder from '../../components/Reminder';
import InvoiceHistory from '../../components/InvoiceHistory';
import MyLineChart from '../../components/LineChart/LineChart';

const DashboardContainer = () => {
  const [dailySales, setDailySales] = useState<IDashboardSalesOverview>({
    expenseDifference: 0,
    id: 0,
    invoiceDifference: 0,
    invoicePercent: 0,
    prev_invoice: 0,
    prev_sale: 0,
    prevexpense: 0,
    purchasePercent: 0,
    saleDifference: 0,
    salePercent: 0,
    totalExpenses: 0,
    totalInvoiceSend: 0,
    totalSales: 0,
  });

  const [apiConfig, setApiConfig] = useState({
    start: dayjs().subtract(7, 'day'),
    end: dayjs(),
  });

  const { data: dailySalesData, isLoading: dailySalesLoading } = useQuery(
    [
      `daily-sales-report-start=${apiConfig.start}-end=${apiConfig.end}`,
      apiConfig.start,
      apiConfig.end,
    ],
    DailyStalesDashboardAPI
  );

  useEffect(() => {
    if (dailySalesData?.data?.result) {
      setDailySales(dailySalesData.data.result);
    }
  }, [dailySalesData]);
  return (
    <WrapperDashboardContainer>
      <Col span={24} offset={2}>
        <Row gutter={24}>
          <Col
            xxl={{ span: 16 }}
            xl={{ span: 14 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={'pb-24'}
          >
            <Row gutter={24}>
              <Col span={24} className={'pb-24'}>
                <DashboardMessages />
              </Col>

              {[1, 2, 3].map(() => {
                return (
                  <Col
                    className={'pb-24'}
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                  >
                    <IncomeView />
                  </Col>
                );
              })}
            </Row>
            <Row gutter={24}>
              <Col span={24} className={'pb-24'}>
                <MyLineChart />
              </Col>
              <Col span={24}>
                <InvoiceHistory />
              </Col>
            </Row>
          </Col>
          <Col
            xxl={{ span: 6 }}
            xl={{ span: 8 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row gutter={24}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                className={'pb-24'}
              >
                <BalanceDetails />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
                className={'pb-24'}
              >
                <IncomeView />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <Reminder />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </WrapperDashboardContainer>
  );
};
const WrapperDashboardContainer = styled.div`
  ._custom_col {
    margin: 7px 0;
    max-width: 100%;
  }

  @media only screen and (max-width: 991px) {
    .pt-24-max-sm {
      padding-top: 1.5rem;
    }
  }
  @media only screen and (max-width: 767px) {
    .pt-24-max-xs {
      padding-top: 1.5rem;
    }
  }
`;

export default DashboardContainer;
