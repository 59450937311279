import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { TribalanceReportWrapper } from './styled';
import { TribalanceReportList } from './List';

export const TribalanceReport: FC = () => {
  return (
    <TribalanceReportWrapper>
      <Row gutter={24}>
        <Col span={18} offset={3}>
          <TribalanceReportList />
        </Col>
      </Row>
    </TribalanceReportWrapper>
  );
};
