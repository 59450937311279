import { Card } from '../Card';
import convertToRem from '../../utils/convertToRem';
import { H2, P } from '../Typography';
import { IThemeProps } from '../../hooks/useTheme/themeColors';
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const BalanceDetails = () => {
  return (
    <WrapperWellcome>
      <Card className="card">
        <DecorativeCircle className="decorative-circle" />
        <DecorativeSquare className="decorative-square" />
        <div className="inner">
          <div>
            <H2 className="_heading">Your Balance</H2>
            <P className="m-reset">$12 356.27</P>
            <div className="_filter textRight"></div>
            <Select
              size="small"
              showSearch
              style={{ width: '50%', borderRadius: 'none', textAlign: 'left' }}
              placeholder="Select currency"
              optionFilterProp="children"
              defaultValue={'PKR Currency'}
            >
              <Option value="today">USD Currency</Option>
              <Option value="current_week">PKR Currency</Option>
            </Select>
          </div>
        </div>
      </Card>
    </WrapperWellcome>
  );
};

const WrapperWellcome = styled.div`
  margin: ${convertToRem(5)} 0;
  overflow: hidden;
  position: relative;
  .card {
    background:${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#0B1822' : '#3d3f65'};
    border-radius: 8px;
    box-shadow: 0px 4px 40px 0px rgba(61, 63, 101, 0.02);
    height: 206px !important;
    padding: 24px 32px 14px 32px !important;
  }

  .inner {
    h2 {
       color: ${(props: IThemeProps) =>
         props.theme?.theme === 'dark' ? '#ffffff99' : '#ffffff'}; 
      font-size: 16px;
      margin-top: 3px;
    }
    p {
       color: ${(props: IThemeProps) =>
         props.theme?.theme === 'dark' ? '#ffffff99' : '#ffffff'}; 
      padding: 29px 0px 29px 0px;
      font-size: 28px;
    }
    .ant-select-arrow {
       color: ${(props: IThemeProps) =>
         props.theme?.theme === 'dark' ? '#ffffff99' : '#ffffff'}; !important;
      margin-left: auto !important;
      svg {
        fill: ${(props: IThemeProps) =>
          props.theme?.theme === 'dark' ? '#ffffff99' : '#ffffff'} !important;
      }
    }
    .ant-select-selector {
      background: transparent !important;
       color: ${(props: IThemeProps) =>
         props.theme?.theme === 'dark' ? '#ffffff99' : '#ffffff'}; !important;
      background: white;
      border: none !important;
    }
  }
`;
const DecorativeCircle = styled.div`
  position: absolute;
  border-bottom-left-radius: 8px !important;
  bottom: -55px;
  right: -28px;
  width: 120px;
  height: 120px;
  background-color: #00c48c;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeSquare = styled.div`
  position: absolute;
  bottom: 65px;
  right: -10px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: rgba(121, 136, 255, 0.6);
  z-index: 1;
  transform: translate(25px, 25px);
`;

export default BalanceDetails;
