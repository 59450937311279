import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import bxPlus from '@iconify-icons/bx/bx-plus';
import { BanksIcon, ButtonTag, Heading } from '@components';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { BanksList } from './BanksList';
import { Rbac } from '../../../components/Rbac';
import { PERMISSIONS } from '../../../components/Rbac/permissions';
export const BankAccounts = () => {
  const { setBanksModalConfig } = useGlobalContext();
  return (
    <WrapperBankAccounts>
      <div className="flex alignCenter justifySpaceBetween pt-5">
        <div className="flex alignCenter pt-10">
          <i>
            <BanksIcon />
          </i>
          <Heading type="table">Bank Accounts</Heading>
        </div>
      </div>
      <BanksList />
    </WrapperBankAccounts>
  );
};

const WrapperBankAccounts = styled.div``;
