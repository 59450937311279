import { FilterType } from '@invyce/shared/types';

export default {
  dateIn: {
    type: FilterType.DATE_IN,
    label: 'Filter Adjusted Aged Receivable',
    value: '',
  },
  ageingIn: {
    type: FilterType.LIST_IDS,
    label: 'Ageing In',
    value: [
      { value: 1, name: 'Due Date' },
      { value: 2, name: 'Invoice Date' },
    ],
  },
};
