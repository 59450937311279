import Icon from '@iconify/react';
import { IThemeProps } from '@invyce/shared/invyce-theme';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FC, ReactNode, Ref } from 'react';
import styled from 'styled-components';
import convertToRem from '../../utils/convertToRem';

interface IProps {
  size: SizeType;
  title: string;
  icon?: any;
  onClick?: (payload?: any) => void;
  ref?: Ref<any>;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
  ghost?: boolean;
  loading?: boolean;
  customizeIcon?: ReactNode;
  style?: any;
  htmlType?: any;
}

export const ButtonTag: FC<IProps> = (
  {
    disabled,
    className,
    size,
    ref,
    onClick,
    icon,
    title,
    type = 'default',
    ghost,
    loading,
    customizeIcon,
    style,
    htmlType = 'button',
  },
  rest
) => {
  return (
    <WrapperButtonTag size={size} title={title}>
      <Button
        loading={loading}
        type={type}
        ghost={ghost}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        className={`flex alignCenter justifyCenter ${className} ${
          !disabled ? `${type}` : 'disabled'
        }`}
        style={style}
        size={size}
        htmlType={htmlType}
        {...rest}
      >
        {customizeIcon && <div className="mr-6">{customizeIcon}</div>}
        {icon && (
          <i className="flex alignCenter _icon_button">
            <Icon icon={icon} />
          </i>
        )}
        {title}
      </Button>
    </WrapperButtonTag>
  );
};

const WrapperButtonTag = styled.div<IProps>`
  button {
    margin-right: 7px;
    border-radius: 4px;
    width: ${(props) => (props.size === 'large' ? '100%' : 'auto')};
    border: none;
    padding: ${convertToRem(20)} ${convertToRem(14)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
    color: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagColor};
    span {
      color: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagColor};
    }

    &:hover {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.$Secondary} !important;
      color: #ffffff !important;
      ._icon_button {
        color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
      }
      span {
        color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
      }
    }
    ._icon_button {
      position: relative;
      top: 0px;
      margin-right: 5px;
      font-size: 14px;
    }
  }

  .default {
    background: ${(props: IThemeProps) => props?.theme?.colors?.td};
    padding: ${(props) =>
      props.size === 'large'
        ? `${convertToRem(21)} ${convertToRem(16)} !important`
        : `${convertToRem(18)} ${convertToRem(16)}`};
    color: ${(props: IThemeProps) =>
      props?.theme?.colors?.sidebarListActiveText}!important;
    span {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.sidebarListActiveText} !important;
    }

    &:hover {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.td} !important;
      ._icon_button {
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.sidebarListActiveText} !important;
      }
      span {
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.sidebarListActiveText} !important;
      }
    }
  }

  .ghost {
    &:hover {
      background: #7988ff !important;
      color: #ffffff !important;
      ._icon_button {
        color: #ffffff !important;
      }
      span {
        color: #ffffff !important;
      }
    }
    &:focus {
      background: #7988ff !important;
      color: #ffffff !important;
      span {
        color: #ffffff !important;
      }
    }
  }

  .primary {
    background: #7988ff;
    color: #ffffff !important;
    padding: ${(props) =>
      props.size === 'large'
        ? `${convertToRem(21)} ${convertToRem(16)} !important`
        : `${convertToRem(18)} ${convertToRem(16)}`};
    span {
      color: #ffffff !important;
    }
    &:hover {
      background: #7988ff !important;
      color: #ffffff !important;
      ._icon_button {
        color: #ffffff !important;
      }
      span {
        color: #ffffff !important;
      }
    }
    &:focus {
      background: #7988ff !important;
      color: #ffffff !important;
    }
  }

  .link {
    color: #7988ff !important;
    span {
      color: #7988ff !important;
    }
    &:hover {
      color: #7988ff !important;
      ._icon_button {
        color: #7988ff !important;
      }
      span {
        color: #7988ff !important;
      }
    }
    &:focus {
      background: #7988ff !important;
      color: #ffffff !important;
    }
  }

  .disabled {
    background: ${(props: IThemeProps) =>
      props?.theme?.colors?.buttonTagBg} !important;
    color: ${(props: IThemeProps) =>
      props?.theme?.colors?.buttonTagColor} !important;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.buttonTagBg} !important;
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.buttonTagColor} !important;
      cursor: not-allowed;

      ._icon_button {
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.buttonTagColor} !important;
      }
      span {
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.buttonTagColor} !important;
      }
    }
  }
`;
