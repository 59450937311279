import { PDFDownloadLink } from '@react-pdf/renderer';
import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import convertToRem from '../../utils/convertToRem';
import { PDFICON } from '../Icons';
import { IThemeProps } from '../../hooks/useTheme/themeColors';

interface IProps {
  document: ReactElement<any>;
}
export const PDFDownloader: FC<IProps> = ({ document }) => {
  return (
    <PDFDownloadLinkWrapper document={<>doc</>}>
      <div className="flex alignCenter justifySpaceBetween">
        <PDFICON className="flex alignCenter mr-5" />

        <span> Download PDF</span>
      </div>
    </PDFDownloadLinkWrapper>
  );
};

export default PDFDownloadLink;
const PDFDownloadLinkWrapper = styled(PDFDownloadLink)`
  background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
  color: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagColor};
  padding: 5px 5px;
  border-radius: 2px;
  margin-right: 8px;
  border: none;
  outline: none;
  transition: 0.4s all ease-in-out;
  &:hover {
    background: ${(props: IThemeProps) => props?.theme?.colors?.$PRIMARY};
    color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
  }
`;
