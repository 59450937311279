import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Upload, message } from 'antd';
import { NodeBaseURL } from '../../utils/http';
import { NOTIFICATIONTYPE } from '@invyce/shared/types';
import { useGlobalContext } from '../../hooks/globalContext/globalContext';
import { IThemeProps } from '@invyce/shared/invyce-theme';
import { UploadIcons } from '../../assets/icons/index';

const { Dragger } = Upload;

interface FileDropzoneProps {
  onUploadSuccess?: (id: string | null) => void;
  onRemoveFile?: (id: string) => void;
  invoiceFormData?: any;
}

const StyledDragger = styled(Dragger)`
  width: 60% !important;
  .file_drop_zone {
    span {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
  .ant-upload-drag {
    border-radius: 8px;
  }
  .ant-upload-text {
    color: #7988ff !important;
  }
`;

const FileDropzone: FC<FileDropzoneProps> = ({
  onUploadSuccess,
  onRemoveFile,
  invoiceFormData,
}) => {
  const { notificationCallback } = useGlobalContext();
  const [fileList, setFileList] = useState(invoiceFormData);

  const onChange = ({ file, fileList: newFileList }) => {
    setFileList(newFileList);
    if (file?.response?.attachment?._id) {
      if (file?.status === 'done') {
        onUploadSuccess && onUploadSuccess(file);
      } else if (status === 'error') {
        console.error('failed upload attachment');
      }
    } else {
      console.error('error upload attachment');
    }
  };

  const handleRemove = (file) => {
    const removedId = file?.response?.attachment?._id;
    const newFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(newFileList);
    if (onRemoveFile && removedId) {
      onRemoveFile(removedId);
    }
  };

  return (
    <StyledDragger
      name="file"
      multiple={false}
      fileList={fileList}
      listType="picture"
      method="POST"
      onChange={onChange}
      withCredentials={true}
      onRemove={(file) => handleRemove(file)}
      action={`${NodeBaseURL}/attachments/attachment`}
    >
      <p className="ant-upload-drag-icon">
        <UploadIcons />
      </p>
      <p className="ant-upload-text">Browse File to Upload</p>
    </StyledDragger>
  );
};

export default FileDropzone;
