import React from 'react';
import { Col, Row } from 'antd';
import { AgedPayableSummaryWrapper } from './styled';
import { AgedPayableSummaryList } from './List';

export const AgedPayableSummaryReport = () => {
  return (
    <AgedPayableSummaryWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <AgedPayableSummaryList />
        </Col>
      </Row>
    </AgedPayableSummaryWrapper>
  );
};
