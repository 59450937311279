import { QueryKey } from '../modal';
import http from '../utils/http';



export const TrialbalanceAPI = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `accounts/reports/trial-balance`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http.get(URL);
};
export const BalanceSheetAPI = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `accounts/reports/balance-sheet`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http.get(URL);
};

export const IncomeStatementAPI = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `accounts/reports/income-statement`;

  if (query) {
    URL = `${URL}?query=${query}`;
  }

  return http.get(URL);
};

export const CashActivityStatementAPI = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/cash-flow-report`;

  if (query) {
    URL = `${URL}?query=${query}`;
  }

  return http.get(URL);
};


export const getTribalanceReports = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/account/trail-balance`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getBalanceSheetReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/account/balance-sheet-v2`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getIncomeStatementReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/account/income-statement`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getAgedPayableReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/invoice/aged-payable-detail`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getAgedPayableSummaryReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/invoice/aged-payable-summary`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};


export const getAgedReceivableDetailsReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/invoice/aged-receivable-detail`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getAgedReceivableSummaryReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/invoice/aged-receivable-summary`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getCashSummaryReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/account/cash-summary`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getInventoryManagement = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/item/inventory-management`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getCustomerOutstandingBalance = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/contact/customer-outstanding-balance`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getSupplierOutstandingBalance = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `reports/contact/supplier-outstanding-balance`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const TrialbalanceKeysAPI = () => http.get(`accounts/reports/import-csv`)