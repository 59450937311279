import styled from 'styled-components';
import convertToRem from '../../utils/convertToRem';
import { IThemeProps } from '../../hooks/useTheme/themeColors';

export const BreadCrumbArea = styled.div`
  padding: ${convertToRem(12)} ${convertToRem(15)};
  margin-bottom: ${convertToRem(15)};
  border-radius: 4px;
  background: ${(props: IThemeProps) =>
    props?.theme?.colors?.updateCardPrimaryColor};
  display: flex;
  align-items: center;

  .ant-breadcrumb {
    display: flex;
    align-items: center;

    .ant-breadcrumb-link {
      color: ${(props: IThemeProps) =>
        props.theme?.theme === 'dark' ? '#FFF' : '#7988FF'} !important;

      a {
        color: ${(props: IThemeProps) =>
          props.theme?.theme === 'dark' ? '#FFF' : '#194479'} !important;
      }
    }
  }
  .bredcrumIcon {
    svg path {
      stroke: ${(props: IThemeProps) =>
        props.theme?.theme === 'dark' ? '#FFF' : '#194479'} !important;
    }
    font-size: 20px;
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#FFF' : '#194479'} !important;
  }
`;
