import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { CustomerOutstandingBalanceList } from './List';
import { CustomerOutstandingContainer } from './styled';

export const CustomerOutstandingBalance: FC = () => {
  return (
    <CustomerOutstandingContainer>
      <Row gutter={2}>
        <Col span={24} offset={0}>
          <CustomerOutstandingBalanceList />
        </Col>
      </Row>
    </CustomerOutstandingContainer>
  );
};
