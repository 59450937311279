import React, { FC, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { useQuery } from 'react-query';
import { getSupplierOutstandingBalance } from '@api';
import { Card, Space } from 'antd';
import baselineAlternateEmail from '@iconify-icons/ic/baseline-alternate-email';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

interface IProps {
  props?: any;
}

export const SupplierOutstandingContainerBalanceList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;
  const [result, setResult] = useState<any>([]);
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const {
    data: supplierOutstandingBalance,
    isLoading,
    isFetched,
  } = useQuery(
    [`report-supplieroutstandingbalance-query=${query}`, query],
    getSupplierOutstandingBalance
  );

  useEffect(() => {
    if (supplierOutstandingBalance) {
      const { data: newResult } = supplierOutstandingBalance as any;
      setResult(newResult);
      console.log(newResult, 'newResultnewResult');
    }
  }, [supplierOutstandingBalance]);

  function formatAsDate(dateString) {
    const dateObject = new Date(dateString);
    return `${dateObject.getDate()}-${dateObject.toLocaleString('default', {
      month: 'short',
    })}-${dateObject.getFullYear()}`;
  }

  const columns = [
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Bill No',
      dataIndex: 'invoicenumber',
      key: 'invoicenumber',
    },
    {
      title: 'Due Date',
      dataIndex: 'duedate',
      key: 'duedate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Amount_Paid',
      dataIndex: 'amountreceived',
      key: 'amountreceived',
    },
    {
      title: 'Outstanding_Amount',
      dataIndex: 'outstandingamount',
      key: 'outstandingamount',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const onEmail = async () => {};
  return (
    <WrapperCustomerOutstandingBalance>
      <FixedHeader
        src="/assets/supplier-outstanding.png"
        title="Supplier outstanding balance"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.SUPPLIER_OUTSTANDING}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            <table className="table">
              {result?.length > 0 ? (
                <>
                  <thead>
                    <tr className="columns">
                      {columns.map((item) => {
                        return <th>{item?.title}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((supplierItem, index) => {
                      return (
                        <React.Fragment key={index}>
                          {supplierItem?.subTotal ? (
                            <tr className="totalRows">
                              {columns.map((item) => {
                                return <td>{supplierItem[item.key]}</td>;
                              })}
                            </tr>
                          ) : !supplierItem?.total ? (
                            <tr className="rows">
                              {columns.map((item) => {
                                // Check if the key includes the word 'date' and format accordingly
                                const formattedValue =
                                  item.key.toLowerCase().includes('date') &&
                                  supplierItem[item.key]
                                    ? formatAsDate(supplierItem[item.key])
                                    : supplierItem[item.key] || '-';

                                return <td>{formattedValue}</td>;
                              })}
                            </tr>
                          ) : (
                            <tr className="subTotalrows">
                              {columns.map((item) => {
                                // Check if the key includes the word 'date' and format accordingly
                                const formattedValue =
                                  item.key.toLowerCase().includes('date') &&
                                  supplierItem[item.key]
                                    ? formatAsDate(supplierItem[item.key])
                                    : supplierItem[item.key];

                                return <td>{formattedValue}</td>;
                              })}
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <Card>
                  <EmptyState
                    text="Report File In Empty"
                    content="Unraveling the Enigma of Empty Report Files."
                    src="/assets/emptyReport.gif"
                    width={200}
                    height={200}
                    alt="invoice"
                  />
                </Card>
              )}
            </table>
          </>
        </PrintFormat>
      </div>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperCustomerOutstandingBalance>
  );
};

const WrapperCustomerOutstandingBalance = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;

    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};

      .columns {
        th {
          padding: ${convertToRem(12)};
          font-weight: 500;
          font-size: ${convertToRem(14)};

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }

    tbody {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};

      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        font-size: ${convertToRem(13)};
        font-weight: 400;
        letter-spacing: 0.13px;
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(12)};
        }
      }

      .totalRows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        text-align: left;
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        font-weight: 500;
        td {
          border-left: ${convertToRem(1)} solid
            ${(props: IThemeProps) => props?.theme?.colors?.tableBodyColor};
          padding: ${convertToRem(12)};
        }
      }
      .subTotalrows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        font-weight: 500;
        font-size: ${convertToRem(13)};
        td {
          padding: ${convertToRem(12)};
        }
      }
    }
  }
`;
