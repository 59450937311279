import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { useQuery } from 'react-query';
import { getAgedPayableReport } from '../../../api';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import { Card, Space } from 'antd';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

const columns = [
  {
    title: "Customer's",
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
  },
  {
    title: '1 30 Days',
    dataIndex: '1-30_days',
    key: '1-30_days',
  },
  {
    title: '31 60 Days',
    dataIndex: '31-60_days',
    key: '31-60_days',
  },
  {
    title: '61 90 Days',
    dataIndex: '61-90_days',
    key: '61-90_days',
  },
  {
    title: '91 120 Days',
    dataIndex: '91-120_day',
    key: '91-120_day',
  },
  {
    title: '>121 Days',
    dataIndex: 'over_120_days',
    key: 'over_120_days',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

export const AgedPayableList = () => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const [result, setResult] = useState([]);
  const printRef = useRef();
  const { routeHistory, userDetails } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });
      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const { data: agedReceivableData } = useQuery(
    [`report-agedreceivablereport-query=${query}`, query],
    getAgedPayableReport
  );

  useEffect(() => {
    if (agedReceivableData) {
      const { data: newResult } = agedReceivableData;
      setResult(newResult);
    }
  }, [agedReceivableData]);

  function calculateTotals(data: any) {
    return data?.reduce(
      (acc, curr) => {
        acc.customer_name += 1;
        acc['current'] += curr['current'] || 0;
        acc['1-30_days'] += curr['1-30_days'] || 0;
        acc['31-60_days'] += curr['31-60_days'] || 0;
        acc['61-90_days'] += curr['61-90_days'] || 0;
        acc['91-120_days'] += curr['91-120_days'] || 0;
        acc['>121_days'] += curr['>121_days'] || 0;
        acc.total += curr.total || 0;
        return acc;
      },
      {
        customer_name: 0,
        current: 0,
        '1-30_days': 0,
        '31-60_days': 0,
        '61-90_days': 0,
        '91-120_days': 0,
        '>121_days': 0,
        total: 0,
      }
    );
  }

  const reduceRes = calculateTotals(result);

  const onEmail = async () => {};

  return (
    <WrapperAgedReciavable>
      <FixedHeader
        src="/assets/agedDetails.png"
        title="Aged Payable Report"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.BALANCE_SHEET_REPORT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            <table className="table">
              {result?.length > 0 ? (
                <>
                  <thead>
                    <tr className="columns">
                      {columns.map((itemHeader, index) => {
                        return <th key={index}>{itemHeader?.title}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((agedRecievable, index) => {
                      return (
                        <tr className="rows" key={index}>
                          {columns.map((item) => {
                            return (
                              <td>
                                {agedRecievable[
                                  item?.key.toLocaleLowerCase()
                                ] || '-'}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                    <tr className="totalRows">
                      {columns.map((item) => {
                        return (
                          <td>
                            {reduceRes && item?.key === 'customer_name'
                              ? `${
                                  reduceRes?.[
                                    item?.key.toLocaleLowerCase() || 0
                                  ]
                                } Customer’s `
                              : reduceRes?.[item?.key.toLocaleLowerCase()] || 0}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </>
              ) : (
                <Card>
                  <EmptyState
                    text="Report File In Empty"
                    content="Unraveling the Enigma of Empty Report Files."
                    src="/assets/emptyReport.gif"
                    width={200}
                    height={200}
                    alt="invoice"
                  />
                </Card>
              )}
            </table>
          </>
        </PrintFormat>
      </div>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperAgedReciavable>
  );
};
const WrapperAgedReciavable = styled.div`
  .buttons {
    display: flex;
    justifycontent: end;
    gap: 10px;
    marginbottom: 20px;
  }

  .table {
    width: 100%;
    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      .columns {
        th {
          padding: ${convertToRem(12)};
          font-size: ${convertToRem(14)};
          font-weight: 500 !important;
          text-align: left;
          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
    tbody {
      font-size: ${convertToRem(13)};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};
      fontweight: 400;

      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(16)};
        }
      }
      .totalRows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.listTableHeaderText};
        text-align: left;
        font-weight: 500;
        td {
          padding: ${convertToRem(16)};
        }
      }
    }
  }
`;
