import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React, { FC, ReactNode, useRef } from 'react';

interface IDocumentProps {
  children: ReactNode;
  generatedUser?: string;
  ref?: any;
}

const styles = StyleSheet.create({
  page: {
    paddingBottom: 65,
  },

  tableWrapper: {
    padding: '20px',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '0px 20px',
  },
  textPoweredBy: {
    color: '#575757',
    fontSize: '10px',
  },
});

export const PdfDocument: FC<IDocumentProps> = ({
  children,
  generatedUser,
  ref,
}) => {
  return (
    <Document ref={ref}>
      <Page size={'A4'} style={styles.page}>
        {children}
        <View fixed style={styles.pageNumbers}>
          <Text>Reported generated on: {dayjs().format(`MMMM D, YYYY`)}</Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          <Text>
            Report generated by:{' '}
            <Text style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
              {generatedUser}
            </Text>
          </Text>
        </View>
      </Page>
    </Document>
  );
};
