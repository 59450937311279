import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { ManagementReportList } from './List';
import { ManagementReportWrapper } from './styled';

export const ManagementReport: FC = () => {
  return (
    <ManagementReportWrapper>
      <Row gutter={24}>
        <Col span={20} offset={2}>
          <ManagementReportList />
        </Col>
      </Row>
    </ManagementReportWrapper>
  );
};
