import { ITheme, IThemeProps } from '@invyce/shared/invyce-theme';
import { useState } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';
import TimeButton from '../TimeButtons';
import { Seprator } from '../Seprator';
import EChart from '../BarChart/Chart';
import { ChartType } from '../../modal/items';
import convertToRem from '../../utils/convertToRem';

const MyLineChart = () => {
  const [activeButton, setActiveButton] = useState<string>(null);

  const handleButtonClick = (value: string) => {
    setActiveButton(value);
  };

  const timeValues: string[] = [
    '48 hours',
    '1 Week',
    '1 Month',
    '6 Months',
    '1 Year',
  ];

  const chartData = {
    xAxisData: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    seriesData: [300, 600, 900, 1200, 1500, 1800, 2100],
  };

  return (
    <WrapperLine>
      <div className="container_button">
        {timeValues.map((value) => (
          <TimeButton
            key={value}
            value={value}
            isActive={activeButton === value}
            onClick={handleButtonClick}
          />
        ))}
      </div>
      <Seprator />
      <EChart data={chartData} height="300px" />
    </WrapperLine>
  );
};

const WrapperLine = styled.div`
  background: ${(props: IThemeProps) =>
    props?.theme?.colors?.sidebarBg} !important;
  padding: ${convertToRem(10)} ${convertToRem(24)};
  border-radius: 9px;
  .container_button {
    margin-bottom: 10px;
  }
`;

export default MyLineChart;
