import { Col, Row } from 'antd';
import styled from 'styled-components';
import { EditorWidget } from './EditorWidget';

export const AddNewAccount = () => {
  return (
    <AddNewAccountWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <EditorWidget />
        </Col>
      </Row>
    </AddNewAccountWrapper>
  );
};

const AddNewAccountWrapper = styled.div``;
