import React, { FC, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { Card, Space } from 'antd';
import baselineAlternateEmail from '@iconify-icons/ic/baseline-alternate-email';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

interface IProps {
  props?: any;
}

export const GeneralLedgerSummaryList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const columns = ['Account', 'Debit', 'Credit', 'Balance'];

  const GenralSummary = [
    {
      account: 'Accounts receivable',
      debit: 52500,
      credit: 19500,
      balance: '33000 Dr',
    },
    {
      account: 'Accounts payable',
      debit: 41000,
      credit: 85000,
      balance: '44000 Cr',
    },
    {
      account: 'Capital one',
      debit: '',
      credit: 300000,
      balance: '300000 Cr',
    },
    {
      account: 'Cash',
      debit: 300000,
      credit: '',
      balance: '300000 Dr',
    },
    {
      account: 'HBL-Saving account',
      debit: 915000,
      credit: 650000,
      balance: '265000 Dr',
    },
    {
      account: 'Computer table-Inventory',
      debit: 40000,
      credit: '',
      balance: '40000 Dr',
    },
    {
      account: 'Gain on sale of asset',
      debit: '',
      credit: 200000,
      balance: '200000 Cr',
    },
    {
      account: 'Land and building',
      debit: 500000,
      credit: '',
      balance: '500000 D',
    },
    {
      account: 'Share capital',
      debit: '',
      credit: 100000,
      balance: '100000 Cr',
    },
    {
      account: 'Plant and machinery',
      debit: 300000,
      credit: '',
      balance: '300000 Dr',
    },
    {
      account: 'Refrigerator-Inventory',
      debit: 60000,
      credit: '',
      balance: '600000 Dr',
    },
    {
      account: 'Sales',
      debit: '',
      credit: 915000,
      balance: '915000 Cr',
    },
  ];
  const removeUnderscores = (str: any) => {
    return str.replace(/_/g, ' ');
  };

  const onEmail = async () => {};

  return (
    <WrapperGeneralLedgerSummary>
      <FixedHeader
        src="/assets/ledger.png"
        title="General Ledger Summar"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.GENERAL_LEDGER_SUMMARY}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            <table className="table">
              {GenralSummary?.length > 0 ? (
                <>
                  <thead>
                    <tr className="columns">
                      {columns.map((item) => {
                        return <th>{removeUnderscores(item)}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {GenralSummary?.map((generalLedgerSummary, index) => {
                      return (
                        <tr className="rows" key={index}>
                          {columns.map((item) => {
                            return (
                              <td>
                                {generalLedgerSummary[
                                  item.toLocaleLowerCase()
                                ] || '-'}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <Card>
                  <EmptyState
                    text="Report File In Empty"
                    content="Unraveling the Enigma of Empty Report Files."
                    src="/assets/emptyReport.gif"
                    width={200}
                    height={200}
                    alt="invoice"
                  />
                </Card>
              )}
            </table>
          </>
        </PrintFormat>
      </div>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperGeneralLedgerSummary>
  );
};

const WrapperGeneralLedgerSummary = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;

    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      text-align: left;

      .columns {
        th {
          padding: ${convertToRem(12)};
          font-weight: 500;
          font-size: ${convertToRem(14)};

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }

    tbody {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};

      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        font-size: ${convertToRem(13)};
        font-weight: 400;
        letter-spacing: 0.13px;
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(12)};
        }
      }
    }
  }
`;