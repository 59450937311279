import { FC } from 'react';
import { View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PdfDefaultHeaderProps } from '../../modal';

const styles = StyleSheet.create({
  companyContiner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
  },
  companyName: {
    fontSize: 30,
    color: '#212B3B',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    width: 56,
    height: 56,
    marginTop: '-84px',
    objectFit: 'contain',
    marginRight: '10px',
  },
  invoiceTitle: {
    fontSize: '20px',
    marginLeft: '-42px',
    fontWeight: 600,
    color: '#7988FF',
    marginBottom: '20px',
  },
  detailColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    lineHeight: 1,
  },
  detailText: {
    fontSize: 11,
    marginBottom: 4,
    color: '#666565',
    fontWeight: 'bold',
  },
  detailLabel: {
    fontSize: 11,
    fontWeight: 700,
    color: '#7988FF',
    marginTop: '2px',
  },
  hr: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
    marginTop: 10,
  },
});

export const PdfDefaultHeader: FC<PdfDefaultHeaderProps> = ({
  title,
  organizationName,
  organizationEmail,
  organizationContact,
  address,
  city,
  country,
  logo,
  listData = true,
  invoiceNumber,
  reference,
  invoiceDate,
}) => {
  return (
    <>
      <View style={styles.headerContainer}>
        <View style={styles.companyContiner}>
          <Image style={styles.logo} src={logo} />
          <View>
            <Text style={styles.companyName}>{organizationName}</Text>
            <Text style={[styles.detailText, styles.detailLabel]}>Email:</Text>
            <Text style={styles.detailText}>{organizationEmail}</Text>
            <Text style={[styles.detailText, styles.detailLabel]}>
              Contact No:
            </Text>
            <Text style={styles.detailText}>{organizationContact}</Text>
            <Text style={[styles.detailText, styles.detailLabel]}>
              Address:
            </Text>
            <Text style={styles.detailText}>
              {address || `${city && city} ${country ? country : ''}`}
            </Text>
          </View>
        </View>
        <View style={styles.detailColumn}>
          <Text style={styles.invoiceTitle}>{title}</Text>
          {!listData && (
            <>
              <Text style={[styles.detailText, styles.detailLabel]}>
                Order Number#
              </Text>
              <Text style={styles.detailText}>{invoiceNumber}</Text>
              <Text style={[styles.detailText, styles.detailLabel]}>
                Invoice Date
              </Text>
              <Text style={styles.detailText}>{invoiceDate}</Text>
              <Text style={[styles.detailText, styles.detailLabel]}>
                Reference #
              </Text>
              <Text style={styles.detailText}>{reference || '-'}</Text>
            </>
          )}
        </View>
      </View>
      <View style={styles.hr} />
    </>
  );
};
