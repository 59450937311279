import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { Card } from 'antd';
import { useQuery } from 'react-query';
import { getAgedReceivableSummaryReport } from '../../../api';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import { Space } from 'antd';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

const columns = [
  {
    title: 'contact',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
  },
  {
    title: '1-30 Days',
    dataIndex: '1-30_days',
    key: '1-30_days',
  },
  {
    title: '31-60 Days',
    dataIndex: '31-60_days',
    key: '31-60_days',
  },
  {
    title: 'Older',
    dataIndex: 'older',
    key: 'older',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

export const AgedReceivableSummaryList = () => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const [result, setResult] = useState([]);
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });
      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const { data: agedReceivableData } = useQuery(
    [`report-agedreceivablereport-query=${query}`, query],
    getAgedReceivableSummaryReport
  );

  useEffect(() => {
    if (agedReceivableData) {
      const { data: newResult } = agedReceivableData;
      let contactCount = 0;
      const calculateTotals = (data) => {
        return data.reduce(
          (acc, curr) => {
            if (curr.contact) {
              contactCount += 1;
            }
            acc.contact = `${contactCount} Contact's`;
            acc.Current += curr.Current || 0;
            acc['1-30_Days'] += curr['1-30_Days'] || 0;
            acc['31-60_Days'] += curr['31-60_Days'] || 0;
            acc['61-90_Days'] += curr['61-90_Days'] || 0;
            acc.Older += curr.older || 0;
            acc.Total += curr.Total || 0;
            return acc;
          },
          {
            contact: 0,
            Current: 0,
            '1-30_Days': 0,
            '31-60_Days': 0,
            '61-90_Days': 0,
            Older: 0,
            Total: 0,
          }
        );
      };
      const resultWithSubtotal = [
        ...newResult,
        { subtotal: true, ...calculateTotals(newResult) },
      ];
      setResult(resultWithSubtotal);
    }
  }, [agedReceivableData, query, history]);

  const onEmail = async () => {};

  return (
    <WrapperAgedReceivable>
      <FixedHeader
        src="/assets/agedPayable.png"
        title="Aged Receivable Summary"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.BALANCE_SHEET_REPORT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            <table className="table">
              {result?.length > 0 ? (
                <>
                  <thead>
                    <tr className="columns">
                      {columns.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className={
                              index === 0 ? 'contact_field' : 'rest_field'
                            }
                          >
                            {item?.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((agedRecieivable, index) => {
                      return (
                        <React.Fragment key={index}>
                          {agedRecieivable?.subtotal ? (
                            <tr className="totalRows">
                              {columns.map((item, columnIndex) => {
                                console.log(agedRecieivable, 'asdasdad');
                                return (
                                  <td
                                    key={columnIndex}
                                    className={
                                      columnIndex === 0
                                        ? 'contact_field'
                                        : 'rest_field'
                                    }
                                  >
                                    {agedRecieivable[item?.title] || '0'}
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            <tr className="rows">
                              {columns.map((item, columnIndex) => {
                                return (
                                  <td
                                    key={columnIndex}
                                    className={
                                      columnIndex === 0
                                        ? 'contact_field'
                                        : 'rest_field'
                                    }
                                  >
                                    {agedRecieivable[item?.title] || '-'}
                                  </td>
                                );
                              })}
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <Card>
                  <EmptyState
                    text="Report File In Empty"
                    content="Unraveling the Enigma of Empty Report Files."
                    src="/assets/emptyReport.gif"
                    width={200}
                    height={200}
                    alt="invoice"
                  />
                </Card>
              )}
            </table>
          </>
        </PrintFormat>
      </div>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperAgedReceivable>
  );
};

export const WrapperAgedReceivable = styled.div`
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;

    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};

      .columns {
        th {
          padding: ${convertToRem(12)};
          font-weight: 500;
          font-size: ${convertToRem(14)};

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }

        .rest_field {
          text-align: center;
          width: 15%;
        }

        .contact_field {
          text-align: left;
          width: 30%;
        }
      }
    }

    tbody {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};

      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        font-size: ${convertToRem(13)};
        font-weight: 400;
        letter-spacing: 0.13px;

        td {
          padding: ${convertToRem(12)};
        }

        & td:not(:first-child) {
          padding: ${convertToRem(12)};
        }

        .rest_field {
          text-align: center;
          width: 15%;
        }

        .contact_field {
          text-align: left;
          width: 30%;
        }
      }

      .rows:not(:first-child) {
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
      }

      .totalRows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        text-align: left;

        font-weight: 500;
        td {
          border-left: ${convertToRem(1)} solid
            ${(props: IThemeProps) => props?.theme?.colors?.reportTableHeader};
          padding: ${convertToRem(12)};
        }

        .rest_field {
          text-align: center;
          width: 15%;
        }

        .contact_field {
          text-align: left;
          width: 30%;
        }
      }
    }
  }
`;
