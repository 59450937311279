/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import bxPlus from '@iconify-icons/bx/bx-plus';
import { Heading } from '../../../components/Heading';
import { TableTabs, TableTabsContent } from '../../../components/TableTabs';
import { ISupportedRoutes } from '../../../modal';
import { ContactMainWrapper } from './styles';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { FallBackLoader } from '../../../components/FallBackLoader';
import { Rbac } from '../../../components/Rbac/index';
import { PERMISSIONS } from '../../../components/Rbac/permissions';
import Customers from './Customers';
import Suppliers from './Suppliers';
import { ButtonTag, CreateInvoiceIcon } from '@components';
import ContactSvg from '../../../assets/contactSvg.svg';

export const ContactList: FC = () => {
  /* DYNAMIC IMPORTS */

  // const Customers = lazy(() => import('./Customers'));
  // const Suppliers = lazy(() => import('./Suppliers'));

  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;
  const [activeTab, setActiveTab] = useState('');
  const { search } = history.location;
  useEffect(() => {
    if (!activeTab) {
      setActiveTab('customers');
    }
  }, []);

  useEffect(() => {
    if (search) {
      const filterTab = search.split('?')[1].split('&')[0].split('=')[1];
      if (filterTab !== null && filterTab !== 'id') {
        if (activeTab !== filterTab) {
          setActiveTab(filterTab);
        }
      }
    }
  }, [search]);

  const RenderButton = () => {
    return (
      <Rbac permission={PERMISSIONS.CONTACTS_CREATE}>
        <ButtonTag
          className="ml-10"
          type="primary"
          size={'large'}
          title="Add Contact"
          onClick={() =>
            history.push({
              pathname: `/app${ISupportedRoutes.CREATE_CONTACT}`,
              state: activeTab,
            })
          }
          icon={bxPlus}
        />
      </Rbac>
    );
  };

  /* JSX */
  return (
    <ContactMainWrapper>
      <div className="headingWrapper flex alignCenter">
        <i>
          <img src={ContactSvg} alt="contact svg" />
        </i>
        <Heading type="table">Contacts</Heading>
      </div>
      <Heading type="sub-heading">
        Grow your network by adding new contacts. Fill in the details below to
        stay connected.
      </Heading>

      <TableTabs
        defaultkey={`${activeTab}`}
        tabBarExtraContent={RenderButton()}
      >
        <>
          <TableTabsContent tab="Customers" key="customers">
            <Customers />
            {/* <Suspense fallback={<FallBackLoader />}>
            </Suspense> */}
          </TableTabsContent>
          <TableTabsContent tab="Suppliers" key="suppliers">
            <Suppliers />
            {/* <Suspense fallback={<FallBackLoader />}>
            </Suspense> */}
          </TableTabsContent>
        </>
      </TableTabs>
    </ContactMainWrapper>
  );
};
