import React, { FC, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  P,
  BOLDTEXT,
  Capitalize,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { Card, Space } from 'antd';
import { useQuery } from 'react-query';
import { getBalanceSheetReport } from '../../../api';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import moment from 'moment';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

interface IProps {
  props?: any;
}

export const BalanceSheetList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const [result, setResult] = useState<any[]>([]);
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });

      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const { data, isLoading, isFetched } = useQuery(
    [`report-balancesheet-query=${query}`, query],
    getBalanceSheetReport
  );

  useEffect(() => {
    if (data?.data) {
      console.log(data, 'asdasdas');
      // Define the desired order of account types
      const desiredOrder = [
        'Current Asset',
        'Fixed Asset',
        'Current Liability',
        'Long-term Liability',
        'Equity',
      ];

      // Helper function to calculate totals
      function calculateTotals(accounts) {
        const totals = accounts.reduce((acc, account) => {
          account.total.forEach((entry) => {
            const key = `${entry.date}-${entry.month}-${entry.year}`;
            if (!acc[key]) {
              acc[key] = {
                date: entry.date,
                month: entry.month,
                year: entry.year,
                amount: 0,
              };
            }
            acc[key].amount += parseFloat(entry.amount);
          });
          return acc;
        }, {});

        return Object.values(totals);
      }

      // Create a custom sorting function
      function customSort(a, b) {
        const typeA = a?.account_type;
        const typeB = b?.account_type;

        // Compare the indices of account types in the desired order
        return desiredOrder?.indexOf(typeA) - desiredOrder?.indexOf(typeB);
      }

      // Sort the data using the custom sorting function
      const sortedData = data?.data?.sort(customSort);

      // Reconstruct data by adding totals
      const reconstructedData = sortedData?.map((item) => {
        const { accounts, account_type } = item;
        const totals = calculateTotals(accounts);

        // Add a new item for totals
        const newItem = {
          ...item,
          balances: totals,
          accounts: [
            ...accounts,
            { name: `Total ${account_type}`, type: 'BOLD', total: totals },
          ],
        };

        return newItem;
      });

      const getSelectiveTotals = (selectiveAccountTypes) => {
        const totalsArr = [];
        reconstructedData
          .filter((item) => selectiveAccountTypes.includes(item.account_type))
          .forEach((nestLoop) => {
            nestLoop.balances.forEach((balanceItem: any) => {
              const indexed = totalsArr.findIndex(
                (i) =>
                  `${i.month}/${i.year}` ===
                  `${balanceItem.month}/${balanceItem.year}`
              );
              if (indexed < 0) {
                totalsArr.push(balanceItem);
              } else {
                totalsArr.splice(indexed, 1, {
                  ...totalsArr[indexed],
                  amount: totalsArr[indexed].amount + balanceItem.amount,
                });
              }
            });
          });

        return totalsArr;
      };

      const LibalitiesAndEquities = getSelectiveTotals([
        'Current Liability',
        'Long-term Liability',
        'Equity',
      ]);
      const AssetsTotal = getSelectiveTotals(['Current Asset', 'Fixed Asset']);

      if (!!LibalitiesAndEquities) {
        reconstructedData[reconstructedData.length - 1].accounts.push({
          name: 'Total Liability & Equity',
          type: 'BOLD',
          total: LibalitiesAndEquities,
        });
      }

      const getTotalAssetsInsertIndex = () => {
        const c_assetsIndex = reconstructedData.findIndex(
          (item) => item.account_type === 'Current Asset'
        );

        const f_assetsIndex = reconstructedData.findIndex(
          (item) => item.account_type === 'Fixed Asset'
        );

        console.log({ c_assetsIndex }, 'indexes');

        let greaterIndex = Math.max(c_assetsIndex, f_assetsIndex);
        return greaterIndex;
      };

      reconstructedData[getTotalAssetsInsertIndex()].accounts.push({
        name: 'Total Assets',
        type: 'BOLD',
        total: AssetsTotal,
      });
      setResult(reconstructedData);
    }
  }, [data]);

  const colss = [];

  result?.forEach((item, index) => {
    item.accounts.forEach((ai, aid) => {
      ai.total.forEach((at, ati) => {
        if (!colss?.includes(`${at?.month}/${at?.year}`)) {
          colss.push(`${at?.month}/${at?.year}`);
        }
      });
    });
  });

  // Sorting colss array based on month/year
  colss?.sort((a, b) => {
    const [monthA, yearA] = a.split('/');
    const [monthB, yearB] = b.split('/');
    if (yearA !== yearB) {
      return parseInt(yearA, 10) - parseInt(yearB, 10);
    }
    return parseInt(monthA, 10) - parseInt(monthB, 10);
  });
  const columnTotals = {};
  result?.forEach((values) => {
    values?.accounts.forEach((ai) => {
      ai?.total.forEach((at) => {
        const monthAndYear = `${at?.month}/${at?.year}`;
        if (!columnTotals[monthAndYear]) {
          columnTotals[monthAndYear] = 0;
        }
        columnTotals[monthAndYear] += parseInt(at.amount, 10);
      });
    });
  });

  const onEmail = async () => {};

  return (
    <WrapperBalanceSheet>
      <FixedHeader
        src="/assets/balanceSheet.png"
        title="Balance Sheet"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
        result={result}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.BALANCE_SHEET_REPORT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <Card className="balancesheet-card">
        <table style={{ width: '100%' }}>
          {result?.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th className={`ant-table-cell textLeft`}>
                    <BOLDTEXT>{'Balance Sheet'}</BOLDTEXT>
                  </th>
                  {colss?.map((itemHeader, index) => {
                    return (
                      <th key={index} className={`ant-table-cell`}>
                        <BOLDTEXT>
                          {moment(itemHeader, 'M/YYYY').format('MMM-YY')}
                        </BOLDTEXT>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => {
                  const columnTotals = colss?.map((dataIndex) => {
                    const totalForColumn = item?.accounts?.reduce(
                      (acc, account_item) => {
                        const [renderTotal] = account_item?.total.filter(
                          (fi) => `${fi?.month}/${fi?.year}` === dataIndex
                        );
                        return acc + parseInt(renderTotal?.amount || 0, 10);
                      },
                      0
                    );

                    return totalForColumn;
                  });

                  return (
                    <React.Fragment key={index}>
                      <div className="title">
                        <BOLDTEXT>{item.account_type}</BOLDTEXT>
                      </div>
                      {item?.accounts?.map((account_item, account_index) => {
                        return (
                          <tr
                            className={`rows ${
                              account_item?.type === 'BOLD'
                                ? 'calculated_groups'
                                : 'rows'
                            }`}
                            key={account_index}
                          >
                            <td
                              className={`${
                                account_item?.type === 'BOLD' ? 'left' : ''
                              } `}
                            >
                              {account_item?.name}
                            </td>
                            {colss?.map((dataIndex, colDataIndex) => {
                              const [renderTotal] = account_item?.total.filter(
                                (fi) => `${fi?.month}/${fi?.year}` === dataIndex
                              );

                              return (
                                <td key={colDataIndex}>
                                  {renderTotal?.amount
                                    ? renderTotal?.amount
                                    : '-'}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                      {/* <tr className="calculated_groups">
                        <td align="left">Total {item?.account_type}</td>
                        {columnTotals?.map((total, colIndex) => (
                          <td align="center" key={colIndex}>
                            {total}
                          </td>
                        ))}
                      </tr> */}
                    </React.Fragment>
                  );
                })}
                {/* <tr className="calculated_groups total">
                  <td align="left">
                    {' '}
                    <BOLDTEXT>Total</BOLDTEXT>
                  </td>
                  {colss?.map((cols, colsIndex) => (
                    <td align="center" key={colsIndex}>
                      <BOLDTEXT>{columnTotals[cols] || 0}</BOLDTEXT>
                    </td>
                  ))}
                </tr> */}
              </tbody>
            </>
          ) : (
            <EmptyState
              text="Report File In Empty"
              content="Unraveling the Enigma of Empty Report Files."
              src="/assets/emptyReport.gif"
              width={200}
              height={200}
              alt="invoice"
            />
          )}
        </table>
      </Card>

      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperBalanceSheet>
  );
};

export const WrapperBalanceSheet = styled.div`
  .balancesheet-card {
    z-index: -0 !important;
    border: none !important;
    .ant-card-body {
      padding: 0 !important;
    }
  }

  .buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
  }
  table thead tr {
    background: ${(props: IThemeProps) =>
      props?.theme?.colors?.reportTableHeader};
    th {
      background: ${(props: IThemeProps) => props.theme?.color?.tableBodyColor};
      box-sizing: border-box;
      padding: ${convertToRem(12)};
      font-size: ${convertToRem(13)};
      font-weight: 500;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.tableHeaderTextColor};
      &:first-child {
        border-top-left-radius: ${convertToRem(4)};
      }
      &:last-child {
        border-top-right-radius: ${convertToRem(4)};
      }
    }
  }

  tbody {
    color: ${(props: IThemeProps) => props.theme?.colors?.tableBodyTextColor};
    .title {
      padding: ${convertToRem(12)};
      width: 100%;
      font-weight: 400;
    }
    .rows {
      background: ${(props: IThemeProps) =>
        props.theme?.colors?.tableBodyColor};
      td {
        font-size: ${(props: IThemeProps) => convertToRem(13)};
        font-weight: 400;
        letter-spacing: 0.13px;
        padding: ${convertToRem(12)};
        text-align: center;
        border-bottom: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-top: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        &:first-child {
          text-align: left;
        }
      }
    }
    .calculated_groups {
      td {
        padding: ${convertToRem(12)} !important;
        font-weight: 500;
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        color: ${(props: IThemeProps) =>
          props.theme?.colors?.tableHeaderTextColor};
      }
    }
    .total {
      td {
        border-top: ${convertToRem(2)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
      }
    }
  }
`;
