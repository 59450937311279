import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import { BreadCrumbArea, Heading, PurchaseOrder } from '@components';
import { PurchaseOrderForm } from './Form';
import { Link } from 'react-router-dom';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import fileInvoice from '@iconify/icons-bx/bx-file';
import Icon from '@iconify/react';

export const PurchaseOrderEditorWidget: FC = () => {
  const { routeHistory } = useGlobalContext();
  const { location } = routeHistory;
  const id = location && location.pathname.split('/app/create-order/')[1];

  return (
    <WrapperWidget>
      <div className="flex alignCenter justifySpaceBetween">
        <div>
          <div className="flex alignCenter">
            <i className="mr-12">
              <PurchaseOrder />
            </i>
            <Heading type="form">Purchase Order</Heading>
          </div>
          <Heading className="pb-10" type="sub-heading">
            Your Business Symphony with Seamless Purchase Orders, Where
            Professionalism Sets the Stage.
          </Heading>
        </div>
        {/* <div className="flex alignFEnd justifySpaceBetween"> */}
        <BreadCrumbArea>
          <Breadcrumb separator=">">
            <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
            <Breadcrumb.Item>
              <Link to={`/app${ISupportedRoutes.PURCHASE_ORDER}`}>
                Purchase Order
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>New Purchase Order</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbArea>
      </div>
      {/* <div className="flex alignFEnd justifySpaceBetween ml-12 pv-13">
          <h4 className="bold m-reset">
            Already Purchased? &nbsp;
            <Link
              className="purchase_order"
              to={`/app${ISupportedRoutes.CREATE_PURCHASE_Entry}`}
            >
              Enter Purchases Here
            </Link>
          </h4>
        </div>
      </div> */}
      <PurchaseOrderForm id={id} />
    </WrapperWidget>
  );
};

const WrapperWidget = styled.div`
  .purchase_order {
    color: #7988ff !important;
  }
`;
