import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonTag, Heading, SmartFilter, P } from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { UpwardTrendIcon } from '../../../components/Icons';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import { Card } from 'antd';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

interface IProps {
  props?: any;
}

export const ManagementReportList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const columns = ['Name', 'Jan 2023', 'Dec 2022', 'Variance'];

  const data: any = [
    {
      title: 'Cash',
      name: 'cash received',
      'jan 2023': '2.165.99',
      'dec 2022': '13.519.29',
      variance: '-83.99%',
    },
    {
      name: 'cash spent',
      'jan 2023': '11.501.77',
      'dec 2022': '6.351.87',
      variance: '81.08%',
    },
    {
      name: 'cash surplus (deficit)',
      'jan 2023': '(9.336.77)',
      'dec 2022': '7.167.42',
      variance: '-230.27%',
    },
    {
      name: 'closing bank balance',
      'jan 2023': '1.760.54',
      'dec 2022': '11.097.31',
      variance: '-84.14%',
    },
    {
      title: 'profitability',
      name: 'income',
      'jan 2023': '7.263.59',
      'dec 2022': '11.230.84',
      variance: '-35.32%',
    },
  ];
  const onEmail = async () => {};

  return (
    <WrapperMangement>
      <FixedHeader
        src="/assets/agedPayable.png"
        title="Management Report"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.MANAGEMENT_REPORT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <table className="table">
        {data?.length > 0 ? (
          <>
            <thead>
              <tr className="columns">
                {columns.map((item, columnIndex) => {
                  return (
                    <th
                      align="left"
                      className={
                        columnIndex === 0 ? 'contact_field' : 'rest_field'
                      }
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((element, index) => {
                return (
                  <React.Fragment key={index}>
                    {element.title ? (
                      <tr className="rows">
                        <td align="left" colSpan={12} className="title">
                          {element?.title}
                        </td>
                      </tr>
                    ) : (
                      ''
                    )}
                    <tr className="rows">
                      {columns.map((item, colIndex) => {
                        return (
                          <td
                            align="left"
                            key={colIndex}
                            className={
                              colIndex === 0 ? 'contact_field' : 'rest_field'
                            }
                          >
                            {element[item.toLocaleLowerCase()] || '-'}
                            {colIndex === 3 &&
                              element[item.toLocaleLowerCase()] && (
                                <span>
                                  {' '}
                                  <UpwardTrendIcon />
                                </span>
                              )}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </>
        ) : (
          <Card>
            <EmptyState
              text="Report File In Empty"
              content="Unraveling the Enigma of Empty Report Files."
              src="/assets/emptyReport.gif"
              width={200}
              height={200}
              alt="invoice"
            />
          </Card>
        )}
      </table>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperMangement>
  );
};
const WrapperMangement = styled.div`
  .buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;

    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      line-height: 1.3;

      .columns {
        border: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBorderColor};
        th {
          padding: ${convertToRem(12)};
          font-weight: 500;
          font-size: ${convertToRem(14)};

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
        .rest_field {
          width: 10%;
        }
        .contact_field {
          width: 20%;
        }
      }
    }

    tbody {
      line-height: 1.5;
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};
      font-size: ${convertToRem(13)};
      .title {
        font-weight: 500;
      }
      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: center;
        border-top: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(12)};
          span {
            margin: 0 ${convertToRem(12)};
          }
        }
        .rest_field {
          width: 10%;
        }

        .contact_field {
          width: 20%;
        }
      }
    }
  }
`;