import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

interface StyledButtonProps {
  isActive: boolean;
}

interface TimeButtonProps {
  value: string;
  onClick: (value: string) => void;
  isActive: boolean;
}

const TimeButton: React.FC<TimeButtonProps> = ({
  value,
  onClick,
  isActive,
}) => {
  const StyledButton = styled(Button)<StyledButtonProps>`
    margin: 7px 5px 7px 5px !important;
    &.ant-btn {
      margin: 0 8px;
      border-radius: 7px;
      border-color: none !important;
      background: ${({ isActive }) => (isActive ? '#7988FF' : '#fff')};
      color: ${({ isActive }) => (isActive ? '#fff' : '#3D3F65')};
      &:hover {
        background: #7988ff !important;
        border-color: none !important;
        color: #fff;
      }
    }
  `;
  return (
    <StyledButton isActive={isActive} onClick={() => onClick(value)}>
      {value}
    </StyledButton>
  );
};

export default TimeButton;
