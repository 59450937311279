import { IThemeProps } from '@invyce/shared/invyce-theme';
import React from 'react';
import styled from 'styled-components';
import { PlusIcon } from '../../assets/icons';

interface ContentProps {
  text: string;
  content: string;
  src: string;
  alt: string;
  width?: number;
  height?: number;
  buttonText?: string;
  onClick?: () => void;
}

const Container = styled.div`
  text-align: center;
  padding: 20px;
  width: 35%;
  margin: 30px auto;
`;

const Heading = styled.h2`
  color: ${(props: IThemeProps) =>
    props.theme.colors.emptyStateHeading} !important;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: capitalize;
`;

const Description = styled.p`
  color: ${(props: IThemeProps) =>
    props.theme.colors.emptyStateDescription} !important;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: normal;
  width: '15px !important';
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
`;
const StyledButton = styled.button`
  font-size: 16px;
  padding: 13px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: auto;
  color: #fff;
  margin-bottom: 10px;
  margin: auto !important;
  background-color: #7988ff;
  display: inline-flex;
  align-items: end !important;
  gap: 5px !important;
  &:hover {
    background-color: #939fff;
  }
`;

const EmptyState: React.FC<ContentProps> = ({
  text,
  content,
  src,
  alt,
  width,
  height,
  buttonText,
  onClick,
}) => {
  return (
    <Container>
      <StyledImage src={src} alt={alt} width={width} height={height} />
      <Heading>{text}</Heading>
      <Description>{content}</Description>
      {buttonText && (
        <StyledButton onClick={onClick}>
          <PlusIcon />
          {buttonText}
        </StyledButton>
      )}
    </Container>
  );
};

export default EmptyState;
