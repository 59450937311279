import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { IncomeStatementList } from '../IncomeStatement/List';
import { CashSummaryList } from './list';
import { CashSummaryContainer } from './styles';

export const CashSummary: FC = () => {
  return (
    <CashSummaryContainer>
      <Row gutter={14}>
        <Col span={20} offset={2}>
          <CashSummaryList />
        </Col>
      </Row>
    </CashSummaryContainer>
  );
};
