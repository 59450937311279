import { Button } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ButtonTag, Heading } from '@components';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { PermissionList } from '../PermissionsList';

export const PermissionsSettingsContainer: FC = () => {
  const { setPermissionConfigModal } = useGlobalContext();

  return (
    <WrapperPermissionContainer>
      <div className="flex alignCenter justifySpaceBetween pb-20">
        <Heading type="table">Permission Settings</Heading>
        <ButtonTag
          onClick={() => setPermissionConfigModal(true)}
          type="primary"
          size="middle"
          title=" Add Permission"
        />
      </div>

      <PermissionList />
    </WrapperPermissionContainer>
  );
};

const WrapperPermissionContainer = styled.div``;
