import { Button } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import fileInvoice from '@iconify-icons/la/file-invoice';
import bxPlus from '@iconify-icons/bx/bx-plus';
import { ButtonTag, CreditNotes, Heading, TableTabs, TableTabsContent } from '@components';

import { Rbac } from '../../../components/Rbac';
import { PERMISSIONS } from '../../../components/Rbac/permissions';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { ISupportedRoutes } from '../../../modal';
import { IThemeProps } from '../../../hooks/useTheme/themeColors';
import AprovedCreditNotes from './AprovedCreditNotes';
import DraftCreditNotes from './DraftCreditNotes';

export const CreditNoteList: FC = () => {
  const { routeHistory } = useGlobalContext();
   const { history } = routeHistory;
  const [activeTab, setActiveTab] = useState('');
  const { search } = routeHistory.history.location;

  useEffect(() => {
    if (!activeTab) {
      setActiveTab('aproved');
    }
  }, [activeTab]);

  useEffect(() => {
    if (search) {
      const filterTab = search.split('?')[1].split('&')[0].split('=')[1];
      if (filterTab !== null && filterTab !== 'id' && filterTab !== activeTab) {
        setActiveTab(filterTab);
      }
    }
  }, [search, activeTab]);

  const RenderButton = () => {
    return (
      <Rbac permission={PERMISSIONS.INVOICES_CREATE}>
        <ButtonTag
          type="primary"
          onClick={() =>
            history.push({
              pathname: `/app${ISupportedRoutes.ADD_CREDIT_NOTE}`,
              state: {
                from: history.location.pathname,
              },
            })
          }
          size={'large'}
          title=" New Credit Note"
          icon={bxPlus}
        />
      </Rbac>
    );
  };

  return (
    <MainWrapperCreditNotes>
      <div className="flex alignCenter mb-5">
        <i>
          <CreditNotes />
        </i>
        <Heading type="table">Credit Notes</Heading>
      </div>
      <Heading type="sub-heading">
        Elevating Your Business with Precision and Professionalism - Your
        Gateway to Seamless Credit Transactions.
      </Heading>
      <TableTabs
        defaultkey={`${activeTab}`}
        tabBarExtraContent={RenderButton()}
      >
        <>
          <TableTabsContent tab="Aproved" key="aproved">
            <AprovedCreditNotes />
          </TableTabsContent>
          <TableTabsContent tab="Draft" key="draft">
            <DraftCreditNotes />
          </TableTabsContent>
        </>
      </TableTabs>
    </MainWrapperCreditNotes>
  );
};

const MainWrapperCreditNotes = styled.div`
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .title-icon {
    color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
  }
`;
