import React, { useState, useRef, FC, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { useQuery } from 'react-query';
import { getTribalanceReports } from '../../../api';
import { Card, Space } from 'antd';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

export const TribalanceReportList: FC = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const [result, setResult] = useState<any[]>([]);
  const { query } = config;
  const printRef = useRef();
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  useEffect(() => {
    if (history?.location?.search) {
      let obj = {};
      const queryArr = history.location.search.split('?')[1].split('&');
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, [split[0]]: split[1] };
      });

      setConfig({ ...config, ...obj });
    }
  }, [history]);

  const { data, isLoading, isFetched } = useQuery(
    [`report-trialbalance-query=${query}`, query],
    getTribalanceReports
  );

  useEffect(() => {
    if (data) {
      setResult(data?.data);
    }
  }, [data]);

  const searchedQueryItem: any = useMemo(() => {
    return query ? JSON.parse(atob(query)) : query;
  }, [query]);

  const cols = [
    {
      title: 'Amount',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
    },
  ];

  const onEmail = async () => {};

  return (
    <WrapperTrialBalance>
      <FixedHeader
        src="/assets/trialBalance.png"
        title="Trial Balances"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.TRIALBALACE_REPORT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            {result?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr className="columns">
                    {cols.map((itemHeader, index) => {
                      return <th key={index}>{itemHeader?.title}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {result?.map((trialBalance, index) => {
                    console.log(trialBalance, 'trialBalancetrialBalance');
                    return (
                      <React.Fragment key={index}>
                        {!trialBalance?.isLastIndex ? (
                          <tr className="rows">
                            {cols.map((item, index) => {
                              return (
                                <td key={index}>
                                  {trialBalance[
                                    item?.key.toLocaleLowerCase()
                                  ] || '-'}
                                </td>
                              );
                            })}
                          </tr>
                        ) : (
                          <tr className="totalRows">
                            {cols.map((item, index) => {
                              return (
                                <td key={index}>
                                  {trialBalance[item?.key.toLocaleLowerCase()]}
                                </td>
                              );
                            })}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <Card>
                <EmptyState
                  text="Report File In Empty"
                  content="Unraveling the Enigma of Empty Report Files."
                  src="/assets/emptyReport.gif"
                  width={200}
                  height={200}
                  alt="invoice"
                />
              </Card>
            )}
          </>
        </PrintFormat>
      </div>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperTrialBalance>
  );
};

const WrapperTrialBalance = styled.div`
  .buttons {
    display: flex;
    justifycontent: end;
    gap: 10px;
    marginbottom: 20px;
  }

  .table {
    width: 100%;
    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      text-align: left;
      .columns {
        th {
          padding: ${convertToRem(12)} ${convertToRem(16)};
          font-size: ${convertToRem(14)};
          font-weight: 500 !important;

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
    tbody {
      font-size: ${convertToRem(13)};
      fontweight: 400;
      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        text-align: left;
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(16)};
        }
      }
      .totalRows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        color: ${(props: IThemeProps) =>
          props?.theme?.colors?.listTableHeaderText};
        text-align: left;
        font-weight: 500;

        td {
          padding: ${convertToRem(16)};
        }
      }
    }
  }
`;
