import React, { FC, useEffect, useState } from 'react';
import addLine from '@iconify/icons-ri/add-line';
import { Icon } from '@iconify/react';
import { Button } from 'antd';
import { ButtonTag, ChartOfAccounts, Heading } from '@components';
import { useGlobalContext } from '../../hooks/globalContext/globalContext';
import { AccountsList } from './AccountsList';
import { columns, data } from './AccountsList/var';
import AccountingWrapper, { Header } from './styles';
import { Rbac } from '../../components/Rbac';
import { PERMISSIONS } from '../../components/Rbac/permissions';
export const Accounts: FC = () => {
  const [accountsList, setAccountsList] = useState([]);
  const { setAccountsModalConfig } = useGlobalContext();
  useEffect(() => {
    setAccountsList(data);
  }, []);

  return (
    <AccountingWrapper>
      <Header>
        <div className="flex alignCenter">
          <i className="pt-10">
            <ChartOfAccounts />
          </i>
          <Heading type="table">Chart of Accounts</Heading>
        </div>
        <div className="addAccount flex alignCenter pt-20 justifyFlexEnd">
          <Rbac permission={PERMISSIONS.ACCOUNTS_CREATE}>
            <ButtonTag
              type="primary"
              onClick={() =>
                setAccountsModalConfig({ visibility: true, id: null })
              }
              size={'middle'}
              icon={addLine}
              title="Add Account"
            />
          </Rbac>
        </div>
      </Header>
      <Heading type="sub-heading" className="pb-8">
        Navigating Financial Clarity Unveiling the Blueprint of Our Chart of
        Accounts for Informed Decision-Making.
      </Heading>
      <AccountsList data={accountsList} columns={columns} />
    </AccountingWrapper>
  );
};
