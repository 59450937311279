import React, { FC, Fragment } from 'react';
import { StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import moneyFormat from '../../utils/moneyFormat';
import { PdfDocument } from './PdfDocument';
import { PDFFontWrapper } from './PDFFontWrapper';
import { PDFHeader } from './pdf-header';
import dayjs from 'dayjs';
import { IContactType } from '../../modal';

interface IPropsHeader {
  title?: string;
  organizationName?: string;
  organizationEmail?: string;
  organizationContact?: string;
  website?: string;
  address?: string;
  city?: string;
  code?: string | number;
  country?: string;
  logo?: string;
}

interface IProps {
  header: IPropsHeader;
  resultData: IContactType;
}

const styles = StyleSheet.create({
  container: {
    margin: '10px',
    border: '1px solid #ddd',
    padding: '10px',
  },
  header: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #ddd',
    padding: '8px 0',
  },
  tableCell: {
    flex: 1,
    fontSize: '12px',
  },
});

export const ContactViewPdf: FC<IProps> = ({ header, resultData }) => {
  return (
    <PdfDocument>
      <PDFFontWrapper>
        <PDFHeader {...header} />
        <View style={styles.container}>
          <Text style={styles.header}>Contact Details</Text>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Business Name</Text>
            <Text style={styles.tableCell}>{resultData?.businessName}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Email</Text>
            <Text style={styles.tableCell}>{resultData?.email}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Phone Number</Text>
            <Text style={styles.tableCell}>{resultData?.phoneNumber}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Address</Text>
            <Text style={styles.tableCell}>
              {resultData?.addresses[0]?.city},{' '}
              {resultData?.addresses[0]?.country}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Credit Limit:</Text>
            <Text style={styles.tableCell}>{resultData.creditLimit}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Credit Limit Block:</Text>
            <Text style={styles.tableCell}>{resultData.creditLimitBlock}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Sales Discount:</Text>
            <Text style={styles.tableCell}>{resultData.salesDiscount}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Payment Days Limit:</Text>
            <Text style={styles.tableCell}>{resultData.paymentDaysLimit}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Balance:</Text>
            <Text style={styles.tableCell}>{resultData.balance}</Text>
          </View>
        </View>
      </PDFFontWrapper>
    </PdfDocument>
  );
};
