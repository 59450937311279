import { Col, Row } from 'antd';
import { ReportingSummaryWrapper } from './styles';
import { ReportSummaryList } from './List';

export const ReportSummary = () => {
  return (
    <ReportingSummaryWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <ReportSummaryList />
        </Col>
      </Row>
    </ReportingSummaryWrapper>
  );
};
