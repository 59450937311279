import React, { FC } from 'react';
import { Heading, TableCard, BreadCrumbArea, CreditNotes } from '@components';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { CreditNoteEditor } from './Editor';
import Icon from '@iconify/react';
import fileInvoice from '@iconify/icons-bx/bx-file';

export const CreditNoteEditorWidget: FC = () => {
  const { routeHistory } = useGlobalContext();
  const { location } = routeHistory;
  const id = location?.pathname?.split(`/app/add-credit-note/`)[1] || null;

  return (
    <>
     <div className="flex alignCenter justifySpaceBetween">
        <div>
      <div className="flex alignCenter">
        <i className="mr-12">
          <CreditNotes />
        </i>
        <Heading type="form">Credit Note</Heading>
      </div>
      <Heading className="pb-10" type="sub-heading">
        Elevating Your Business with Precision and Professionalism - Your
        Gateway to Seamless Credit Transactions.
      </Heading>
      </div>
      <BreadCrumbArea>
        <Breadcrumb separator=">">
          <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
          <Breadcrumb.Item>
            <Link to={`/app${ISupportedRoutes.CREDIT_NOTES}`}>
              Credit Notes
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Credit Note</Breadcrumb.Item>
        </Breadcrumb>
      </BreadCrumbArea>
      </div>
      <CreditNoteEditor id={id} type="CN" />
    </>
  );
};
