import React, { FC } from 'react';
import styled from 'styled-components';
import { Heading } from '@components';
import convertToRem from '../../../../utils/convertToRem';
import { Card } from 'antd';

interface IProps {
  props?: any;
}

export const EditorWidget: FC<IProps> = (props) => {
  return (
    <WrapperAddAccount>
      <div className="flex alignCenter justifySpaceBetween pb-20">
        <div>
          <Heading type="form">Add new Accounts</Heading>
        </div>
        <div className="_disable_print flex alignCenter"></div>
      </div>
      <Card className="account-categorization"></Card>
    </WrapperAddAccount>
  );
};

export const WrapperAddAccount = styled.div`
  ${({ theme }) => {
    return {
      marginTop: `${convertToRem(20)}`,
      padding: '1px',
    };
  }}
`;
