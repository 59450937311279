import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer, Col, Row, Button } from 'antd';
import printIcon from '@iconify-icons/bytesize/print';
import {
  ArrowButton,
  CommonTable,
  ButtonTag,
  Card,
  Heading,
} from '@components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { moneyFormatJs } from '@invyce/common';
import {
  IContactTypes,
  IContactViewResult,
  ISupportedRoutes,
  ReactQueryKeys,
} from '@invyce/shared/types';
import PDFICON from '@iconify-icons/ant-design/file-pdf-outlined';
import { ColumnsType } from 'antd/lib/table';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { convertToRem } from '@invyce/pixels-to-rem';
import { SummaryItem } from './SummaryItem';
import {
  ContactDebit,
  TotalBalanceIcon,
  TotalSales,
} from '../../../assets/icons';
import { ItemSalesGraph } from '../../Items/ItemsList/ItemsView/ItemSalesGraph';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { viewSingleContact } from '../../../api/Contact';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { ContactViewPdf } from '../../../components/PDFs';
import Icon from '@iconify/react';
import moneyFormat from 'apps/main-ui/src/utils/moneyFormat';
import moment from 'moment';

interface IProps {
  onClose: () => void;
  visible: boolean;
  data: any;
}

export const ContactItemDetails: FC<IProps> = ({ onClose, visible, data }) => {
  const history = useHistory();
  const { routeHistory, userDetails } = useGlobalContext();
  const [apiConfig, setApiConfig] = useState({
    id: null,
    start: dayjs(),
    end: dayjs(),
    type: 1,
  });
  const [{ result, nextContact, prevContact }, setContactDetails] = useState<
    IContactViewResult | undefined
  >({
    result: null,
    message: '',
    nextContact: '',
    prevContact: '',
    status: '',
  });

  const { organization } = userDetails;
  const { address: organizationAddress, website, attachment } = organization;
  const { city, country, postalCode } = organizationAddress;

  const headerprops = {
    organizationName: data?.businessName,
    city,
    country,
    title: '',
    organizationContact: data?.cellNumber || data?.phonenNumber,
    organizationEmail: data?.email,
    address: '',
    code: postalCode,
    logo: attachment?.path,
    website,
  };

  useEffect(() => {
    if (data?.id) {
      setApiConfig((prev) => {
        return { ...prev, id: data?.id };
      });
    }
  }, [data?.id]);

  const { data: ContactViewResponse } = useQuery<any>(
    [ReactQueryKeys?.CONTACT_VIEW, apiConfig?.id, apiConfig?.type],
    viewSingleContact,
    {
      enabled: !!apiConfig?.id,
    }
  );

  useEffect(() => {
    if (routeHistory?.history?.location?.search) {
      const queryArr = history.location.search.split('?')[1].split('&');
      let obj = {};
      queryArr.forEach((item, index) => {
        const split = item.split('=');
        obj = { ...obj, type: split[1] === 'suppliers' ? 2 : 1 };
      });
      setApiConfig((prev) => {
        return { ...prev, ...obj };
      });
    }
  }, [routeHistory]);

  useEffect(() => {
    if (ContactViewResponse?.data) {
      const contactResponse = ContactViewResponse?.data;
      setContactDetails(contactResponse);
    }
  }, [ContactViewResponse]);

  const handleMutateApiConfig = (id) => {
    setApiConfig((prev) => {
      return { ...prev, id };
    });
  };

  const renderTitleArea = () => {
    return (
      <div className="flex alignCenter justifySpaceBetween">
        <div className="flex alignCenter">
          <ArrowButton
            disabled={!prevContact}
            className="mr-10 cusrsor"
            onClick={() => {
              if (prevContact) {
                handleMutateApiConfig(prevContact);
              }
            }}
            iconType="left"
          />
          <ArrowButton
            disabled={!nextContact}
            onClick={() => {
              if (nextContact) {
                handleMutateApiConfig(nextContact);
              }
            }}
            iconType="right"
          />
        </div>
        <div className="flex alignCenter mr-6">
          <ButtonTag
            className="mr-10"
            onClick={() => console.log('')}
            title="Print"
            size="middle"
            type="ghost"
            icon={printIcon}
          />
          {result && (
            <PDFDownloadLinkWrapper
              document={
                <ContactViewPdf
                  resultData={result as any}
                  header={headerprops}
                />
              }
            >
              <div className="flex alignCenter">
                <Icon icon={PDFICON} className="flex alignCenter mr-5" />
                <span> Download PDF</span>
              </div>
            </PDFDownloadLinkWrapper>
          )}
          <ButtonTag
            className="ml-10"
            onClick={() => console.log('')}
            title="Import"
            type="ghost"
            size="middle"
          />
        </div>
      </div>
    );
  };

  return (
    <CustomDrawer
      title={renderTitleArea()}
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      width={1000}
    >
      <WrapperContactListDetails>
        <Row gutter={[20, 12]}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="_itemdetailcard">
              <div className="flex justifySpaceBetween alignCenter ml-10 innerHeader">
                <h4>Contact Overview</h4>
                <Button
                  className="btn"
                  onClick={() => {
                    const contactType =
                      result.contactType === 1 ? 'customer' : 'supplier';
                    history.push(
                      `/app${ISupportedRoutes.UPDATE_CONTACT}/${result.id}`
                    );
                  }}
                  type="link"
                  size="middle"
                >
                  Edit Contact
                </Button>
              </div>
              <div className="calc_Details">
                <Row gutter={12}>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total Balance"
                      icon_bg="_color"
                      icon={<TotalBalanceIcon />}
                      amount={result?.balance}
                      card={{
                        light:
                          'linear-gradient(127deg, rgba(203,202,222,1) 0%, rgba(230,230,237,1) 33%, rgba(242,242,242,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total Credit Limit"
                      icon_bg="_color3"
                      icon={<ContactDebit />}
                      amount={result?.creditLimit}
                      card={{
                        light:
                          'linear-gradient(22deg, rgba(204,227,255,1) 0%, rgba(230,230,238,1) 33%, rgba(233,240,248,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total credit Limit Block"
                      icon_bg="_color2"
                      icon={<TotalSales />}
                      amount={result?.creditLimitBlock}
                      card={{
                        light:
                          'linear-gradient(339deg, rgba(255,255,255,1) 0%, rgba(238,233,233,1) 33%, rgba(220,220,220,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div className="flex justifySpaceBetween alignCenter innerHeader">
                <h4>Contact Details</h4>
                <Button
                  className="btn"
                  onClick={() => {
                    const contactType =
                      result.contactType === 1 ? 'customer' : 'supplier';
                    history.push(
                      `/app${ISupportedRoutes.CONTACTS}/${result.id}?type=${contactType}`
                    );
                  }}
                  type="link"
                  size="middle"
                >
                  More details
                </Button>
              </div>
              <div className="calc_Details">
                <Row gutter={[20, 10]}>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Contact Email</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {result?.email || '-'}
                      </Heading>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Business Name</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {result?.businessName || '-'}
                      </Heading>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Balance</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {moneyFormat(result?.balance) || '-'}
                      </Heading>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Credit Block</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {moneyFormat(result?.creditLimitBlock) || '-'}
                      </Heading>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Credit Limit</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {moneyFormat(result?.creditLimit) || '-'}
                      </Heading>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="detail_area">
                      <p>Due Date</p>
                      <Heading type="normal" fontWeight="600" color="#012756">
                        {moment(result?.createdAt).format('LL') || '-'}
                      </Heading>
                    </div>
                  </Col>
                </Row>
              </div>
              <ItemSalesGraph id={1} />
            </Card>
          </Col>
        </Row>
      </WrapperContactListDetails>
    </CustomDrawer>
  );
};

const WrapperContactListDetails = styled.div`
  padding: 0;
  ._itemviewcard {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: ${convertToRem(16)};
    .item_amount {
      font-size: ${convertToRem(16)};
      line-height: ${convertToRem(24)};
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${convertToRem(7)} 0;
      color: ${(props: IThemeProps) => props?.theme?.colors?.$TEXT};
    }
  }

  ._itemdetailcard {
    min-height: ${convertToRem(272)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    margin-bottom: ${convertToRem(16)};
    padding: 10px;
    .innerHeader {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      padding: 9px;
      margin: 0 10px;

      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
        margin: 0 !important;
      }
    }
    .btn {
      background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
      border: 1px solid #2395e7;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calc_Details {
      padding: ${convertToRem(16)};
      color: ${(props: IThemeProps) => props.theme.colors.$BLACK};

      .detail_area {
        padding: 8px 12px;
        border-radius: 12px;
        border: 1px solid #eaeaea;

        p {
          color: ${(props: IThemeProps) =>
            props.theme.colors.emptyStateDescription};
          font-size: ${convertToRem(15)};
        }
      }
    }
    h4 {
      margin-bottom: ${convertToRem(23)};
    }
    p {
      font-size: ${convertToRem(16)};
      margin-bottom: ${convertToRem(23)};
    }
  }
  ._salesitemcard {
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(272)};
    margin-bottom: ${convertToRem(13)};

    h4 {
      margin-bottom: ${convertToRem(10)};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      color: ${(props: IThemeProps) => props?.theme?.colors?.$LIGHT_BLACK};
    }
    .innerHeader {
      background-color: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      padding: 15px 8px;
      margin: 0;
    }
    .itemOverview {
      margin: 0 10px;
    }
  }
  ._topitemcard {
    margin-bottom: ${convertToRem(16)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    min-height: ${convertToRem(310)};
  }
  ._otherlinkcard {
    margin-bottom: ${convertToRem(16)};
    min-height: ${convertToRem(310)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.cardBg};
    .datalinkcard {
      margin-bottom: ${convertToRem(26)};
      margin-top: ${convertToRem(26)};
      a {
        margin-left: ${convertToRem(16)};
      }
    }
  }
`;
const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 !important;
  }
`;

const PDFDownloadLinkWrapper = styled(PDFDownloadLink)`
  padding: ${convertToRem(9)} ${convertToRem(14)} !important;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
  color: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagColor};
  font-size: ${convertToRem(14)};
  font-weight: 400;

  &:hover {
    background: ${(props: IThemeProps) => props?.theme?.colors?.$PRIMARY};
    color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
  }
`;
