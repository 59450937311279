import React, { FC } from 'react';
import styled from 'styled-components';
import { Heading, PurchaseOrder } from '@components';
import { PurchaseOrderList } from './PurchaseOrderList';

export const PurchaseOrderContainer: FC = () => {
  return (
    <WrapperPurchaseOrderContainer>
      <div className="flex alignCenter mb-5">
        <i>
          <PurchaseOrder />
        </i>
        <Heading type="table">Purchase Orders</Heading>
      </div>
      <Heading type="sub-heading">
        Your Business Symphony with Seamless Purchase Orders, Where
        Professionalism Sets the Stage.
      </Heading>
      <PurchaseOrderList />
    </WrapperPurchaseOrderContainer>
  );
};

export const WrapperPurchaseOrderContainer = styled.div``;
