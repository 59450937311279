import { IThemeProps } from './../../../../../hooks/useTheme/themeColors';
import styled from 'styled-components';

import convertToRem from '../../../../../utils/convertToRem';

interface WrapperPOForm {
  isToggle: boolean;
}

export const WrapperPurchaseOrderForm = styled.div<WrapperPOForm>`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  .add_purcahseitem > button {
    color: #7988ff !important;
    margin: ${convertToRem(18)};
    border: 1px solid #7988ff;
    &:hover {
      border: 1px solid #7988ff;
    }
  }
  .ant-card-body {
    padding: 0 0 20px 0 !important ;
  }
  .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ref_header {
    padding: ${convertToRem(3)} ${convertToRem(10)};
    border-bottom: ${convertToRem(0)};
    box-sizing: border-box;
    border-radius: ${convertToRem(5)} ${convertToRem(5)} 0 0;
  }

  .custom_col {
    padding: 0 ${convertToRem(6)} !important;
  }

  .ant-form-item-explain {
    position: absolute;
    right: 0;
    top: -${convertToRem(22)};
  }

  .ant-form-item {
    margin-bottom: ${convertToRem(13)};
  }

  .quick_access {
    position: relative;
    top: ${convertToRem(2)};
  }
  .quick_access_head {
    font-style: normal;
    font-weight: normal;
    font-size: ${convertToRem(16)};
    line-height: ${convertToRem(19)};
    letter-spacing: 0.04em;
    color: #2e2d2d;
    padding: ${convertToRem(13)} 0;
  }
  .quick_item_wrapper {
    border: ${convertToRem(1)} solid
      ${(props: IThemeProps) => props?.theme?.colors?.seprator};
    box-sizing: border-box;
    border-radius: ${convertToRem(6)};
    min-height: ${convertToRem(378)};
    background-color: ${(props: IThemeProps) =>
      props?.theme?.colors?.sidebarBg};
    padding: ${convertToRem(2)} 0;
  }

  .file_drop_zone {
    margin: ${convertToRem(20)};
  }
  .actions_control {
    margin: 0 !important;
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }

  p {
    margin-bottom: 0;
    color: ${(props: IThemeProps) => props?.theme?.colors?.sidebarDefaultText};
  }

  .actions {
    padding: ${convertToRem(10)} ${convertToRem(10)} ${convertToRem(10)}
      ${convertToRem(10)};
    background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
  }

  .actions.fixed-actions {
    position: fixed;
    width: ${(props) =>
      props.isToggle ? `calc(100% - 258px)` : `calc(100% - 112px)`};
    bottom: 2px;
    z-index: 1000;
  }

  ._col_border_lft {
    border-left: 1px solid #e4e4e4;
  }
  ._custom_select {
    background: ${(props: IThemeProps) =>
      props?.theme?.colors?.layoutBg} !important;
    .ant-select-selector {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.layoutBg} !important;
    }
  }
`;
