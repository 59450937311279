import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
} from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';

interface IProps {
  props?: any;
}

export const GeneralLedgerDetailList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const columns = [
    'Date',
    'Transaction_Type',
    'No',
    'Name',
    'Memo',
    'Debit',
    'Credit',
    'Balance',
  ];

  const GeneralDetail = [
    {
      title: 'Account Recivable',
      date: '01 Jun 2023',
      transaction_type: 'Invoice',
      no: '001',
      name: 'Hameed & Co',
      memo: '',
      debit: 20000,
      credit: '',
      balance: '20000 Dr',
    },
    {
      date: '03 Jun 2023',
      transaction_type: 'Invoice',
      no: '002',
      name: 'XYZ textiles',
      memo: '',
      debit: 15000,
      credit: '',
      balance: '35000 Dr',
    },
    {
      date: '04 Jun 2023',
      transaction_type: 'Customer payment',
      no: '001',
      name: 'Hameed & Co',
      memo: '',
      debit: '',
      credit: '2000',
      balance: '33000 Dr',
    },
    {
      date: '06 Jun 2023',
      transaction_type: 'Invoice',
      no: '001',
      name: 'Hameed & Co',
      memo: '',
      debit: '20000',
      credit: '',
      balance: '20000 Dr',
    },
    {
      date: '01 Jun 2023',
      transaction_type: 'Invoice',
      no: '003',
      name: 'Shabana',
      memo: '',
      debit: '17500',
      credit: '',
      balance: '50500 Dr',
    },
    {
      date: '08 Jun 2023',
      transaction_type: 'Customer payment',
      no: '003',
      name: 'Shabana',
      memo: '',
      debit: '',
      credit: '17500',
      balance: '33000 Dr',
    },
    {
      total: true,
      date: 'As on 30 Jan 2023',
      transaction_type: 'Customer payment',
      no: '',
      name: '',
      memo: '',
      debit: '52500 Dr',
      credit: '19500 Cr',
      balance: '33000 Dr',
    },
    {
      title: 'Accounts Payable',
      date: 'As on 1 Jan 2023',
      transaction_type: 'Opening Balance',
      no: '',
      name: '',
      memo: '',
      debit: '',
      credit: '50000',
      balance: '50000 Dr',
    },
    {
      date: '01 Jan 2023',
      transaction_type: 'Bill paymment',
      no: '001',
      name: 'Hayat motors',
      memo: '',
      debit: '16000',
      credit: '',
      balance: '34000 Cr',
    },
    {
      date: '03 Jan 2023',
      transaction_type: 'Bill',
      no: '002',
      name: 'Ikram Uddin',
      memo: '',
      debit: '10000',
      credit: '',
      balance: '44000 Cr',
    },
    {
      date: '07 Jan 2023',
      transaction_type: 'Bill',
      no: '002',
      name: 'Seema Alam',
      memo: '',
      debit: '',
      credit: '25000',
      balance: '69000 Cr',
    },
    {
      date: '10 Jan 2023',
      transaction_type: 'Bill paymment',
      no: '003',
      name: 'Seema Alam',
      memo: '',
      debit: '25000',
      credit: '',
      balance: '44000 Cr',
    },
    {
      total: true,
      date: 'As on 11 Jan 2023',
      transaction_type: 'Closing Balance',
      no: '',
      name: '',
      memo: '',
      debit: 41000,
      credit: 85000,
      balance: '44000 Cr',
    },
  ];
  const removeUnderscores = (str: any) => {
    return str.replace(/_/g, ' ');
  };

  return (
    <WrapperGeneralLedgerDetail>
      <div className="flex alignCenter justifySpaceBetween pb-20">
        <div>
          <Heading type="form">General Ledger Detail</Heading>
        </div>
        <div className="_disable_print flex alignCenter">
          <ButtonTag
            className="mr-10"
            onClick={onPrint}
            title="Print"
            size="middle"
            icon={printIcon}
          />
          <ButtonTag
            className="mr-10"
            onClick={() => console.log('here')}
            title="Email"
            size="middle"
          />
          <SmartFilter
            formSchema={FilterSchema}
            onFilter={(query) => {
              setConfig({ query: query });
              history.push(
                `/app${ISupportedRoutes.GENERAL_LEDGER_DETAIL}?query=${query}`
              );
            }}
          />
        </div>
      </div>
      <div ref={printRef}>
        <PrintFormat>
          <>
            <div className="mb-30 _visibleOnPrint">
              <PrintHeaderFormat hasbackgroundColor={true}>
                <TableDivisions
                  divisions={[
                    {
                      element: <TopbarLogoWithDetails />,
                    },
                    {
                      element: <Addressbar />,
                    },
                  ]}
                />
              </PrintHeaderFormat>
            </div>
            <table className="table">
              <thead>
                <tr className="columns">
                  {columns.map((item) => {
                    return <th>{removeUnderscores(item)}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {GeneralDetail?.map((generalLedgerDetail, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {generalLedgerDetail?.total ? (
                        <tr className="totalRows" key={index}>
                          {columns.map((item) => {
                            return (
                              <td>
                                {generalLedgerDetail[item.toLocaleLowerCase()]}
                              </td>
                            );
                          })}
                        </tr>
                      ) : (
                        <>
                          {generalLedgerDetail.title ? (
                            <tr className="header">
                              <td colSpan={8}> {generalLedgerDetail.title} </td>
                            </tr>
                          ) : (
                            ''
                          )}
                          <tr className="rows" key={index}>
                            {columns.map((item) => {
                              return (
                                <td>
                                  {generalLedgerDetail[
                                    item.toLocaleLowerCase()
                                  ] || '-'}
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        </PrintFormat>
      </div>
    </WrapperGeneralLedgerDetail>
  );
};

const WrapperGeneralLedgerDetail = styled.div`
  ${({ theme }) => {
    return {
      marginTop: `${convertToRem(20)}`,
      padding: '1px',
      '& .buttons': {
        display: 'flex',
        justifyContent: 'end',
        gap: '10px',
        marginBottom: '20px',
      },
      '& .header': {
        background: '#ffff',
        padding: '16px',
        color: '#272727',
        width: '100%',
        fontSize: `${convertToRem(16)}`,
        fontWeight: 500,
      },
      '& .table': {
        width: '100%',

        '& thead': {
          background: '#F8F8F8',
          color: '#3E3E3C',
          fontSize: `${convertToRem(14)}`,
          lineHeight: 1.3,
          '& .columns': {
            border: `${convertToRem(1)} solid #E8E8E8`,
            textAlign: 'left',
            '& th': {
              fontWeight: 500,
              padding: `${convertToRem(16)}`,
            },
          },
        },
        '& tbody': {
          fontSize: `${convertToRem(13)}`,
          fontWeight: 400,
          lineHeight: 1.5,
          color: '#272727',
          '& .rows': {
            background: ' #ffff',
            border: `${convertToRem(1)} solid #E8E8E8`,
            '& td': {
              padding: `${convertToRem(16)}`,
            },
          },
          '& .header': {
            background: '#ffff',
            '& td': {
              padding: `${convertToRem(16)}`,
            },
          },
          '& .totalRows': {
            background: ' #F8F8F8',
            textAlign: 'left',
            border: `${convertToRem(1)} solid #E8E8E8`,
            fontWeight: 500,
            '& td': {
              padding: `${convertToRem(16)}`,
            },
          },
        },
      },
    };
  }}
`;
