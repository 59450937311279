import React, { FC } from 'react';
import { Heading, TableCard, BreadCrumbArea, DebitNotes } from '@components';
import { Breadcrumb } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import { DebitNoteEditor } from './Editor';
import { ISupportedRoutes } from '@invyce/shared/types';
import fileInvoice from '@iconify/icons-bx/bx-file';
import Icon from '@iconify/react';

export const DebitNoteEditorWidget: FC = () => {
  const history = useHistory();
  const { location } = history;
  const id =
    location?.pathname?.split(`app${ISupportedRoutes.ADD_DEBIT_NOTE}/`)[1] ||
    null;

  return (
    <>
      <div className="flex alignCenter justifySpaceBetween">
        <div>
          <div className="flex alignCenter">
            <i className="mr-12">
              <DebitNotes />
            </i>
            <Heading type="form">Debit Note</Heading>
          </div>

          <Heading className="pb-10" type="sub-heading">
            Crafting Clarity in Financial Transactions for Precise Accounting
            and Transparent Business Dealings.
          </Heading>
        </div>
        <BreadCrumbArea>
          <Breadcrumb separator=">">
            <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
            <Breadcrumb.Item>
              <Link to={`/app${ISupportedRoutes.DEBIT_NOTES}`}>
                Debit Notes
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Debit Note</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbArea>
      </div>

      <DebitNoteEditor id={id} type="DN" />
    </>
  );
};
