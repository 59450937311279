import React from 'react';
import { Col, Row } from 'antd';
import { AgedReceivableWrapper } from './styled';
import { AgedReceivableList } from './List';

export const AgedReceivableReport = () => {
  return (
    <AgedReceivableWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <AgedReceivableList />
        </Col>
      </Row>
    </AgedReceivableWrapper>
  );
};
