import { Button } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Heading, JournalEntries } from '@components';
import { TransactionsList } from './TransactionsList';

export const TransactionContainer: FC = () => {
  return (
    <WrapperTransactions>
      <div className="flex alignCenter justifySpaceBetween pb-5">
        <div className="flex alignCenter">
          <i className="pt-5">
            <JournalEntries />
          </i>
          <Heading type="table">Journal Entries</Heading>
        </div>
      </div>
      <Heading type="sub-heading">
        "Elevating Your Journal Entries with Precision and Professionalism, Your
        Gateway to Seamless Transactions."
      </Heading>
      <TransactionsList />
    </WrapperTransactions>
  );
};

const WrapperTransactions = styled.div``;
