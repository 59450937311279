import React, { FC } from 'react';
import { Card, Table, Tag, Select } from 'antd';
import styled from 'styled-components';
import { IThemeProps } from '@invyce/shared/invyce-theme';

const { Option } = Select;

interface StyledTagProps {
  status: 'Completed' | 'Draft';
}

// Styled components
const StyledCard = styled(Card)<IThemeProps>`
  background: ${(props) => props.theme.colors.sidebarBg};
  border-radius: 9px !important;
  .ant-card-head-title {
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : '#334d6e'} !important;
  }
  .ant-select-selection-item {
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : '#334d6e'} !important;
  }
`;

const StyledTable = styled(Table)`
  .ant-card-head-title {
  }
  th.ant-table-cell {
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : '#334d6e'} !important;
  }
  .ant-table-cell {
    background: ${(props) => props.theme.colors.sidebarBg} !important;
    border-right: none !important;
    &:hover {
      background: ${(props) => props.theme.colors.sidebarBg} !important;
    }
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0px !important;
    height: 1.6em;
    background-color: hsla(0, 0%, 100%, 0.08);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  .ant-table-tbody > tr > td {
    background: ${(props) => props.theme.colors.sidebarBg};
    &:hover {
      background: ${(props) => props.theme.colors.sidebarBg};
    }
    .ant-table-cell {
    }
  }
`;

const StyledTag = styled(Tag)<StyledTagProps>`
  &.tag_styling {
    background: ${(props) =>
      props.status === 'Completed'
        ? 'rgba(21, 175, 147,0.1)'
        : 'rgba(255, 132, 117, 0.1)'} !important;
    color: ${(props) =>
      props.status === 'Completed' ? '#15AF93' : '#FF8475'} !important;
    border: none !important;
    width: 90px;
    border-radius: 15px;
    text-align: center;
    padding: 4px 8px 4px 8px;
    justify-content: center;
    align-items: center;
  }
`;
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Item Name',
    dataIndex: 'itemName',
    key: 'itemName',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status: 'Completed' | 'Draft') => (
      <StyledTag status={status} className="tag_styling">
        {status}
      </StyledTag>
    ),
  },
];

const data = [
  {
    key: '1',
    id: '#12560',
    itemName: 'Milkpack',
    date: 'June 26, 2022',
    amount: '$129.00',
    status: 'Completed',
  },
  {
    key: '1',
    id: '#12560',
    itemName: 'Milkpack',
    date: 'June 26, 2022',
    amount: '$129.00',
    status: 'Draft',
  },
];

export const InvoiceHistory: FC = () => {
  return (
    <StyledCard
      title="Invoice History"
      extra={
        <div className="flex">
          <div className="_filter textRight">
            <Select
              size="large"
              showSearch
              style={{ width: '100%', padding: '6px', textAlign: 'right' }}
              placeholder="Select Time"
              optionFilterProp="children"
              defaultValue={'current_week'}
            >
              <Option value="today">Invoices</Option>
              <Option value="current_week">This Week</Option>
              <Option value="current_month">This Month</Option>
              <Option value="current_year">This Year</Option>
            </Select>
          </div>
          <div className="_filter textRight">
            <Select
              size="large"
              showSearch
              style={{ width: '100%', padding: '6px', textAlign: 'right' }}
              placeholder="Select Time"
              optionFilterProp="children"
              defaultValue={'current_week'}
            >
              <Option value="today">last 7 days</Option>
              <Option value="current_week">This Week</Option>
              <Option value="current_month">This Month</Option>
              <Option value="current_year">This Year</Option>
            </Select>
          </div>
        </div>
      }
    >
      <StyledTable columns={columns} dataSource={data} pagination={false} />
    </StyledCard>
  );
};

export default InvoiceHistory;
