import React from 'react';
import { Col, Row } from 'antd';
import { AgedPayableWrapper } from './styled';
import { AgedPayableList } from './List';

export const AgedPayableReport = () => {
  return (
    <AgedPayableWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <AgedPayableList />
        </Col>
      </Row>
    </AgedPayableWrapper>
  );
};
