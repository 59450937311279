import React from 'react';
import { Col, Row } from 'antd';
import { AgedReceivableSummaryWrapper } from './styled';
import { AgedReceivableSummaryList } from './List';

export const AgedReceivableSummaryReport = () => {
  return (
    <AgedReceivableSummaryWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <AgedReceivableSummaryList />
        </Col>
      </Row>
    </AgedReceivableSummaryWrapper>
  );
};
