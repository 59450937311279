import { QueryKey } from '@invyce/shared/types';
import http from '../utils/http';

export const getTribalanceReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `account/trail-balance`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

export const getBalaceSheetReport = ({ queryKey }: QueryKey) => {
  const query = queryKey[1];
  let URL = `account/balance-sheet`;
  if (query) {
    URL = `${URL}?query=${query}`;
  }
  return http?.get(URL);
};

// export const getAgedReceivableReport = ({ queryKey }: QueryKey) => {
//   const query = queryKey[1];
//   let URL = `reports/invoice/aged-payable-detail`;
//   if (query) {
//     URL = `${URL}?query=${query}`;
//   }
//   return http?.get(URL);
// };

// export const getAgedPayableReport = ({ queryKey }: QueryKey) => {
//   const query = queryKey[1];
//   let URL = `reports/invoice/aged-payables`;
//   if (query) {
//     URL = `${URL}?query=${query}`;
//   }
//   return http?.get(URL);
// };
