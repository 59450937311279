import { Heading, P } from '@components';
import convertToRem from 'apps/main-ui/src/utils/convertToRem';
import * as React from 'react';
import styled from 'styled-components';
import { IThemeProps } from '../../../hooks/useTheme/themeColors';
import cashSummaryRecent from '../../../assets/cashSummaryRecent.png';

export const RecentReport = () => {
  const recentList = [
    {
      title: 'Cash Summery Report',
      src: cashSummaryRecent,
    },
    {
      title: 'Cash Summery Report',
      src: cashSummaryRecent,
    },
    {
      title: 'Cash Summery Report',
      src: cashSummaryRecent,
    },
    {
      title: 'Cash Summery Report',
      src: cashSummaryRecent,
    },
  ];

  return (
    <WrapperRecentReport>
      <Heading type="form" className="pv-26">
        Recent Report’s
      </Heading>
      <div className="container">
        {recentList?.map((item, index) => (
          <div className="context_area" key={index}>
            <div className="report_list">
              <img src={item?.src} alt={`Report ${index + 1}`} />
              <Overlay>
                <p>View Report</p>
              </Overlay>
            </div>
            <p className="mv-6">{item?.title}</p>
          </div>
        ))}
      </div>
    </WrapperRecentReport>
  );
};

const WrapperRecentReport = styled.div`
  padding: ${convertToRem(30)};
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .context_area {
      position: relative; /* Needed for positioning the overlay */
      flex-direction: column;
      text-align: start;
    }

    .report_list {
      border-radius: 8px;
      position: relative; /* Needed for positioning the overlay */
      text-align: center;
      padding: ${convertToRem(10)};
      background: ${(props) => props.theme.colors.listTableHeader} !important;
      overflow: hidden; /* Hide overflow of the overlay */

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-bottom: ${convertToRem(10)};
      }
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 19, 19, 0.75);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;

  p {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  .report_list:hover & {
    opacity: 1;
  }
`;
