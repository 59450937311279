import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { StockInventoryManagementWrapper } from './styled';
import { StockInventoryManagementList } from './List';

export const StockInventoryManagement: FC = () => {
  return (
    <StockInventoryManagementWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <StockInventoryManagementList />
        </Col>
      </Row>
    </StockInventoryManagementWrapper>
  );
};
