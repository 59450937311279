export const InyvceDarkTextIcon = () => {
  return (
    <svg
      width="114"
      height="26"
      viewBox="0 0 114 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8534 0.169251L20.6827 4.69872C20.8481 4.77424 20.9878 4.89579 21.0847 5.04855C21.1817 5.20132 21.2317 5.37872 21.2288 5.55916L20.9803 19.6644C20.9778 19.8201 20.936 19.9727 20.8587 20.1084C20.7814 20.244 20.6711 20.3583 20.5379 20.4408C20.4046 20.5234 20.2526 20.5716 20.0957 20.5811C19.9389 20.5906 19.7821 20.561 19.6397 20.4951L10.5721 16.3119C10.3151 16.1929 10.0342 16.133 9.75051 16.1367C9.46681 16.1405 9.18765 16.2078 8.9339 16.3336L1.3653 20.0567C1.221 20.1295 1.06015 20.1641 0.898375 20.1571C0.736602 20.1501 0.579397 20.1018 0.442022 20.0169C0.304646 19.9319 0.191764 19.8132 0.114336 19.6723C0.0369075 19.5313 -0.00243792 19.373 0.000116945 19.2125L0.24312 5.16682C0.247175 4.99728 0.297897 4.83205 0.389807 4.68899C0.481716 4.54593 0.611322 4.43047 0.764623 4.35509L9.2124 0.204426C9.46552 0.0762663 9.74463 0.00652828 10.0288 0.000436664C10.313 -0.00565495 10.5949 0.0520575 10.8534 0.169251Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M16.6745 9.65708L24.2513 5.93123C24.3964 5.85973 24.5576 5.82643 24.7194 5.83452C24.8813 5.84261 25.0383 5.89182 25.1754 5.97742C25.3125 6.06302 25.4251 6.18213 25.5023 6.32331C25.5796 6.46448 25.6189 6.62297 25.6164 6.78355L25.3625 20.8401C25.3585 21.0096 25.3077 21.1748 25.2158 21.3179C25.1239 21.461 24.9943 21.5764 24.841 21.6518L16.3769 25.8079C16.1266 25.931 15.8516 25.9966 15.5722 25.9999C15.2928 26.0031 15.0163 25.944 14.7632 25.8268L4.93386 21.2946C4.76867 21.2181 4.62928 21.0959 4.53242 20.9428C4.43555 20.7897 4.38533 20.6122 4.38778 20.4315L4.63897 6.32356C4.64171 6.16819 4.68355 6.01596 4.76068 5.88067C4.83781 5.74538 4.94781 5.63131 5.08068 5.54881C5.21356 5.46631 5.36512 5.41798 5.52161 5.40822C5.67811 5.39845 5.83459 5.42756 5.97686 5.49289L15.0554 9.67873C15.3096 9.79577 15.5871 9.85459 15.8674 9.85084C16.1477 9.8471 16.4235 9.78087 16.6745 9.65708Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M31.3115 5.32451C31.3156 5.20908 31.3639 5.09954 31.4466 5.01813C31.5292 4.93672 31.6401 4.88953 31.7566 4.88617H34.5607C34.6772 4.88953 34.788 4.93672 34.8707 5.01813C34.9533 5.09954 35.0016 5.20908 35.0057 5.32451V20.6636C35.0023 20.7795 34.9543 20.8897 34.8716 20.9717C34.7888 21.0536 34.6776 21.1012 34.5607 21.1046H31.7566C31.6396 21.1012 31.5284 21.0536 31.4457 20.9717C31.3629 20.8897 31.315 20.7795 31.3115 20.6636V5.32451Z"
        fill="#0B0B0B"
      />
      <path
        d="M39.2373 5.07069C39.2423 4.958 39.2911 4.8516 39.3736 4.77389C39.4561 4.69617 39.5658 4.6532 39.6796 4.654H40.2639L49.5936 13.5506H49.6155V5.32504C49.6162 5.20853 49.6634 5.09705 49.7468 5.01492C49.8302 4.93279 49.9429 4.8867 50.0605 4.8867H52.8673C52.9833 4.89074 53.0935 4.93822 53.1756 5.01956C53.2577 5.10091 53.3056 5.21007 53.3097 5.32504V20.9184C53.3054 21.0314 53.2567 21.1382 53.1741 21.216C53.0914 21.2939 52.9814 21.3366 52.8673 21.3351H52.4933C52.3831 21.3223 52.278 21.2823 52.1875 21.2188L42.9533 12.0191H42.9315V20.6614C42.9315 20.7784 42.8846 20.8905 42.8011 20.9733C42.7177 21.056 42.6045 21.1024 42.4864 21.1024H39.7042C39.5872 21.099 39.476 21.0515 39.3933 20.9695C39.3106 20.8875 39.2626 20.7773 39.2591 20.6614L39.2373 5.07069Z"
        fill="#0B0B0B"
      />
      <path
        d="M55.4614 5.48856C55.4253 5.42401 55.4077 5.35093 55.4104 5.2772C55.4132 5.20347 55.4361 5.13186 55.4768 5.07009C55.5175 5.00832 55.5745 4.95871 55.6415 4.92661C55.7085 4.8945 55.7831 4.88111 55.8573 4.88788H58.9754C59.0587 4.89086 59.1398 4.91605 59.2099 4.96081C59.2801 5.00557 59.3368 5.06824 59.374 5.14222L63.2293 13.5978H63.3713L67.2211 5.14493C67.2574 5.07079 67.3135 5.0079 67.3833 4.96307C67.4531 4.91824 67.5339 4.89317 67.617 4.89059H70.716C70.7901 4.88382 70.8647 4.89721 70.9318 4.92931C70.9988 4.96142 71.0557 5.01102 71.0964 5.0728C71.1371 5.13457 71.1601 5.20617 71.1628 5.27991C71.1655 5.35364 71.1479 5.42671 71.1119 5.49127L63.7836 21.082C63.7478 21.1571 63.6915 21.2207 63.621 21.2657C63.5505 21.3107 63.4687 21.3351 63.3849 21.3363H63.1528C63.069 21.3351 62.9872 21.3107 62.9168 21.2657C62.8463 21.2207 62.79 21.1571 62.7542 21.082L55.4614 5.48856Z"
        fill="#0B0B0B"
      />
      <path
        d="M76.7577 13.2962L71.5918 5.55765C71.5459 5.49217 71.5193 5.41525 71.5152 5.33561C71.5111 5.25597 71.5295 5.17677 71.5684 5.10696C71.6073 5.03716 71.6652 4.97953 71.7354 4.94059C71.8056 4.90165 71.8855 4.88296 71.9659 4.88662H75.0048C75.0795 4.88873 75.1526 4.90879 75.2177 4.94508C75.2829 4.98138 75.3382 5.03281 75.3788 5.09497L78.6362 9.84361L81.888 5.09497C81.9264 5.03073 81.9812 4.97765 82.0469 4.94107C82.1125 4.9045 82.1868 4.88571 82.2621 4.88662H85.301C85.3814 4.88296 85.4612 4.90165 85.5314 4.94059C85.6017 4.97953 85.6595 5.03716 85.6984 5.10696C85.7373 5.17677 85.7558 5.25597 85.7516 5.33561C85.7475 5.41525 85.721 5.49217 85.6751 5.55765L80.43 13.261V20.6532C80.4266 20.7691 80.3786 20.8793 80.2959 20.9613C80.2131 21.0433 80.1019 21.0908 79.985 21.0942H77.2027C77.0847 21.0942 76.9715 21.0478 76.888 20.9651C76.8046 20.8823 76.7577 20.7702 76.7577 20.6532V13.2962Z"
        fill="#0B0B0B"
      />
      <path
        d="M94.7109 4.65193C95.7516 4.62352 96.7876 4.80024 97.7587 5.17183C98.7299 5.54342 99.6169 6.10248 100.368 6.81655C100.416 6.85542 100.456 6.90399 100.483 6.95903C100.511 7.01406 100.526 7.0743 100.529 7.13574C100.531 7.19718 100.52 7.2584 100.497 7.31533C100.473 7.37227 100.438 7.42361 100.393 7.46594L98.5662 9.33292C98.5281 9.37137 98.4826 9.40192 98.4325 9.42276C98.3823 9.44361 98.3285 9.45435 98.2741 9.45435C98.2197 9.45435 98.1658 9.44361 98.1157 9.42276C98.0655 9.40192 98.02 9.37137 97.9819 9.33292C97.1103 8.57085 95.988 8.15038 94.8256 8.1505C92.1826 8.1505 90.244 10.3151 90.244 12.9235C90.244 15.5319 92.2072 17.6261 94.8502 17.6261C95.9945 17.6243 97.1025 17.2283 97.9847 16.5059C98.072 16.4365 98.1818 16.4008 98.2937 16.4052C98.4056 16.4097 98.5121 16.4541 98.5935 16.5303L100.415 18.4514C100.491 18.5398 100.531 18.6533 100.527 18.7695C100.523 18.8856 100.475 18.9961 100.393 19.0791C98.8724 20.5418 96.8298 21.3491 94.7109 21.3249C93.5929 21.3449 92.4821 21.1439 91.4434 20.7337C90.4046 20.3235 89.4587 19.7123 88.6609 18.9359C87.8632 18.1594 87.2295 17.2332 86.7969 16.2114C86.3644 15.1895 86.1416 14.0925 86.1416 12.9844C86.1416 11.8763 86.3644 10.7792 86.7969 9.75739C87.2295 8.73554 87.8632 7.80934 88.6609 7.03287C89.4587 6.2564 90.4046 5.64522 91.4434 5.23503C92.4821 4.82484 93.5929 4.62385 94.7109 4.64381V4.65193Z"
        fill="#0B0B0B"
      />
      <path
        d="M103.431 5.32451C103.431 5.208 103.479 5.09652 103.562 5.01439C103.645 4.93226 103.758 4.88617 103.876 4.88617H113.555C113.672 4.88617 113.785 4.93226 113.869 5.01439C113.952 5.09652 113.999 5.208 114 5.32451V7.849C113.999 7.9655 113.952 8.07699 113.869 8.15911C113.785 8.24124 113.672 8.28734 113.555 8.28733H107.078V11.1636H112.408C112.525 11.167 112.636 11.2145 112.719 11.2965C112.802 11.3785 112.85 11.4887 112.853 11.6046V14.1291C112.853 14.2461 112.806 14.3583 112.723 14.441C112.639 14.5237 112.526 14.5702 112.408 14.5702H107.078V17.698H113.555C113.673 17.698 113.786 17.7445 113.87 17.8272C113.953 17.9099 114 18.0221 114 18.1391V20.6636C114 20.7805 113.953 20.8927 113.87 20.9754C113.786 21.0581 113.673 21.1046 113.555 21.1046H103.876C103.758 21.1046 103.644 21.0581 103.561 20.9754C103.478 20.8927 103.431 20.7805 103.431 20.6636V5.32451Z"
        fill="#0B0B0B"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-1.25039"
          y1="10.2889"
          x2="20.2923"
          y2="10.2889"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.44" stop-color="#F8D04A" />
          <stop offset="0.48" stop-color="#F4CA48" />
          <stop offset="0.61" stop-color="#E9BB44" />
          <stop offset="0.77" stop-color="#E2B242" />
          <stop offset="1" stop-color="#E0AF41" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5.50723"
          y1="15.7018"
          x2="27.0499"
          y2="15.7018"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.45" stop-color="#D3702D" />
          <stop offset="0.48" stop-color="#DA752F" />
          <stop offset="0.56" stop-color="#E57E34" />
          <stop offset="0.68" stop-color="#EC8336" />
          <stop offset="1" stop-color="#EE8437" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const AppLogoWithoutText = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1685 0.156231L19.3777 4.33728C19.5326 4.407 19.6635 4.51919 19.7543 4.6602C19.8451 4.80122 19.892 4.96497 19.8893 5.13153L19.6565 18.1517C19.6541 18.2955 19.6149 18.4364 19.5425 18.5616C19.4701 18.6867 19.3668 18.7923 19.2419 18.8685C19.1171 18.9447 18.9747 18.9892 18.8277 18.9979C18.6807 19.0067 18.5339 18.9794 18.4005 18.9185L9.90504 15.0572C9.66422 14.9473 9.40107 14.892 9.13527 14.8954C8.86947 14.8989 8.60792 14.961 8.37019 15.0771L1.27916 18.5139C1.14395 18.5811 0.993255 18.613 0.841689 18.6066C0.690123 18.6001 0.542838 18.5555 0.414131 18.4771C0.285424 18.3987 0.179664 18.2891 0.107122 18.159C0.0345787 18.0289 -0.00228409 17.8827 0.000109566 17.7346L0.22778 4.76938C0.231578 4.61287 0.2791 4.46035 0.36521 4.3283C0.451321 4.19624 0.572748 4.08967 0.716376 4.02008L8.63111 0.188701C8.86826 0.0703997 9.12976 0.00602611 9.39601 0.000403075C9.66226 -0.00521996 9.92636 0.0480531 10.1685 0.156231Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M15.6218 8.91421L22.7206 5.47496C22.8565 5.40896 23.0075 5.37822 23.1592 5.38569C23.3108 5.39316 23.4579 5.43858 23.5864 5.5176C23.7148 5.59661 23.8203 5.70656 23.8927 5.83688C23.9651 5.9672 24.0019 6.1135 23.9996 6.26172L23.7617 19.237C23.7579 19.3935 23.7104 19.546 23.6243 19.678C23.5382 19.8101 23.4167 19.9167 23.2731 19.9863L15.343 23.8226C15.1086 23.9363 14.8509 23.9968 14.5891 23.9999C14.3274 24.0029 14.0683 23.9483 13.8312 23.8401L4.62205 19.6566C4.46729 19.5859 4.33669 19.4732 4.24594 19.3318C4.15519 19.1905 4.10814 19.0266 4.11043 18.8598L4.34578 5.83712C4.34834 5.6937 4.38753 5.55318 4.4598 5.42829C4.53207 5.30341 4.63512 5.19812 4.75961 5.12196C4.88411 5.0458 5.0261 5.0012 5.17272 4.99218C5.31934 4.98317 5.46595 5.01003 5.59924 5.07034L14.1049 8.93419C14.3431 9.04223 14.6031 9.09653 14.8657 9.09307C15.1283 9.08961 15.3867 9.02848 15.6218 8.91421Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-1.1715"
          y1="9.49741"
          x2="19.0119"
          y2="9.49741"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.44" stop-color="#F8D04A" />
          <stop offset="0.48" stop-color="#F4CA48" />
          <stop offset="0.61" stop-color="#E9BB44" />
          <stop offset="0.77" stop-color="#E2B242" />
          <stop offset="1" stop-color="#E0AF41" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5.15925"
          y1="14.4939"
          x2="25.3426"
          y2="14.4939"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.45" stop-color="#D3702D" />
          <stop offset="0.48" stop-color="#DA752F" />
          <stop offset="0.56" stop-color="#E57E34" />
          <stop offset="0.68" stop-color="#EC8336" />
          <stop offset="1" stop-color="#EE8437" />
        </linearGradient>
      </defs>
    </svg>
  );
};
