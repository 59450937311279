import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { ButtonTag, Heading } from '@components';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import addIcon from '@iconify/icons-carbon/add';
import Icon from '@iconify/react';

export const AccountCategorizationList = () => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;

  const removeUnderscores = (str) => {
    return str.replace(/_/g, ' ');
  };

  const columns = ['Code_Number', 'Account_Name', 'Description'];

  const result = [
    {
      code_number: '00123',
      account_name: 'Cash in hand',
      description:
        'Add item for invoice create new code number for in voice and stock items...',
    },
    {
      code_number: '00134',
      account_name: 'Sales',
      description: 'Add item for invoice create new',
    },
    {
      code_number: '00145',
      account_name: 'Payable',
      description:
        'Add item for invoice create new code number for in voice and stock items invoice create',
    },
    {
      code_number: '00187',
      account_name: 'Cash',
      description: 'Add item for invoice',
    },
    {
      code_number: '00156',
      account_name: 'Account',
      description: 'Add item for invoice create new code number',
    },
  ];

  return (
    <WrapperAccountCategorization>
      <div className="flex alignCenter justifySpaceBetween pb-20">
        <div>
          <Heading type="form">Account categorization</Heading>
        </div>
        <div className="_disable_print flex alignCenter">
          <Link to={`/app${ISupportedRoutes.ADD_GROUP}`}>
            <ButtonTag
              type="primary"
              size="middle"
              title=" Add Group"
              icon={addIcon}
              className="flex alignCenter"
            />
          </Link>
        </div>
      </div>
      <Card className="account-categorization">
        <table className="table">
          <thead>
            <tr className="columns">
              {columns.map((itemHeader, index) => {
                return <th key={index}>{removeUnderscores(itemHeader)}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {result?.map((accountCateorization, index) => {
              return (
                <tr className="rows" key={index}>
                  {columns.map((item, columnIndex) => {
                    return (
                      <td
                        key={columnIndex}
                        className={
                          columnIndex === 2 ? 'contact_field' : 'rest_field'
                        }
                      >
                        {columnIndex === 1 ? (
                          <Link to={`/app${ISupportedRoutes.ACCOUNTS}`}>
                            {accountCateorization[item.toLocaleLowerCase()]}
                          </Link>
                        ) : (
                          accountCateorization[item.toLocaleLowerCase()] || '-'
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </WrapperAccountCategorization>
  );
};

export const WrapperAccountCategorization = styled.div`
  ${({ theme }) => {
    return {
      marginTop: `${convertToRem(20)}`,
      padding: '1px',
      '& .buttons': {
        display: 'flex',
        justifyContent: 'end',
        gap: '10px',
        marginBottom: '20px',
      },
      '& .account-categorization': {
        minHeight: '100vh',
        '& .ant-card-body': {
          padding: '0',
        },
      },
      '& .table': {
        width: '100%',
        '& thead': {
          background: theme?.colors.calulatedAreaBg,
          color: theme?.colors?.tableHeaderTextColor,
          fontSize: `${convertToRem(14)}`,
          '& .columns': {
            textAlign: 'left',
            borderLeft: `${convertToRem(1)} solid ${
              theme?.colors?.tableBorderColor
            }`,
            borderRight: `${convertToRem(1)} solid ${
              theme?.colors?.tableBorderColor
            }`,
            '& th': {
              padding: `${convertToRem(16)} ${convertToRem(20)}`,
              fontWeight: 500,
              borderTop: `${convertToRem(1)} solid ${
                theme?.colors?.tableBorderColor
              }`,
              borderBottom: `${convertToRem(1)} solid ${
                theme?.colors?.tableBorderColor
              }`,
            },
          },
        },
        '& tbody': {
          fontSize: `${convertToRem(13)}`,
          color: theme?.colors?.tableBodyTextColor,
          '& .rows': {
            background: theme?.colors?.tableBodyColor,
            textAlign: 'left',
            borderLeft: `${convertToRem(1)} solid ${
              theme?.colors?.tableBorderColor
            }`,
            borderRight: `${convertToRem(1)} solid ${
              theme?.colors?.tableBorderColor
            }`,
            '& td': {
              padding: `${convertToRem(16)} ${convertToRem(20)}`,
              borderTop: `${convertToRem(1)} solid ${
                theme?.colors?.tableBorderColor
              }`,
              borderBottom: `${convertToRem(1)} solid ${
                theme?.colors?.tableBorderColor
              }`,

              '& a': {
                color: '#7988FF',
              },
            },
            '& .rest_field': {
              width: '8%',
            },
            '& .contact_field': {
              width: '28%',
            },
          },
          '& .totalRows': {
            background: theme?.colors.calulatedAreaBg,
            textAlign: 'left',
            fontWeight: 500,
            '& td': {
              padding: `${convertToRem(16)}`,
            },
          },
        },
      },
    };
  }}
`;
