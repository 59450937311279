import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  BreadCrumbArea,
  Heading,
  PurchaseOrder,
  PurchasesView,
  TableCard,
} from '@components';
import { useGlobalContext } from '../../../../../hooks/globalContext/globalContext';
import { IInvoiceType, ISupportedRoutes } from '@invyce/shared/types';
import Icon from '@iconify/react';
import fileInvoice from '@iconify/icons-bx/bx-file';

export const PurchaseView = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const invId = pathname.split(
    `${ISupportedRoutes.DASHBOARD_LAYOUT}${ISupportedRoutes?.PURCHASE_ORDER}/`
  )[1];

  return (
    <>
      <div className="flex alignCenter justifySpaceBetween">
        <div>
          <div className="flex alignCenter mb-5">
            <i>
              <PurchaseOrder />
            </i>
            <Heading type="table">Purchase Orders</Heading>
          </div>
          <Heading type="sub-heading">
            Your Business Symphony with Seamless Purchase Orders, Where
            Professionalism Sets the Stage.
          </Heading>
        </div>
        <BreadCrumbArea>
          <Breadcrumb separator=">">
            <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
            <Breadcrumb.Item>
              <Link to={`/app${ISupportedRoutes.PURCHASE_ORDER}`}>
                Purchase Orders
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Purchase Order View</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbArea>
      </div>

      <TableCard>
        <PurchasesView type={IInvoiceType.PURCHASE_ORDER} id={invId} />
      </TableCard>
    </>
  );
};
