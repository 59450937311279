import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { TrackingSummaryContainer } from './styled';
import { TrackingSummaryList } from './List';

export const TrackingSummary: FC = () => {
  return (
    <TrackingSummaryContainer>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <TrackingSummaryList />
        </Col>
      </Row>
    </TrackingSummaryContainer>
  );
};
