import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { BreadCrumbArea } from '../../../../components/BreadCrumbArea';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { IInvoiceType, ISupportedRoutes } from '@invyce/shared/types';
import { CreditNotes, Heading, PurchasesView } from '@components';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import Icon from '@iconify/react';
import fileInvoice from '@iconify/icons-bx/bx-file';

export const CreditNoteView: FC = () => {
  const { routeHistory } = useGlobalContext();
  const { pathname } = routeHistory.location;
  const invId = pathname.split('app/credit-notes/')[1];
  return (
    <WrapperCreditNoteView>
      <div className="flex alignCenter justifySpaceBetween">
        <div>
          <div className="flex alignCenter mb-5">
            <i>
              <CreditNotes />
            </i>
            <Heading type="table">Credit Notes View</Heading>
          </div>
          <Heading type="sub-heading">
            Elevating Your Business with Precision and Professionalism - Your
            Gateway to Seamless Credit Transactions.
          </Heading>
        </div>
        <BreadCrumbArea>
          <Breadcrumb separator=">">
            <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
            <Breadcrumb.Item>
              <Link to={`/app${ISupportedRoutes.CREDIT_NOTES}`}>
                Credit Notes
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Credit Note View</Breadcrumb.Item>
          </Breadcrumb>
        </BreadCrumbArea>
      </div>

      <PurchasesView type={IInvoiceType.CREDITNOTE} id={invId} />
    </WrapperCreditNoteView>
  );
};
const WrapperCreditNoteView = styled.div``;
