import React, { FC } from 'react';
import { ButtonTag } from '@components';
import importIcon from '@iconify-icons/uil/file-import';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';

const ItemsImport = () => {
  const { setItemsImportconfig, itemsImportconfig } = useGlobalContext();

  return (
    <ButtonTag
      onClick={() => {
        setItemsImportconfig(true, 'items');
      }}
      className="ml-5"
      title="Import"
      size="middle"
      icon={importIcon}
      type="ghost"
    />
  );
};

export default ItemsImport;
