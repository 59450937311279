import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import deleteIcon from '@iconify/icons-carbon/delete';
import editSolid from '@iconify/icons-clarity/edit-solid';
import Icon from '@iconify/react';
import { Button } from 'antd';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../../../hooks/globalContext/globalContext';
import { IThemeProps } from '../../../../../hooks/useTheme/themeColors';
import { ButtonTag } from '@components';

interface IProps {
  disabled?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onEmail?: () => void;
  onApprove?: () => void;
  isEditable?: boolean;
  renderSmartFilter?: ReactElement<any>;
  hasApproveButton?: boolean;
  isAbleToDelete?: boolean;
}

export const PurchaseTopbar: FC<IProps> = ({
  disabled,
  onDelete,
  onEdit,
  onEmail,
  onApprove,
  isEditable,
  renderSmartFilter,
  hasApproveButton,
  isAbleToDelete = true,
}) => {
  const { setPaymentsModalConfig } = useGlobalContext();
  return (
    <WrapperPurchaseTopbar className="flex alignCenter justifySpaceBetween">
      <div className="flex alignCenter _quick_access">
        {/* <Button className={"_customized_button"}>Submit for approvel</Button> */}
        {hasApproveButton && (
          <ButtonTag
            onClick={onApprove}
            disabled={disabled}
            className={`${!disabled ? '_customized_button' : ''}`}
            title="Approve"
            size="middle"
            type="ghost"
          />
        )}
        {isEditable && (
          <ButtonTag
            disabled={disabled}
            onClick={onEdit}
            className={` flex alignCenter ${
              !disabled ? '_customized_button' : ''
            }`}
            size="middle"
            title="Edit"
            icon={editSolid}
            type="ghost"
          />
        )}
        {isAbleToDelete && (
          <Button
            disabled={disabled}
            onClick={onDelete}
            className={` flex alignCenter  ${
              !disabled ? '_customized_button' : ''
            }`}
            size="middle"
            icon={deleteIcon}
            type="ghost"
            title="Delete"
          />
        )}
        <ButtonTag
          disabled={disabled}
          onClick={onEmail}
          className={`flex alignCenter ${
            !disabled ? '_customized_button' : ''
          }`}
          size="middle"
          title="Email"
          icon={baselineAlternateEmail}
          type="ghost"
        />
      </div>
      <div className={'_print flex alignCenter'}>
        <div className="filter_space">{renderSmartFilter}</div>
      </div>
    </WrapperPurchaseTopbar>
  );
};
const WrapperPurchaseTopbar = styled.div`
  padding: 10px 0;
  .filter_space {
    position: relative;
    top: -5px;
  }
  ._print {
    ._print_button {
      border: none;
    }
  }
`;
