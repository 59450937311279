import React, { FC, useState, useRef, Fragment, useEffect } from 'react';
import {
  ButtonTag,
  Heading,
  SmartFilter,
  PrintFormat,
  PrintHeaderFormat,
  TableDivisions,
  TopbarLogoWithDetails,
  Addressbar,
  Card,
  Para,
} from '@components';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import styled from 'styled-components';
import { Select } from 'antd';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

interface IProps {
  props?: any;
}

export const BudgetManagerList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const columns = [
    'Overall_Budget',
    'November',
    'October',
    'September',
    'August',
    'July',
    'June',
    'May',
  ];

  const trackSummaryData = [
    {
      title: 'Income',
      overall_budget: 'Interest income (200)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Other revenue (200)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Sales (200)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      total: true,
      overall_budget: 'Total Income',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      title: 'Less Cost Of Sales',
      overall_budget: 'less cost of good sales',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Total cost of sale',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      total: true,
      overall_budget: 'Gross profit',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      title: 'Less operation expenses',
      overall_budget: 'Advertising (400)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Bank Fees (404)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Bank Revaluations (497)',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Total Operating Expenses',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      overall_budget: 'Total Expenses',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
    {
      total: true,
      overall_budget: 'Net Profit',
      november: '0PKR',
      october: '0PKR',
      september: '0PKR',
      august: '0PKR',
      july: '0PKR',
      june: '0PKR',
      may: '0PKR',
    },
  ];

  const accountGroup = [
    {
      value: 'sales',
      label: 'Sales',
    },
  ];

  const trackingStartDate = [
    {
      value: 'jan_2024',
      label: 'jan 2024',
    },
    {
      value: 'dec_2023',
      label: 'Dec 2023',
    },
    {
      value: 'nov_2023',
      label: 'Nov 2023',
    },
    {
      value: 'oct_2023',
      label: 'Oct 2023',
    },
    {
      value: 'sep_2023',
      label: 'Sep 2023',
    },
    {
      value: 'aug_2023',
      label: 'Aug 2023',
    },
    {
      value: 'jul_2023',
      label: 'Jul 2023',
    },
  ];

  const periodCategory = [
    {
      value: '3_month',
      label: '3 Month',
    },
    {
      value: '6_month',
      label: '6 Month',
    },
    {
      value: '12_month',
      label: '12 Month',
    },
    {
      value: '24_month',
      label: '24 Month',
    },
  ];

  const handleAccountGroup = (value: {
    value: string;
    label: React.ReactNode;
  }) => {};
  const handleStartDateFilter = (value: {
    value: string;
    label: React.ReactNode;
  }) => {};

  const handlePeriodCaterogry = (value: {
    value: string;
    label: React.ReactNode;
  }) => {};

  const handleActualCaterogry = (value: {
    value: string;
    label: React.ReactNode;
  }) => {};

  const removeUnderscores = (str) => {
    return str.replace(/_/g, ' ');
  };

  const onEmail = async () => {};

  return (
    <WrapperBudgetManager>
      <FixedHeader
        src="/assets/budgetManager.png"
        title="Budget Manager"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(`/app${ISupportedRoutes.CASH_SUMMARY}?query=${query}`);
          }}
        />
      </FixedHeader>
      <Card className="card_wrapper">
        {trackSummaryData?.length > 0 ? (
          <>
            <div className="flex alignCenter justifySpaceBetween pb-20 select_area mb-20">
              <div>
                <Para type="label">Select budget</Para>
                <Select
                  labelInValue
                  placeholder="Actuals"
                  style={{ width: 181 }}
                  onChange={handleAccountGroup}
                  options={accountGroup}
                />
              </div>
              <div>
                <Para type="label">Start</Para>
                <Select
                  labelInValue
                  placeholder="Start"
                  style={{ width: 181 }}
                  onChange={handleStartDateFilter}
                  options={trackingStartDate}
                />
              </div>
              <div>
                <Para type="label">Actuals</Para>
                <Select
                  labelInValue
                  placeholder="Actuals"
                  style={{ width: 181 }}
                  onChange={handleActualCaterogry}
                  options={periodCategory}
                />
              </div>
              <div>
                <Para type="label">Period</Para>
                <Select
                  labelInValue
                  placeholder="Period"
                  style={{ width: 181 }}
                  onChange={handlePeriodCaterogry}
                  options={periodCategory}
                />
              </div>
            </div>
            <div ref={printRef}>
              <PrintFormat>
                <>
                  <div className="mb-30 _visibleOnPrint">
                    <PrintHeaderFormat hasbackgroundColor={true}>
                      <TableDivisions
                        divisions={[
                          {
                            element: <TopbarLogoWithDetails />,
                          },
                          {
                            element: <Addressbar />,
                          },
                        ]}
                      />
                    </PrintHeaderFormat>
                  </div>
                  <table className="table">
                    <thead>
                      <tr className="columns">
                        {columns.map((item) => {
                          return <th>{removeUnderscores(item)}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {trackSummaryData?.map((tracksummaryItem, index) => {
                        return (
                          <Fragment key={index}>
                            {tracksummaryItem?.total ? (
                              <tr className="totalRows">
                                {columns.map((item) => {
                                  return (
                                    <td>
                                      {
                                        tracksummaryItem[
                                          item.toLocaleLowerCase()
                                        ]
                                      }
                                    </td>
                                  );
                                })}
                              </tr>
                            ) : (
                              <>
                                {tracksummaryItem.title ? (
                                  <tr className="rows title">
                                    <td>{tracksummaryItem.title}</td>
                                  </tr>
                                ) : (
                                  ''
                                )}

                                <tr className="rows">
                                  {columns.map((item) => {
                                    return (
                                      <td>
                                        {
                                          tracksummaryItem[
                                            item.toLocaleLowerCase()
                                          ]
                                        }
                                      </td>
                                    );
                                  })}
                                </tr>
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              </PrintFormat>
            </div>
          </>
        ) : (
          <EmptyState
            text="Report File In Empty"
            content="Unraveling the Enigma of Empty Report Files."
            src="/assets/emptyReport.gif"
            width={200}
            height={200}
            alt="invoice"
          />
        )}
      </Card>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperBudgetManager>
  );
};

const WrapperBudgetManager = styled.div`
  .buttons {
    display: flex;
    justifycontent: end;
    gap: 10px;
    marginbottom: 20px;
  }
  .card_wrapper {
    margin: ${convertToRem(30)} 0;
    padding: ${convertToRem(30)} 0;
    border: none;
    min-height: 100vh;
    .select_area {
      padding: 0 ${convertToRem(30)};
      zindex: -99 !important;
    }
  }

  .table {
    width: 100%;
    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      font-size: ${convertToRem(14)};
      line-height: 1.3;
      .columns {
        text-align: left;
        th {
          fontweight: 500;
          padding: ${convertToRem(16)};
        }
      }
    }

    tbody {
      font-size: ${convertToRem(13)};
      font-weight: 400;
      line-height: 1.5;
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};
      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        border-top: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: 1px solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          padding: ${convertToRem(16)};
        }
      }
      .title {
        fontweight: 500;
        border: none;
      }
      .totalRows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.reportTableHeader};
        text-align: left;
        font-size: ${convertToRem(14)};
        font-weight: 500;
        td {
          padding: ${convertToRem(16)};
        }
      }
    }
  }
`;