import { Col, Row } from 'antd';
import { AccountCategorizationList } from './List';
import { AccountCategorizationWrapper } from './styles';

export const AccountCategorization = () => {
  return (
    <AccountCategorizationWrapper>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <AccountCategorizationList />
        </Col>
      </Row>
    </AccountCategorizationWrapper>
  );
};
