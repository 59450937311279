import { Button, Col, Row, Drawer, Space } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState, lazy } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getItemDetail, getItemByIDAPI } from '../../../../api';
import {
  ArrowButton,
  ButtonTag,
  Card,
  TransactionApprovePdf,
} from '@components';
import PDFICON from '@iconify-icons/ant-design/file-pdf-outlined';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import {
  ISupportedRoutes,
  IItemViewResult,
  IItemViewResponse,
  ReactQueryKeys,
} from '@invyce/shared/types';
import moneyFormat from '../../../../utils/moneyFormat';
import { SummaryItem } from './SummaryItem';
import view from '@iconify-icons/carbon/view';
import boxes from '@iconify-icons/bi/boxes';
import fileText from '@iconify-icons/feather/file-text';
import InvoiceIcon from '@iconify-icons/mdi/file-document-box-plus';
import Icon from '@iconify/react';
import { ItemSalesGraph } from '../ItemsView/ItemSalesGraph';
import { WrapperItemsView, ItemDrawer } from './SummaryItem/styles';
import { ItemDetails } from './ItemDetails';
import printIcon from '@iconify-icons/bytesize/print';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import convertToRem from '../../../../utils/convertToRem';
import { IThemeProps } from '../../../../hooks/useTheme/themeColors';
import ItemsImport from '../../ItemsImport';

interface Iprops {
  showItemDetails: any;
  setShowItemsDetails: (payload: any) => void;
}

const headerprops = {
  organizationName: '',
  city: '',
  country: '',
  title: 'Journal Entries',
  organizationContact: '',
  organizationEmail: '',
  address: '',
  code: '',
  logo: '',
  website: '',
};

export const ItemsViewContainer: FC<Iprops> = ({
  showItemDetails,
  setShowItemsDetails,
}) => {
  /* HOOKS HERE */
  const { routeHistory } = useGlobalContext();
  const { visibility } = showItemDetails;
  const { history } = routeHistory;
  const [apiConfig, setApiConfig] = useState({
    id: null,
    start: dayjs(),
    end: dayjs(),
  });

  const [{ result, message, nextItem, prevItem }, setItemDetails] =
    useState<IItemViewResult>({
      result: null,
      message: '',
      nextItem: '',
      prevItem: '',
    });

  useEffect(() => {
    if (showItemDetails?.id) {
      setApiConfig((prev) => {
        return { ...prev, id: showItemDetails?.id };
      });
    }
  }, [showItemDetails?.id]);

  const { data: itemViewResponse } = useQuery(
    [ReactQueryKeys?.ITEMS_VIEW, apiConfig?.id],
    getItemByIDAPI,
    {
      enabled: !!apiConfig?.id,
    }
  );

  useEffect(() => {
    if (itemViewResponse?.data?.result) {
      setItemDetails(itemViewResponse?.data);
    }
  }, [itemViewResponse]);

  const handleMutateApiConfig = (id) => {
    setApiConfig((prev) => {
      return { ...prev, id };
    });
  };

  const renderTitleArea = () => {
    return (
      <div className="flex alignCenter justifySpaceBetween">
        <div className="flex alignCenter">
          <ArrowButton
            className="mr-10 cusrsor"
            onClick={() => {
              if (prevItem) {
                handleMutateApiConfig(prevItem);
              }
            }}
            iconType="left"
          />
          <ArrowButton
            onClick={() => {
              if (nextItem) {
                handleMutateApiConfig(nextItem);
              }
            }}
            iconType="right"
          />
        </div>
        <div className="flex alignCenter mr-6">
          <ButtonTag
            className="mr-10"
            onClick={() => console.log('')}
            title="Print"
            size="middle"
            icon={printIcon}
            type="ghost"
          />
          <PDFDownloadLinkWrapper
            document={
              <TransactionApprovePdf
                resultData={[] as any}
                header={headerprops}
              />
            }
          >
            <div className="flex alignCenter">
              <Icon icon={PDFICON} className="mr-8" />
              <span> Download PDF</span>
            </div>
          </PDFDownloadLinkWrapper>
          <div>
            <ItemsImport />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ItemDrawer
      title={renderTitleArea()}
      placement="right"
      size={'large'}
      onClose={() => setShowItemsDetails({ visibility: false, id: null })}
      visible={visibility}
      closable={false}
      width={1000}
      extra={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            top: '15px',
            right: '4rem',
            cursor: 'pointer',
          }}
        ></div>
      }
    >
      <WrapperItemsView>
        <Row gutter={10}>
          <Col
            xxl={{ span: 24 }}
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Card className="_itemdetailcard">
              <div className="flex justifySpaceBetween alignCenter innerHeader">
                <h4 className="innerHeader">Item Overview</h4>
                <Button
                  onClick={() =>
                    history.push(
                      `/app${ISupportedRoutes.ITEMS}/${showItemDetails.id}`
                    )
                  }
                  type="link"
                  size="middle"
                  className="btn"
                >
                  More Details
                </Button>
              </div>
              <div className="calc_Details">
                <Row gutter={12}>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total Stock"
                      icon_bg="_color"
                      icon={<Icon color="#FFFFFF" icon={boxes} />}
                      amount={(result && result.stock) || 0}
                      card={{
                        light:
                          'linear-gradient(127deg, rgba(203,202,222,1) 0%, rgba(230,230,237,1) 33%, rgba(242,242,242,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total Purchases"
                      icon_bg="_color3"
                      icon={<Icon color="#FFFFFF" icon={fileText} />}
                      amount={moneyFormat(
                        result?.totalBillsAmount ? result?.totalBillsAmount : 0
                      )}
                      card={{
                        light:
                          'linear-gradient(22deg, rgba(204,227,255,1) 0%, rgba(230,230,238,1) 33%, rgba(233,240,248,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    xxl={{ span: 8 }}
                    xl={{ span: 8 }}
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 8 }}
                  >
                    <SummaryItem
                      footerDesc="Total Sales"
                      icon_bg="_color2"
                      icon={<Icon color="#FFFFFF" icon={InvoiceIcon} />}
                      amount={moneyFormat(
                        result?.totalInvoicesAmount
                          ? result?.totalInvoicesAmount
                          : 0
                      )}
                      card={{
                        light:
                          'linear-gradient(339deg, rgba(255,255,255,1) 0%, rgba(238,233,233,1) 33%, rgba(220,220,220,1) 100%)',
                        dark: 'linear-gradient(127deg, rgba(1, 39, 86, 1) 0%, rgba(0, 18, 40, 1) 33%, rgba(0, 7, 15, 1) 100%)',
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <ItemDetails result={result} id={showItemDetails.id} />
              <ItemSalesGraph id={showItemDetails?.id} />
            </Card>
          </Col>
        </Row>
      </WrapperItemsView>
    </ItemDrawer>
  );
};

const PDFDownloadLinkWrapper = styled(PDFDownloadLink)`
  padding: ${convertToRem(9)} ${convertToRem(14)} !important;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagBg};
  color: ${(props: IThemeProps) => props?.theme?.colors?.buttonTagColor};
  font-size: ${convertToRem(14)};
  font-weight: 400;

  &:hover {
    background: ${(props: IThemeProps) => props?.theme?.colors?.$PRIMARY};
    color: ${(props: IThemeProps) => props?.theme?.colors?.$WHITE};
  }
`;
