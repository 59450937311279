import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { Card, Heading, P, Seprator } from '@components';
import moneyFormat from '../../../../../utils/moneyFormat';
import { useGlobalContext } from '../../../../../hooks/globalContext/globalContext';
import moment from 'moment';
interface IProps {
  result: any;
  id: number;
}

export const ItemDetails: FC<IProps> = ({ result, id }) => {
  const { setItemsModalConfig } = useGlobalContext();
  return (
    <Card className="_itemdetailcard">
      <div className="flex justifySpaceBetween alignCenter innerHeader">
        <h4 className="innerHeader">Item Details</h4>
        <Button
          onClick={() => setItemsModalConfig(true, id)}
          type="link"
          size="middle"
          className="btn"
        >
          Edit Item
        </Button>
      </div>
      <Seprator />
      <div className="calc_Details">
        <Row gutter={[20, 10]}>
          <Col span={8}>
            <div className="detail_area">
              <p>Item Name</p>
              <Heading type="normal" fontWeight="600">
                {result?.name || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={8}>
            <div className="detail_area">
              <p>Item Code</p>
              <Heading type="normal" fontWeight="600">
                {result?.code || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={8}>
            <div className="detail_area">
              <p>Price</p>
              <Heading type="normal" fontWeight="600">
                {moneyFormat(result?.price?.salePrice) || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={8}>
            <div className="detail_area">
              <p>Item Sale Price</p>
              <Heading type="normal" fontWeight="600">
                {moneyFormat(result?.price?.salePrice) || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={8}>
            <div className="detail_area">
              <p>Item purchase Price</p>
              <Heading type="normal" fontWeight="600">
                {moneyFormat(result?.price?.purchasePrice) || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={8}>
            <div className="detail_area">
              <p>Added Date</p>
              <Heading type="normal" fontWeight="600">
                {moment(result?.createdAt).format('LL') || '-'}
              </Heading>
            </div>
          </Col>
          <Col span={24}>
            <Heading type="normal" fontWeight="600" className="mv-14">
              Description
            </Heading>
            <div className="detail_area">
              <p>{result?.description || '-'}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
