import formatMoney from 'accounting-js/lib/formatMoney.js';
import { useGlobalContext } from '../hooks/globalContext/globalContext';
import { ICurrency } from '../modal/organization';

export default function (amount: number | string) {
  const { userDetails } = useGlobalContext();
  const currency: ICurrency = userDetails?.organization?.currency || {
    name: 'United States dollar',
    code: 'USD',
    symbol: '$',
    symbolNative: '$',
    id: null,
  };

  // Attempt to parse the amount if it's a string.
  const valueToFormat =
    typeof amount === 'string' ? parseFloat(amount) : amount;

  // Check if the parsed value is not a number (NaN).
  if (isNaN(valueToFormat)) {
    // If it's not a number, return formatted '0'.
    return formatMoney(0, {
      symbol: currency?.symbolNative,
      format: '%s%v ',
    });
  }

  // Handle negative values.
  if (valueToFormat < 0) {
    return formatMoney(amount, {
      symbol: currency?.symbolNative,
      format: '%s(%v) ',
    });
  } else {
    // Handle positive values and zero.
    return formatMoney(amount, {
      symbol: currency?.symbolNative,
      format: '%s%v ',
    });
  }
}
