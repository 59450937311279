import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { FC } from 'react';
import { PdfDefaultHeaderProps } from '../../modal';

const styles = StyleSheet.create({
  footerContainer: {
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    justifyContent: 'flex-start',
    width: '50%',
  },

  title: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#212B3B',
  },
  info: {
    fontSize: 10,
    marginBottom: 4,
    color: '#666565',
    fontWeight: 'bold',
  },
  BottomDescription: {
    width: 400,
  },
  termsTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginTop: 12,
    marginBottom: 6,
    color: '#212B3B',
  },
  termsContent: {
    fontSize: 9,
    lineHeight: 'normal',
    color: '#666565',
    fontWeight: 'bold',
  },
  rightColumn: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '27%',
    marginTop: 10,
  },
  totalsRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    marginBottom: 4,
  },
  totalsTitle: {
    fontSize: 12,
    fontWeight: 'extrabold',
    color: '#212B3B',
    width: '60%',
  },
  totalsValue: {
    fontSize: 12,
    marginLeft: 8,
    fontWeight: 'semibold',
    color: '#7988FF',
    width: '50%',
  },
  hr: {
    borderBottomWidth: 1,
    borderBottomColor: '#7988ff',
    borderBottomStyle: 'solid',
    marginTop: 8,
    marginBottom: 8,
    width: 160,
  },
});

export const PdfFooter: FC<PdfDefaultHeaderProps> = ({
  grossTotal,
  itemDiscount,
  invoiceDiscount,
  netTotal,
  tax,
}) => {
  return (
    <View break style={styles.footerContainer}>
      <View style={styles.leftColumn}>
        <Text style={styles.title}>Payment Information :</Text>
        <Text style={styles.info}>Account Name : Studio Shodwe</Text>
        <Text style={styles.info}>Account Number : 123-456-7890</Text>
        <Text style={styles.info}>
          Email Address : hello@reallygreatsite.com
        </Text>
        <Text style={styles.info}>Website : www.reallygreatsite.com</Text>

        <View style={styles.BottomDescription}>
          <Text style={styles.termsTitle}>Term & Condition:</Text>
          <Text style={styles.termsContent}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            tristique lobortis purus, et cursus enim maximus ac. Quisque arcu
            eros, feugiat in tellus sit amet.
          </Text>
        </View>
      </View>
      <View style={styles.rightColumn}>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsTitle}>Sub Total:</Text>
          <Text style={styles.totalsValue}>{grossTotal}</Text>
        </View>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsTitle}>Discount:</Text>
          <Text style={styles.totalsValue}>{itemDiscount}</Text>
        </View>
        <View style={styles.totalsRow}>
          <Text style={styles.totalsTitle}>Tax (10%):</Text>
          <Text style={styles.totalsValue}>{tax}</Text>
        </View>
        <View style={styles.hr} />

        <View style={styles.totalsRow}>
          <Text style={styles.totalsTitle}>Grand Total:</Text>
          <Text style={styles.totalsValue}>{netTotal}</Text>
        </View>
      </View>
    </View>
  );
};
