import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { Bill, BreadCrumbArea, Heading, PurchasesView, TableCard } from '@components';
import { useGlobalContext } from '../../../../../hooks/globalContext/globalContext';
import { IInvoiceType, ISupportedRoutes } from '@invyce/shared/types';

export const PurchaseEntryView = () => {
  const { routeHistory } = useGlobalContext();
  const { pathname } = routeHistory.location;
  const invId = pathname.split(`app${ISupportedRoutes.BILLS}/`)[1];

  return (
    <>
    <div className='flex alignCenter justifySpaceBetween'>
      <div>
      <div className="flex alignCenter pb-5">
        <i>
          <Bill />
        </i>
        <Heading type="table">Bills</Heading>
      </div>
      <Heading type="sub-heading">
        Elevating Your Transactions with Precision and Professionalism. Your
        Gateway to Seamless Billing.
      </Heading>
      </div>
      <BreadCrumbArea>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/app${ISupportedRoutes.PURCHASES}`}>Bills</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Bill View</Breadcrumb.Item>
        </Breadcrumb>
      </BreadCrumbArea>
    </div>
  
     
      <TableCard>
        <PurchasesView type={IInvoiceType.BILL} id={invId} />
      </TableCard>
    </>
  );
};
