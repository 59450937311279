import { Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import bxPlus from '@iconify-icons/bx/bx-plus';
import {
  ButtonTag,
  CreateInvoiceIcon,
  Heading,
  TableTabs,
  TableTabsContent,
} from '@components';
import { Rbac } from '../../components/Rbac';
import { PERMISSIONS } from '../../components/Rbac/permissions';
import { useGlobalContext } from '../../hooks/globalContext/globalContext';
import { PaymentPaidList } from './PaymentsList/Payables';
import { PaymentRecievedList } from './PaymentsList/Recieveables';
import paymentSvg from '../../assets/paymentSvg.svg';

export const PaymentContainer: FC = () => {
  const { routeHistory, setPaymentsModalConfig } = useGlobalContext();
  const [activeTab, setActiveTab] = useState('paid');
  const { history } = routeHistory;
  const { search } = history.location;

  useEffect(() => {
    if (!activeTab) {
      setActiveTab('customers');
    }
  }, []);

  useEffect(() => {
    if (search) {
      const filterTab = search.split('?')[1].split('&')[0].split('=')[1];
      if (filterTab !== null && filterTab !== 'id') {
        if (activeTab !== filterTab) {
          setActiveTab(filterTab);
        }
      }
    }
  }, [search]);

  return (
    <WrapperPaymentContainer>
      <div className="flex alignCenter">
        <i>
          <img src={paymentSvg} alt="payment svg" />
        </i>
        <Heading type="table">Payments</Heading>
      </div>
      <Heading type="sub-heading">
        Elevate your business with streamlined payments for ultimate efficiency.
      </Heading>
      <div>
        <TableTabs
          defaultkey={`${activeTab}`}
          tabBarExtraContent={
            <Rbac permission={PERMISSIONS.PAYMENTS_CREATE}>
              <ButtonTag
                onClick={() => setPaymentsModalConfig(true)}
                type="primary"
                size="large"
                title="Add Payment"
                icon={bxPlus}
              />
            </Rbac>
          }
        >
          <>
            <TableTabsContent tab="Paid" key="paid">
              <PaymentPaidList />
            </TableTabsContent>
            <TableTabsContent tab="Received" key="received">
              <PaymentRecievedList />
            </TableTabsContent>
          </>
        </TableTabs>
      </div>
    </WrapperPaymentContainer>
  );
};

const WrapperPaymentContainer = styled.div``;
