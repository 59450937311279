import styled from 'styled-components';
import { Card } from '../Card';
import { H3 } from '../Typography';
import { IThemeProps, SharedInvyceTheme } from '@invyce/shared/invyce-theme';
export const IncomeView = () => {
  return (
    <WrapperSales>
      <Card className="_salescard">
        <div className="_salecontainer">
          <H3 className="heading_h3">Income</H3>
          <p className="sales_value">
            50000 <span>PKR</span>
          </p>
          <p className="sales_overview">
            17% <span>Since Last Month</span>
          </p>
        </div>
      </Card>
    </WrapperSales>
  );
};

const WrapperSales = styled.div`
  ._salescard {
    border-radius: 8px;
  }
  ._salecontainer {
    min-height: 120px;
    display: flex;
    padding: 0.75rem 1rem;
    background: #0b1822;
    flex-direction: column;
    justify-content: space-around;
    background: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#0B1822' : 'white'} !important;
    max-width: 270px !important;
  }
  .heading_h3 {
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : '#334d6e'};
    font-size: 16px;
    font-weight: 500;
  }
  .sales_value {
    font-size: 18px;
    margin: 14px 0px 14px 0px;
    font-weight: 500;
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#ffffff99' : 'rgba(39, 39, 39, 0.85)'};
  }
  .sales_overview {
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    color: ${(props: IThemeProps) =>
      props.theme?.theme === 'dark' ? '#15af93' : ' #15af93'};
    span {
      color: ${(props: IThemeProps) =>
        props.theme?.theme === 'dark' ? '#ffffff99' : '#656565'};
    }
  }
`;
