import { ISupportedRoutes } from '@invyce/shared/types';
import convertToRem from '../../../utils/convertToRem';
import React, { FC, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Icon from '@iconify/react';
import StarIcon from '@iconify-icons/ic/outline-star-border';
import StarFillIcon from '@iconify-icons/ic/outline-star';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Input } from 'antd';
import { IThemeProps } from '../../../hooks/useTheme/themeColors';
import { Heading, TableCard } from '@components';
import { RecentReport } from './RecentReports';
type ReportItem = {
  title: string;
  items: Array<ReportItemDetail>;
};

type ReportItemDetail = {
  name: string;
  isFav: boolean;
  isNew: boolean;
  link: string;
};

const root = '/app';
const reportItems: Array<ReportItem> = [
  {
    title: 'Financial',
    items: [
      {
        name: 'Balance Sheet Report',
        isFav: true,
        isNew: true,
        link: `${root}${ISupportedRoutes.BALANCE_SHEET_REPORT}`,
      },
      {
        name: 'Budget manager Report',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.BUDGET_MANAGER}`,
      },
      {
        name: 'Cash Summary Report',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.CASH_SUMMARY}`,
      },
      {
        name: 'AccountCategorization',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.ACCOUNT_CATEGORIZATION}`,
      },
    ],
  },
  {
    title: 'Sales',
    items: [
      {
        name: 'Aged Receivables Details',
        isFav: true,
        isNew: true,
        link: `${root}${ISupportedRoutes.AGED_RECEIVABLE}`,
      },
      {
        name: 'Aged Receivables Summary',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.AGED_RECEIVABLE_SUMMARY}`,
      },
      {
        name: 'Aged Payable Summary',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.AGED_PAYABLE_SUMMARY}`,
      },
      {
        name: 'Aged Payable Details',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.AGED_PAYABLES}`,
      },
    ],
  },
  {
    title: 'Others',
    items: [
      // {
      //   name: 'Trial Balance',
      //   isFav: false,
      //   isNew: true,
      //   link: `${root}${ISupportedRoutes.TRIAL_BALANCE}`,
      // },
      {
        name: 'Trial Balance report',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.TRIALBALACE_REPORT}`,
      },
      // {
      //   name: 'Balance Sheet',
      //   isFav: false,
      //   isNew: true,
      //   link: `${root}${ISupportedRoutes.BALANCE_SHEET}`,
      // },
      {
        name: 'Income Statement',
        isFav: false,
        isNew: true,
        link: `${root}${ISupportedRoutes.INCOME_STATEMENT}`,
      },
      // {
      //   name: 'Cash Summary',
      //   isFav: false,
      //   isNew: false,
      //   link: `${root}${ISupportedRoutes.CASH_SUMMARY}`,
      // },
      {
        name: 'Stock Inventory Management',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.STOCK_INVENTORY_MANAGEMENT}`,
      },
      // {
      //   name: 'Balance Sheet report',
      //   isFav: false,
      //   isNew: false,
      //   link: `${root}${ISupportedRoutes.BALANCE_SHEET_REPORT}`,
      // },
    ],
  },
  {
    title: 'Reports',
    items: [
      {
        name: 'Management Report',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.MANAGEMENT_REPORT}`,
      },
      // {
      //   name: 'Budget Manager',
      //   isFav: false,
      //   isNew: false,
      //   link: `${root}${ISupportedRoutes.BUDGET_MANAGER}`,
      // },
      {
        name: 'Customer Outstanding',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.CUSTOMER_OUTSTANDING}`,
      },
      {
        name: 'Supplier Outstanding',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.SUPPLIER_OUTSTANDING}`,
      },
      {
        name: 'General Ledger Summary',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.GENERAL_LEDGER_SUMMARY}`,
      },
      {
        name: 'Tracking Summary',
        isFav: false,
        isNew: false,
        link: `${root}${ISupportedRoutes.TRACKING_SUMMARY}`,
      },
    ],
  },
];

export const ReportItems: FC = () => {
  const [favorites, setFavorites] = useState([]);
  const { Search } = Input;
  const INITIAL_VISIBLE_COUNT = 3;
  const [visibleItemCounts, setVisibleItemCounts] = useState({});

  const handleToggleVisibility = (sectionIndex: number) => {
    setVisibleItemCounts((prevCounts) => {
      const currentCount = prevCounts[sectionIndex] || INITIAL_VISIBLE_COUNT;
      return {
        ...prevCounts,
        [sectionIndex]:
          currentCount > INITIAL_VISIBLE_COUNT
            ? INITIAL_VISIBLE_COUNT
            : currentCount + INITIAL_VISIBLE_COUNT,
      };
    });
  };

  const toggleFavorite = (item: ReportItemDetail) => {
    if (favorites.includes(item)) {
      setFavorites(favorites.filter((fav) => fav !== item));
    } else {
      setFavorites([...favorites, item]);
    }
  };

  const renderItems = (items: ReportItemDetail[], sectionIndex: number) => {
    const visibleCount =
      visibleItemCounts[sectionIndex] || INITIAL_VISIBLE_COUNT;
    return items.slice(0, visibleCount).map((item: ReportItemDetail) => (
      <div className="InnerCard">
        <span className="flex alignCenter justifyCenter">
          <i className="iconWrapper" onClick={() => toggleFavorite(item)}>
            {item.isFav ? (
              <Icon
                icon={StarFillIcon}
                color="#7988FF"
                width={22}
                height={22}
              />
            ) : (
              <Icon icon={StarIcon} color="#7988FF" width={22} height={22} />
            )}
          </i>
          <Link key={item.name} to={item.link}>
            {item.name}
          </Link>
        </span>
        <span className={item.isNew ? 'newBtn' : ''}>
          {item.isNew && 'New'}
        </span>
      </div>
    ));
  };

  return (
    <WrapperReportListItems>
      <TableCard>
        <RecentReport />
        <div className="searchbar">
          <Heading type="form-inner">Your Total’s Reports are 203</Heading>
          <Search
            size="middle"
            placeholder="Search Report"
            onSearch={() => console.log('adasds')}
            enterButton={<Button type="primary" icon={<SearchOutlined />} />}
            width={30}
          />
        </div>
        <Row gutter={4} className="rowsGutter">
          {reportItems.slice(0, 5).map((section, index) => (
            <Col span={11} key={index} className="columnContainer">
              <Heading type="form-inner" className="heading">
                {section.title}
              </Heading>
              {renderItems(section.items, index)}
              <div className="seeMoreLinkDiv">
                {section.items.length > INITIAL_VISIBLE_COUNT && (
                  <Button
                    type="text"
                    className="seeMoreLink"
                    onClick={() => handleToggleVisibility(index)}
                  >
                    {visibleItemCounts[index] > INITIAL_VISIBLE_COUNT
                      ? 'See Less'
                      : 'See More'}
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </TableCard>
    </WrapperReportListItems>
  );
};

const WrapperReportListItems = styled.div`
  margin: ${convertToRem(10)} 0;
  .container {
    height: auto;
  }
  .searchbar{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin: ${convertToRem(15)} ${convertToRem(36)};
    padding: ${convertToRem(12)};
    border-radius: ${convertToRem(4)};
    background:  ${(props: IThemeProps) =>
      props?.theme?.colors?.layoutBg} !important;

      .ant-input-group-wrapper{
        width: 32% !important;

        .ant-input{
          border-top-left-radius: 20px !important;
          border-bottom-left-radius: 20px !important;
          border: 1px solid #F0EFEF;
        }
        .ant-input-group-addon{
           left: -8px !important;
        }
        .ant-input-group-addon > button{
          border-top-right-radius: 20px !important;
          border-bottom-right-radius: 20px !important;
          margin-left: 1px !important;
          background: #7988FF;
          border: none;
          z-index: 999;
        }
      }
  }
  .rowsGutter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .columnContainer {
    border: 1px solid
      ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBorderColor} !important;
    border-radius: 4px;
    background:  ${(props: IThemeProps) =>
      props?.theme?.colors?.layoutBg} !important;
    padding: ${convertToRem(18)} ${convertToRem(16)};
    margin: ${convertToRem(14)};
    min-height: 260px;
    overflow: hidden;

    .heading {
      margin-left: ${convertToRem(10)};
    }
    .seeMoreLink {
      display: flex;
      align-items: center;
      border-radius:10px
      justify-content: flex-end;
      padding: ${convertToRem(5)};
    }
  }
  .seeMoreLinkDiv {
    margin: ${convertToRem(14)};
    display: flex !important;
    justify-content: flex-end !important;
  }

  .InnerCard {
    background: ${(props: IThemeProps) =>
      props?.theme?.colors?.updateCardPrimaryColor};
    border-radius: ${convertToRem(4)};
    padding: ${convertToRem(10)};
    margin: ${convertToRem(16)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      cursor: pointer;
      margin-right: ${convertToRem(6)};
      display: flex;
      align-items: center;
    }

    a {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableHeaderTextColor};
    }

    .newBtn {
      cursor: pointer;
      color: #3e3e3c;
      padding: ${convertToRem(4)} ${convertToRem(10)};
      background: #e8eafd;
      border-radius: ${convertToRem(8)};
      font-size: ${convertToRem(12)};
    }
  }
`;
