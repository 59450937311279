import React from 'react';
import { Col, Row } from 'antd';
import { BalanceSheetWrapper } from './styled';
import { BalanceSheetList } from './List';

export const BalanceSheetReport = () => {
  return (
    <BalanceSheetWrapper>
      <Row gutter={14}>
        <Col span={20} offset={2}>
          <BalanceSheetList />
        </Col>
      </Row>
    </BalanceSheetWrapper>
  );
};
