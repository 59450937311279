import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { FC } from 'react';
import { PdfDefaultHeaderProps } from '../../modal';

const styles = StyleSheet.create({
  container: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    borderBottom: '1 solid #E4E4E4',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    justifyContent: 'flex-start',
    width: '50%',
  },
  headerLabel: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#212B3B',
  },
  headerInfo: {
    fontSize: 10,
    marginBottom: 4,
    color: '#666565',
    fontWeight: 'bold',
  },
  greeting: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 4,
    color: '#212B3B',
  },
  bodyText: {
    fontSize: 10,
    lineHeight: 1.4,
    width: '170%',
    color: '#666565',
    fontWeight: 'bold',
  },
  rightColumn: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '50%',
  },
  netTotalLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#212B3B',
  },
  netTotalValue: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: 'semibold',
    color: '#7988FF',
  },
});

export const PdfBalance: FC<PdfDefaultHeaderProps> = ({
  contactName,
  contactNumber,
  contactAddress,
  netTotal,
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <Text style={styles.headerLabel}>Invoice To:</Text>
        <Text style={styles.netTotalValue}>{contactName}</Text>
        <Text style={styles.headerInfo}>Phone Number: {contactNumber}</Text>
        {contactAddress ? (
          <Text style={styles.headerInfo}>
            Address: {contactAddress[0]?.description} ?{' '}
            {contactAddress[0]?.description} : {contactAddress[0]?.city},{' '}
            {contactAddress[0]?.country}
          </Text>
        ) : (
          ''
        )}
        <Text style={styles.greeting}>Dear {contactName},</Text>
        <Text style={styles.bodyText}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
          tristique lobortis purus, et cursus enim maximus ac. Quisque arcu
          eros, feugiat in tellus sit amet.
        </Text>
      </View>
      <View style={styles.rightColumn}>
        <Text style={styles.netTotalLabel}>NetTotal</Text>
        <Text style={styles.netTotalValue}>{netTotal}</Text>
      </View>
    </View>
  );
};
