import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';
import { GeneralLedgerDetailList } from './List';
import { GeneralLedgerDetailContainer } from './styled';

export const GeneralLedgerDetail: FC = () => {
  return (
    <GeneralLedgerDetailContainer>
      <Row gutter={2}>
        <Col span={22} offset={1}>
          <GeneralLedgerDetailList />
        </Col>
      </Row>
    </GeneralLedgerDetailContainer>
  );
};
