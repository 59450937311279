// import { Card } from 'antd';
import { Card, BoldText } from '@components';
import React, { FC, ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import convertToRem from '../../../../../utils/convertToRem';
import moneyFormat from '../../../../../utils/moneyFormat';
import { IThemeProps } from '@invyce/shared/invyce-theme';

interface ICardProps {
  light: string;
  dark: string;
}

interface IProps {
  amount: number | string;
  footerDesc: string;
  icon: ReactElement<any>;
  icon_bg: any;
  card?: ICardProps;
}

export const SummaryItem: FC<IProps> = ({
  amount,
  footerDesc,
  icon,
  icon_bg,
  card,
}) => {
  return (
    <WrapperSummaryItem>
      <GlassCard card={card}>
        <div className="flex flexDColumn alignCenter justifySpaceBetween">
          <div className="content_area">
            <div className="item_result flex justifyCenter">
              <div
                className={`${icon_bg}  icon flex alignCenter justifyCenter`}
              >
                <i className="flex alignCenter justifyCenter">{icon}</i>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="_footer_desc textCenter">
              <p className="m-reset">{footerDesc}</p>
            </div>
            <BoldText className="item_amount">{amount || 0} </BoldText>
          </div>
        </div>
      </GlassCard>
    </WrapperSummaryItem>
  );
};

const GlassCard = styled(Card)<{
  theme: IThemeProps;
  card: { light: string; dark: string };
}>`
  backdrop-filter: blur(75px);
  background: ${(props) =>
    props.theme?.theme === 'dark' ? props.card?.dark : props.card?.light};
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.09);
  .ant-card-body {
    padding: 10px !important;
  }
`;

const WrapperSummaryItem = styled.div`
  padding: ${convertToRem(5)} 0 0 0;
  .item_result {
    margin: ${convertToRem(18)} 0;
    .icon {
      width: ${convertToRem(35)};
      height: ${convertToRem(35)};
      border-radius: 50%;
      i {
        font-size: ${convertToRem(20)};
        color: #1f9dff;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0 ${convertToRem(15)} 0;
    color: ${(props: IThemeProps) => props?.theme?.colors?.emptyStateHeading};
    ._footer_desc {
      font-size: 14px !important;
    }
    .item_amount {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.emptyStateDescription} !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: normal;
      letter-spacing: 0.64px;
      text-transform: capitalize;
      margin-top: 6px !important;
    }
  }
`;
