import React, { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonTag, Heading, SmartFilter, P } from '@components';
import printIcon from '@iconify-icons/bytesize/print';
import documentImport from '@iconify/icons-carbon/document-import';
import printDiv from '../../../utils/Print';
import FilterSchema from './filter';
import { ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../hooks/globalContext/globalContext';
import convertToRem from '../../../utils/convertToRem';
import { useQuery } from 'react-query';
import { getInventoryManagement } from '@api';
import baselineAlternateEmail from '@iconify-icons/carbon/email-new';
import FixedHeader from 'apps/main-ui/src/components/SummaryHeader';
import { IThemeProps } from 'apps/main-ui/src/hooks/useTheme/themeColors';
import { EmailModal } from 'apps/main-ui/src/components/PurchasesView/Email';
import { Card } from 'antd';
import EmptyState from 'apps/main-ui/src/components/EpmtyState/EmptyState';

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Reference',
    dataIndex: 'reference',
    key: 'reference',
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Purchase Price',
    dataIndex: 'purchasePrice',
    key: 'purchasePrice',
  },
  {
    title: 'Sale Price',
    dataIndex: 'salePrice',
    key: 'salePrice',
  },
  {
    title: 'Total Sale',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: 'Stock able',
    dataIndex: 'stock',
    key: 'stock',
  },
  {
    title: 'Reorder',
    dataIndex: 'reorder',
    key: 'reorder',
  },
  {
    title: 'Supplier Name',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
  },
];

interface IProps {
  props?: any;
}

export const StockInventoryManagementList: FC<IProps> = (props) => {
  const [config, setConfig] = useState({
    query: '',
  });
  const [result, setResult] = useState<any>([]);
  const { query } = config;
  const printRef = useRef();
  const { routeHistory, userDetails, Colors } = useGlobalContext();
  const { history } = routeHistory;
  const [emailModal, setEmailModal] = useState(false);

  const { data, isLoading, isFetched } = useQuery(
    [`report-stockinventory-query=${query}`, query],
    getInventoryManagement
  );

  useEffect(() => {
    if (data) {
      setResult(data);
    }
  }, [data, history, query]);

  const onPrint = () => {
    const printItem = printRef.current;
    printDiv(printItem);
  };

  const onEmail = async () => {};

  return (
    <WrapperStockInventory>
      <FixedHeader
        src="/assets/stockeInventoryManagement.png"
        title="Stock & Inventory Management"
        onEmail={() => setEmailModal(true)}
        onPrint={onPrint}
      >
        <SmartFilter
          formSchema={FilterSchema}
          onFilter={(query) => {
            setConfig({ query: query });
            history.push(
              `/app${ISupportedRoutes.STOCK_INVENTORY_MANAGEMENT}?query=${query}`
            );
          }}
        />
      </FixedHeader>
      <table className="table">
        {result?.data?.length > 0 ? (
          <>
            <thead>
              <tr className="columns">
                {columns.map((item) => {
                  return <th align="left">{item?.title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {result?.data?.map((element, index) => {
                return (
                  <tr className="rows" key={index}>
                    {columns.map((item) => {
                      if (item.key === 'date') {
                        const dateObject = new Date(element[item.key]);
                        const formattedDate = `${dateObject.getDate()}-${dateObject.toLocaleString(
                          'default',
                          { month: 'short' }
                        )}-${dateObject.getFullYear()}`;
                        return <td align="left">{formattedDate}</td>;
                      }
                      return <td align="left">{element[item.key] || '-'}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          <Card>
            <EmptyState
              text="Report File In Empty"
              content="Unraveling the Enigma of Empty Report Files."
              src="/assets/emptyReport.gif"
              width={200}
              height={200}
              alt="invoice"
            />
          </Card>
        )}
      </table>
      <EmailModal
        onSendEmail={onEmail}
        visibility={emailModal}
        setVisibility={(a) => setEmailModal(a)}
      />
    </WrapperStockInventory>
  );
};

const WrapperStockInventory = styled.div`
  .buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
  }
  .table {
    width: 100%;
    thead {
      background: ${(props: IThemeProps) =>
        props?.theme?.colors?.reportTableHeader};
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.listTableHeaderText};
      .columns {
        th {
          padding: ${convertToRem(12)};
          font-size: ${convertToRem(14)};
          font-weight: 500 !important;

          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
    tbody {
      color: ${(props: IThemeProps) =>
        props?.theme?.colors?.tableBodyTextColor};
      .rows {
        background: ${(props: IThemeProps) =>
          props?.theme?.colors?.tableBodyColor};
        border-top: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        border-bottom: ${convertToRem(1)} solid
          ${(props: IThemeProps) => props?.theme?.colors?.tableBorderColor};
        td {
          font-size: ${convertToRem(13)};
          font-weight: 400;
          line-height: 1.5;
          padding: ${convertToRem(12)};
        }
      }
    }
  }
`;