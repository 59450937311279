import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import {
  BreadCrumbArea,
  DebitNotes,
  Heading,
  PurchasesView,
} from '@components';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { IInvoiceType, ISupportedRoutes } from '@invyce/shared/types';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import Icon from '@iconify/react';
import fileInvoice from '@iconify/icons-bx/bx-file';

export const DebitNotesView: FC = () => {
  const { routeHistory } = useGlobalContext();
  const { pathname } = routeHistory.location;
  const invId = pathname.split(
    `${ISupportedRoutes?.DASHBOARD_LAYOUT}${ISupportedRoutes?.DEBIT_NOTES}/`
  )[1];

  return (
    <WrapperCreditNoteView>
      <div className='flex alignCenter justifySpaceBetween'>
       <div>
      <div className="flex alignCenter mb-5">
        <i>
          <DebitNotes />
        </i>
        <Heading type="table">Debit Notes View</Heading>
      </div>
      <Heading type="sub-heading">
        Crafting Clarity in Financial Transactions for Precise Accounting and
        Transparent Business Dealings.
      </Heading>
      </div>
      <BreadCrumbArea>
        <Breadcrumb separator=">">
          <Icon icon={fileInvoice} className="bredcrumIcon mr-6" />
          <Breadcrumb.Item>
            <Link to={`/app${ISupportedRoutes.DEBIT_NOTES}`}>Debit Notes</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Debit Note View</Breadcrumb.Item>
        </Breadcrumb>
      </BreadCrumbArea>
      </div>

      <PurchasesView type={IInvoiceType.DEBITNOTE} id={invId} />
    </WrapperCreditNoteView>
  );
};
const WrapperCreditNoteView = styled.div``;
