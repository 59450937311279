export enum IType {
  SCHEDULED = 'scheduled',
  REMINDER = 'reminder',
  UNSCHEDULED = 'unscheduled',
}

export enum IEndType {
  NONE = 'none',
  BY = 'by',
  AFTER = 'after',
}

export enum IInterval {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  wEEKLY = 'weekly',
  YEARLY = 'yearly',
}

export enum ITerm {
  DUEONRECEIPT = 'due_on_receipt',
  NET10 = 'net_10',
  NET15 = 'net_15',
  NET30 = 'net_30',
  NET60 = 'net_60',
}

interface IBase {
  name: string;
  value: string;
}

interface IMonthly {
  tracking: any;
  onDay: IBase[];
}

interface IDays {
  onDay: IBase[];
}

export interface IBaseRecurring {
  type: IBase[];
  interval: IBase[];
  monthly: IMonthly;
  weekly: IDays;
  yearly: any;
}

export const recurringInvyce: IBaseRecurring = {
  type: [
    {
      name: 'Scheduled',
      value: IType?.SCHEDULED,
    },
    {
      name: 'Reminder',
      value: IType?.REMINDER,
    },
    {
      name: 'Unscheduled',
      value: IType?.UNSCHEDULED,
    },
  ],
  interval: [
    {
      name: 'Daily',
      value: IInterval?.DAILY,
    },
    {
      name: 'Monthly',
      value: IInterval?.MONTHLY,
    },
    {
      name: 'Weekly',
      value: IInterval?.wEEKLY,
    },
    {
      name: 'Yearly',
      value: IInterval?.YEARLY,
    },
  ],
  monthly: {
    onDay: [
      {
        name: 'Day',
        value: 'day',
      },
      {
        name: 'First',
        value: 'first',
      },
      {
        name: 'Second',
        value: 'second',
      },
      {
        name: 'Third',
        value: 'third',
      },
      {
        name: 'Fourth',
        value: 'fourth',
      },
      {
        name: 'Last',
        value: 'last',
      },
    ],
    tracking: generateNumbers(),
  },
  weekly: {
    onDay: generateDaysOfWeekArray(),
  },
  yearly: {
    Every: generateMonthsArray(),
    onDay: generateNumbers(),
  },
};

/// helper functions
//function for generting number 1st to 10th
export function generateNumbers(): { name: string; value: string }[] {
  const array: { name: string; value: string }[] = [];
  const suffixes = ['st', 'nd', 'rd'];
  const defaultSuffix = 'th';
  for (let i = 1; i <= 10; i++) {
    let suffix = defaultSuffix;
    if (i <= 3) {
      suffix = suffixes[i - 1];
    }
    array.push({ name: `${i}${suffix}`, value: `${i}${suffix}` });
  }
  return array;
}

//write function to generate weeksofdays
export function generateDaysOfWeekArray(): { name: string; value: string }[] {
  const daysOfWeek: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const array: { name: string; value: string }[] = daysOfWeek.map((day) => {
    return { name: day, value: day.toLowerCase() };
  });

  return array;
}

export function generateMonthsArray(): { name: string; value: string }[] {
  const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const array: { name: string; value: string }[] = months.map((month) => {
    return { name: month, value: month.toLowerCase() };
  });

  return array;
}
