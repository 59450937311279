/* eslint-disable react-hooks/exhaustive-deps */
import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../hooks/globalContext/globalContext';
import {
  TableTabs,
  TableTabsContent,
  FallBackLoader,
  ButtonTag,
} from '@components';
import { WrapperTransactionsList } from './styles';
import { ISupportedRoutes } from '@invyce/shared/types';
import bxPlus from '@iconify-icons/bx/bx-plus';

export const TransactionsList: FC = () => {
  const APPROVETransactionList = lazy(() => import('./All'));
  const DRAFTTransactionList = lazy(() => import('./Drafts'));

  const [activeTab, setActiveTab] = useState('');
  const { routeHistory } = useGlobalContext();
  const { history } = routeHistory;
  const { search } = routeHistory.history.location;

  useEffect(() => {
    if (!activeTab) {
      setActiveTab('approve');
    }
  }, [activeTab]);

  useEffect(() => {
    if (search) {
      const filterTab = search.split('?')[1].split('&')[0].split('=')[1];
      if (filterTab !== null && filterTab !== 'id' && filterTab !== activeTab) {
        setActiveTab(filterTab);
      }
    }
  }, [search, activeTab]);

  const RenderButton = () => {
    return (
      <ButtonTag
        type="primary"
        onClick={() =>
          history.push({
            pathname: `/app${ISupportedRoutes.CREATE_TRANSACTION}`,
            state: {
              from: history.location.pathname,
            },
          })
        }
        size={'large'}
        title=" Journal Entries"
        icon={bxPlus}
      />
    );
  };

  return (
    <WrapperTransactionsList>
      <TableTabs
        defaultkey={`${activeTab}`}
        tabBarExtraContent={RenderButton()}
      >
        <>
          <TableTabsContent tab="Approve" key="approve">
            <Suspense fallback={<FallBackLoader />}>
              <APPROVETransactionList />
            </Suspense>
          </TableTabsContent>

          <TableTabsContent tab="Draft" key="draft">
            <Suspense fallback={<FallBackLoader />}>
              <DRAFTTransactionList />
            </Suspense>
          </TableTabsContent>
        </>
      </TableTabs>
    </WrapperTransactionsList>
  );
};
